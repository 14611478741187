import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout from "src/components/layout/Layout";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskFormAutoSave from "src/hooks/api/tasks/useTaskFormAutoSave";
import useAuth from "src/hooks/selectors/useAuth";
import TaskDetailViewCompany from "src/views/authenticated/tasks/detail/company/TaskDetailView";
import TaskPublishView from "src/views/authenticated/tasks/detail/publish/TaskPublishView";
import TaskDetailViewStudent from "src/views/authenticated/tasks/detail/student/TaskDetailView";
import TasksDetailEventView from "src/views/authenticated/tasks/detail/TasksDetailEventView";

export default function TasksDetailView() {
	const { id, pane } = useParams() as any;
	const {
		tenant: { modules },
	} = useTenant();
	const auth = useAuth();
	const location = useLocation();
	const { task, actions, status } = useTask({ id });
	const externalTaskForm = useTaskForm();
	useTaskFormAutoSave(["publish", "edit"].includes(pane));

	useEffect(() => {
		if (!task?.hid || task?.hid !== id) {
			console.log("SHOULD FETCH", {
				id,
				task: task?.hid,
			});
			actions.get().then((task) => {
				if (task?.id && modules.includes("extern-task")) {
					externalTaskForm.actions.setTask(task);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	if (status === "loading") {
		return <Layout loading={status === "loading"} />;
	}

	//Disable for task create thanks page
	if (id === "create" && pane === "completed") {
		return <></>;
	}

	if (
		pane === "show" &&
		task.variant === "HOUR_RATE" &&
		auth.type === "company"
	) {
		return <TasksDetailEventView />;
	}

	if (
		pane === "publish" &&
		auth.type === "company" &&
		modules.includes("extern-task")
	) {
		return <TaskPublishView />;
	}

	if (auth.type === "student" && modules.includes("extern-task")) {
		return <TaskDetailViewStudent />;
	}

	return <TaskDetailViewCompany />;
}
