import { useState } from "react";
import { useLocation } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "src/components/ui/hover-card";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cn } from "src/lib/utils";

const SupportButton = ({ t }: Translation) => {
	const { tenant } = useTenant();
	const { pathname } = useLocation();
	const [open, setOpen] = useState(false);

	if (
		tenant.slug !== "getjobsdone" ||
		(!pathname.startsWith("/login") &&
			!pathname.startsWith("/signup") &&
			!pathname.startsWith("/forgot-password"))
	) {
		return null;
	}

	return (
		<div className="fixed z-40 bottom-4 right-4">
			<HoverCard {...{ open }} onOpenChange={setOpen}>
				<HoverCardTrigger asChild>
					<div
						onClick={() => setOpen(true)}
						className="flex justify-center items-center shadow w-12 h-12 rounded-full bg-dark text-primary-foreground cursor-pointer"
					>
						<i className="fas fa-question"></i>
					</div>
				</HoverCardTrigger>
				<HoverCardContent
					align="end"
					side="left"
					sideOffset={8}
					alignOffset={8}
					className="w-[200px] p-1"
				>
					<div className="flex flex-col">
						<SupportItem
							icon="fas fa-envelope"
							url="https://getjobsdone.nl/contact"
						>
							{t("contact")}
						</SupportItem>
						<SupportItem
							icon="fas fa-question"
							url="https://getjobsdone.nl/feedback?field[65]=Hulp bij een functionaliteit"
						>
							{t("support")}
						</SupportItem>
						<SupportItem
							icon="fas fa-bug"
							url="https://getjobsdone.nl/feedback?field[65]=Een bug in het platform"
						>
							{t("bug")}
						</SupportItem>
					</div>
				</HoverCardContent>
			</HoverCard>
		</div>
	);
};

SupportButton.locale = {
	nl: {
		contact: "Contact",
		support: "Support",
		bug: "Bug melden",
	},
	en: {},
};

interface SupportItemProps {
	url: string;
	children: any;
	icon: string;
}

const SupportItem = ({ url, children, icon }: SupportItemProps) => {
	return (
		<a
			href={url}
			className="text-foreground flex items-center gap-1.5 hover:bg-accent px-3 py-2 rounded-md transition-all select-none"
			target="_blank"
			rel="noreferrer"
		>
			<div className="w-6">
				<i className={cn(icon)} />
			</div>
			<span>{children}</span>
		</a>
	);
};

export default withTranslation(SupportButton);
