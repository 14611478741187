import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo, useState } from "react";
import { ApiPaymentRequest } from "src/api/types";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import PaymentRequestStatus, {
	PAYMENT_REQUEST_STATUSES,
} from "src/components/payment-request/elements/PaymentRequestStatus";
import PaymentRequestCalendar from "src/components/payment-request/visualizations/PaymentRequestCalendar";
import PaymentRequestTable from "src/components/payment-request/visualizations/PaymentRequestTable";
import PaymentRequestWeekList from "src/components/payment-request/visualizations/PaymentRequestWeekList";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import useQueryString from "src/hooks/useQueryString";

const tabAtom = atomWithStorage("payment-requests-tab", "week");

const PaymentRequestsView = ({ t }: Translation) => {
	const qs = useQueryString();
	const auth = useAuth();
	const { modules, loading: loadingModules } = useModules();
	const [filter, setFilter] = useState<{
		connection_id?: number | string;
		status?: ApiPaymentRequest["status"][];
	}>({ status: [], connection_id: qs?.connection_id?.toString() });
	const [tab, setTab] = useAtom(tabAtom);
	const createUrl = useMemo(() => {
		const path = "/payment-requests/create";
		if (!filter) return path;
		const search = new URLSearchParams();
		if (filter?.connection_id) {
			search.set("connection_id", filter.connection_id.toString());
		}
		return `/payment-requests/create?${search.toString()}`;
	}, [filter]);

	if (
		auth.type === "company" &&
		!modules.includes("payment-requests") &&
		!loadingModules
	) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("title"),
						to: "/payment-requests",
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="all" />
			</Layout>
		);
	}

	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/payment-requests",
				},
			]}
		>
			<div className="flex flex-col-reverse gap-4 md:flex-row md:justify-between">
				<div className="flex flex-col md:flex-row md:items-center gap-4">
					<div className="flex gap-4 items-center justify-between">
						<Tabs.Container className="w-max">
							<Tab
								className="gap-2"
								onClick={() => setTab("week")}
								active={tab === "week"}
							>
								<i className="far fa-calendar-week"></i>
								{t("tabs.week")}
							</Tab>
							<Tab
								className="gap-2"
								onClick={() => setTab("list")}
								active={tab === "list"}
							>
								<i className="far fa-list"></i>
								{t("tabs.list")}
							</Tab>
						</Tabs.Container>
						<div className="items-center gap-4 flex md:hidden">
							<Button
								to={{
									modal: createUrl,
								}}
							>
								{t("create")}
							</Button>
						</div>
					</div>
					<div className="flex gap-4 items-center">
						<Field.Connection
							onChange={(value) =>
								setFilter((filter) => ({
									...filter,
									connection_id: value,
								}))
							}
							value={filter?.connection_id}
							placeholder={t(
								`filters.${
									auth.type === "student"
										? "company"
										: "student"
								}`
							)}
						/>
						<Field.Select
							placeholder={t("filters.status")}
							onChange={(value) =>
								setFilter((filter) => ({
									...filter,
									status: value,
								}))
							}
							value={filter?.status?.length ? filter.status : []}
							options={PAYMENT_REQUEST_STATUSES.map((status) => ({
								value: status,
								label: <PaymentRequestStatus status={status} />,
							}))}
							theme={{
								value: {
									renderValue: (value) => {
										if (Array.isArray(value)) {
											return (
												<>
													{value.map((value) => (
														<PaymentRequestStatus
															status={value}
														/>
													))}
												</>
											);
										}
										return (
											<PaymentRequestStatus
												status={value}
											/>
										);
									},
								},
							}}
						/>
					</div>
				</div>
				<div className="items-center gap-4 hidden md:flex">
					<Button
						to={{
							modal: createUrl,
						}}
					>
						{t("create")}
					</Button>
				</div>
			</div>
			<div className="flex flex-col flex-1">
				{tab === "agenda" && (
					<PaymentRequestCalendar {...{ filter, createUrl }} />
				)}
				{tab === "list" && (
					<PaymentRequestTable
						{...{ createUrl }}
						filter={{ ...filter, sort: "newest" }}
					/>
				)}
				{tab === "week" && (
					<PaymentRequestWeekList {...{ filter, createUrl }} />
				)}
			</div>
		</Layout>
	);
};

PaymentRequestsView.locale = {
	nl: {
		title: "Betaalverzoeken",
		create: "Aanmaken",
		tabs: {
			agenda: "Agenda",
			week: "Weken",
			list: "Lijst",
		},
		filters: {
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			status: "Status",
		},
	},
	en: {
		title: "Payment Requests",
		create: "Create",
		tabs: {
			agenda: "Agenda",
			week: "Weeks",
			list: "List",
		},
		filters: {
			company: "Client",
			student: "Contractor",
			status: "Status",
		},
	},
};

export default withTranslation(PaymentRequestsView);
