import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useSignupTracking from "src/hooks/api/services/auth/useSignupTracking";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useLanguage from "src/hooks/api/useLanguage";

const API_URL = process.env.REACT_APP_API_URL;

interface OAuthProvidersProps extends Translation {}

const OAuthProviders = ({ t }: OAuthProvidersProps) => {
	const routeParams = useParams();
	const { tenant } = useTenant();
	const trackingData = useSignupTracking();
	const { language } = useLanguage();

	const getUrl = (provider: "facebook" | "google") => {
		const params: any = {
			locale: language.current,
			tenant: tenant.slug,
			...trackingData,
		};

		if (routeParams.session) {
			params.session_id = routeParams.session;
		}

		//Remove the undefined values from object
		Object.keys(params).forEach(
			(key) => params[key] === undefined && delete params[key]
		);
		const url = new URL(`${API_URL}/oauth/${provider}`);
		url.search = new URLSearchParams(params).toString();
		return url.toString();
	};

	return (
		<div className="flex flex-col gap-4">
			{tenant.providers.oauth.includes("google") && (
				<Button
					to={getUrl("google")}
					type="border"
					className="text-background-foreground justify-start"
				>
					<div className="flex gap-3 items-center w-full max-w-[240px] mx-auto">
						<svg
							width="25"
							height="24"
							viewBox="0 0 25 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clipRule="evenodd"
								d="M23.8859 12.2614C23.8859 11.4459 23.8128 10.6618 23.6769 9.90912H12.8459V14.3575H19.035C18.7684 15.795 17.9582 17.013 16.7403 17.8284V20.7139H20.4569C22.6314 18.7118 23.8859 15.7637 23.8859 12.2614Z"
								fill="#4285F4"
							/>
							<path
								fill-rule="evenodd"
								clipRule="evenodd"
								d="M12.8459 23.4998C15.9509 23.4998 18.5541 22.47 20.4568 20.7137L16.7402 17.8282C15.7105 18.5182 14.3932 18.9259 12.8459 18.9259C9.85068 18.9259 7.31546 16.903 6.41114 14.1848H2.56909V17.1644C4.46136 20.9228 8.35046 23.4998 12.8459 23.4998Z"
								fill="#34A853"
							/>
							<path
								fill-rule="evenodd"
								clipRule="evenodd"
								d="M6.41117 14.1851C6.18117 13.4951 6.05049 12.758 6.05049 12.0001C6.05049 11.2421 6.18117 10.5051 6.41117 9.81506V6.83551H2.56913C1.79027 8.38801 1.34595 10.1444 1.34595 12.0001C1.34595 13.8557 1.79027 15.6121 2.56913 17.1646L6.41117 14.1851Z"
								fill="#FBBC05"
							/>
							<path
								fill-rule="evenodd"
								clipRule="evenodd"
								d="M12.8459 5.07386C14.5343 5.07386 16.0502 5.65409 17.242 6.79364L20.5405 3.49523C18.5489 1.63955 15.9457 0.5 12.8459 0.5C8.35046 0.5 4.46136 3.07705 2.56909 6.83545L6.41114 9.815C7.31546 7.09682 9.85068 5.07386 12.8459 5.07386Z"
								fill="#EA4335"
							/>
						</svg>

						<span>{t("providers.google")}</span>
					</div>
				</Button>
			)}
			{tenant.providers.oauth.includes("facebook") && (
				<Button
					to={getUrl("facebook")}
					type="border"
					className="text-background-foreground"
				>
					<div className="flex gap-3 items-center w-full max-w-[240px] mx-auto">
						<svg
							width="25"
							height="24"
							viewBox="0 0 25 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_1_114)">
								<path
									d="M24.3459 12.0699C24.3459 5.7186 19.1972 0.56988 12.8459 0.56988C6.49467 0.56988 1.34595 5.7186 1.34595 12.0699C1.34595 17.8099 5.55133 22.5674 11.0491 23.4302V15.3941H8.12915V12.0699H11.0491V9.53629C11.0491 6.6541 12.7659 5.06207 15.3928 5.06207C16.651 5.06207 17.967 5.28668 17.967 5.28668V8.11675H16.5169C15.0883 8.11675 14.6428 9.00322 14.6428 9.91266V12.0699H17.8323L17.3224 15.3941H14.6428V23.4302C20.1406 22.5674 24.3459 17.8099 24.3459 12.0699Z"
									fill="#4267B2"
								/>
							</g>
							<defs>
								<clipPath id="clip0_1_114">
									<rect
										width="24"
										height="24"
										fill="white"
										transform="translate(0.845947)"
									/>
								</clipPath>
							</defs>
						</svg>
						<span>{t("providers.facebook")}</span>
					</div>
				</Button>
			)}
		</div>
	);
};

OAuthProviders.locale = {
	nl: {
		providers: {
			facebook: "Doorgaan met Facebook",
			google: "Doorgaan met Google",
		},
	},
	en: {
		providers: {
			facebook: "Continue with Facebook",
			google: "Continue with Google",
		},
	},
};

export default withTranslation(OAuthProviders);
