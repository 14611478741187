import React from "react";
import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

const StudentCanRegister = () => {
	const auth = useAuth();
	const { t } = useTranslation("promotion", {
		keyPrefix: "student-can-register",
	});
	const { tenant } = useTenant();
	let url = tenant?.urls?.public + "/opdrachten/alles/overal";

	if (tenant.urls.community) {
		url = tenant.urls.community + "/opdrachten";
	}

	if (tenant?.slug === "getcrewdone") {
		url = tenant?.urls?.public + "/evenementen";
	}

	if (
		auth.onboarding_status === "verified" &&
		!auth.status.has_registrations
	) {
		return (
			<a
				target="_blank"
				href={url}
				className="p-4 flex gap-2 items-center rounded-md bg-green-200 border-2 border-green text-green"
				rel="noreferrer"
			>
				<i className="fas fa-check"></i>
				<small className="flex-1 font-bold">{t("description")}</small>
				<i className="fas fa-arrow-right"></i>
			</a>
		);
	}
	return <></>;
};

export default StudentCanRegister;
