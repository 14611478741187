import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Link from "src/components/Link";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";

const NoMandateBanner = () => {
	const { t } = useTranslation("promotion", {
		keyPrefix: "no-mandate-banner",
	});
	const auth = useAuth();
	const subscription = useSubscription();
	const location = useLocation();
	const isFree = subscription?.name === "Free";
	if (
		location.pathname === "/profile/billing/change/mandate" ||
		isFree ||
		auth?.company?.hide_mandate_connect_banner
	) {
		return <></>;
	}

	return (
		<div className="top-0 left-0 right-0 p-4 rounded-md bg-red z-20">
			<div className="container">
				<Link
					to="/profile/billing/change/mandate"
					className="text-white mx-auto block text-center"
				>
					<Trans {...{ t }} i18nKey="description">
						Je hebt geen actieve bankkoppeling,
						<span className="md:bg-dark md:px-3 md:py-2 mx-2 underline rounded-md">
							Klik hier
						</span>
						om een nieuwe koppeling te maken
					</Trans>
				</Link>
			</div>
		</div>
	);
};

export default NoMandateBanner;
