const registrations = {
	status: {
		open: "Open",
		pending: "In afwachting",
		completed: "Afgerond",
		deleted: "Verwijdert",
		expired: "Verlopen",
		matched: "Gematcht",
		denied: "Niet geselecteerd",
		canceled: "Geannuleerd",
	},
	"recent-list": {
		title: "Nieuwe aanmeldingen",
		empty: "Je hebt nog geen aanmeldingen op jouw opdrachten",
	},
	card: {
		cta: "Aanmelding bekijken",
	},
	"accept-modal": {
		title: "Aanmelding goedkeuren",
		"payment-description":
			"Deze betaling wordt vastgehouden op een derdenrekening, wanneer jij een positieve review geeft wordt het bedrag doorgestort naar de professional.",
		"sign-direct": "Automatisch de overeenkomst tekenen",
		"sign-direct-sublabel": "om de overeenkomst te bekijken",
	},
	buttons: {
		review: "Review plaatsen",
		denie: "Afwijzen",
		accept: "Accepteren",
		"cancel-task": "Opdracht annuleren",
		payout: "Uitbetalen",
	},
	"registration-details": {
		registration: "Aanmelding",
		"reason-of-deny": "Reden van afwijzing",
		telephone: "Telefoonnummer",
		"about-me": "Over mij",
		"current-education": "Huidige opleiding",
		"current-education-text": "{{title}} aan {{subtitle}}",
		bio: "Bio",
		skills: "Vaardigheden",
	},
	"registration-head": {
		"full-cv": "Volledige cv",
	},
	"registration-match-details": {
		envelope: "Overeenkomst",
		payment: "Betaling",
		"no-payment":
			"Er is nog geen betaling voor deze match. Maak een betaling aan zodat de opdracht afgerond kan worden.",
		"create-payment": "Betaling aanmaken",
	},
	"registration-reviews": {
		client: "Opdrachtgever",
		contractor: "Opdrachtnemer",
		review: {
			title: "Review",
			"not-created": "Nog geen review ontvangen",
			"created-by": "Aangemaakt door",
		},
	},
	"select-registration-pane": {
		back: "Terug naar aanmeldingen",
		empty: {
			title: "Selecteer eerst een aanmelding",
		},
	},
	"registrations-sidebar": {
		tabs: {
			all: "Alles",
			accepted: "Gematcht",
			open: "Open",
			denied: "Afgewezen",
			favorites: "Favorieten",
		},
	},
	"registration-contact-details": {
		title: "Contactgegevens",
		telephone: "Telefoonnummer",
		email: "Email",
		website: "Website",
	},
};

export default registrations;
