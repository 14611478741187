import axios from "axios";
import { useState } from "react";
import { ApiPlaceAutocomplete } from "src/api/types";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

interface Props {
	search?: string;
}

function useAddressAutocomplete({ search }: Props) {
	const [status, setStatus] = useState<"idle" | "loading" | "searching">(
		"idle"
	);
	const [places, setPlaces] = useState<ApiPlaceAutocomplete[]>([]);
	const [fetchingPlaceId, setFetchingPlaceId] = useState<string>();
	useUpdateEffectDebounce(search, 500, (value) => {
		getPlaces(value);
	});

	const getPlaces = async (search?: string) => {
		if (!search) {
			setPlaces([]);
			return;
		}
		setStatus("searching");
		const { data } = await axios.get(`/helpers/places?search=${search}`);
		setPlaces(data?.data || []);
		setStatus("idle");
		return data;
	};

	const getAddress = async (id: string) => {
		setStatus("loading");
		setFetchingPlaceId(id);
		const { data } = await axios.get(`/helpers/places/${id}`);
		setFetchingPlaceId(undefined);
		return data?.data;
	};

	return {
		places,
		status,
		fetchingPlaceId,
		actions: { getPlaces, getAddress },
	};
}

export default useAddressAutocomplete;
