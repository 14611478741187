const SUBDOMAINS = ["account.", "www.", "demo.", "app."];

export const getCurrentDomainName = () => {
	let host = window.location.hostname;
	SUBDOMAINS.map((subdomain) => {
		host = host.replace(subdomain, "");
		return subdomain;
	});
	[host] = host.split(".");
	return host;
};
