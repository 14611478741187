import { useState } from "react";
import copyToClipboard from "src/lib/copyToClipboard";
import { cn } from "src/lib/utils";

interface ShareClipboardProps {
	label?: string;
	url: string;
}

const ShareClipboard = ({ label, url }: ShareClipboardProps) => {
	const [isCopied, setIsCopied] = useState(false);
	const onClick = () => {
		copyToClipboard(url);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	};

	return (
		<div className="flex bg-accent py-3 px-4 max-w-full items-center overflow-hidden rounded-md relative">
			<div className="block flex-1 max-w-[60px]">
				<small className="whitespace-nowrap">{url}</small>
			</div>
			<div className="bg-gradient-to-l from-accent absolute top-0 bottom-0 right-0 w-24"></div>
			<div
				{...{ onClick }}
				className={cn(
					"transition-all h-8 right-3 cursor-pointer text-background-foreground rounded-md absolute flex items-center justify-center bg-background",
					isCopied && "bg-green",
					!label ? "w-8" : "px-0 md:px-2 w-8 md:w-auto gap-2"
				)}
			>
				<i
					className={`fas ${isCopied ? "fa-check" : "fa-clipboard"}`}
				></i>

				{label && (
					<span className="hidden md:flex font-medium">{label}</span>
				)}
			</div>
		</div>
	);
};

export default ShareClipboard;
