import { useEffect, useRef } from "react";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthSessionProcessingLoader from "src/components/promotion/banners/AuthSessionProcessingLoader";
import TaskTableRow from "src/components/tasks/TaskTableRow";
import List from "src/components/ui/list";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import useTasks from "src/hooks/api/services/tasks/useTasks";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useAuth from "src/hooks/selectors/useAuth";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useElementSize from "src/hooks/useElementSize";

interface TasksTableProps extends Translation {
	filters?: any;
	setModalTask: (hid: string) => void;
	searchActions?: any;
	options?: {
		scrollToTop?: boolean;
	};
}

const TasksTable = ({ t, setModalTask }: TasksTableProps) => {
	const authSession = useAuthSession();
	const permissions = usePermissions();
	const auth = useAuth();
	const type = auth.type;
	const { ref } = useElementSize();
	const width = useRef() as any;
	const { taskType, tenant } = useTenant();
	const { tasks, status, pagination, actions } = useTasks();
	const { filter } = useTasksFilter();

	useUpdateEffectDebounce(filter, 1000, () => {
		actions.list(filter);
	});
	const loading = status === "loading" && tasks.length === 0;

	useEffect(() => {
		actions.list(
			{
				...filter,
				recent: 1,
				variant: "table",
			},
			{
				disableMergeFilter: true,
			}
		);
		return () => {
			if (taskType === "extern") {
				actions.resetState();
				actions.resetFilter();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const textKey = ["both", "extern"].includes(taskType || "extern")
		? "empty.extern"
		: "empty.intern";
	const texts = {
		title:
			type === "student"
				? t(`${textKey}.student.title`)
				: t(`${textKey}.company.title`),
		text:
			type === "student"
				? t(`${textKey}.student.text`)
				: t(`${textKey}.company.text`),
	};

	if (
		authSession?.session?.id &&
		authSession?.session?.status !== "COMPLETED"
	) {
		return <AuthSessionProcessingLoader className="mx-auto flex-1" />;
	}

	if (
		tasks?.length === 0 &&
		!loading &&
		permissions.has("tasks.create") &&
		!filter
	) {
		return (
			<div className="flex flex-1 flex-col gap-4 justify-center items-center text-center">
				<h2>{t("empty.company.title")}</h2>
				<p className="text-center">{t("empty.company.text")}</p>
				<Button
					className="mt-4"
					to={
						auth.has_subscription
							? "/tasks/create"
							: "/subscription"
					}
				>
					{t("empty.company.button")}
				</Button>
			</div>
		);
	}

	return (
		<div
			{...{ ref }}
			style={{ maxWidth: width.current }}
			className="flex flex-col gap-4"
		>
			<List
				status={status !== "idle" && tasks?.length ? "idle" : status}
				data={tasks}
			>
				<List.Head>
					<List.Item>{t("headers.status")}</List.Item>
					<List.Item className="w-[200px]">
						{t("headers.task")}
					</List.Item>
					{taskType === "extern" && auth.type === "student" && (
						<List.Item>{t("headers.company")}</List.Item>
					)}
					{permissions.has("tasks.create") && (
						<List.Item>{t("headers.registrations")}</List.Item>
					)}
					<List.Item>{t("headers.budget")}</List.Item>
					<List.Item>{t("headers.date")}</List.Item>
					{auth.type === "student" &&
						tenant.modules.includes("skills-profile") && (
							<List.Item className="text-center">
								{t("headers.match-score")}
							</List.Item>
						)}
					<List.Item>{t("headers.actions")}</List.Item>
				</List.Head>
				<List.Items>
					{(task) => (
						<TaskTableRow
							{...{ task, setModalTask }}
							key={`task-${task.hid}`}
							onDelete={async () => actions.delete(task.hid)}
						/>
					)}
				</List.Items>
				<List.Empty>
					<h2>{texts.title}</h2>
					<p>{texts.text}</p>
				</List.Empty>
				<List.Loading>
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					{taskType === "extern" && (
						<List.Item>
							<Skeleton className="h-6 w-full" />
						</List.Item>
					)}
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					{permissions.has("tasks.create") && (
						<List.Item>
							<Skeleton className="h-6 w-full" />
						</List.Item>
					)}
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					<List.Item align="right">
						<Skeleton className="h-10 w-10" />
					</List.Item>
				</List.Loading>
				<List.Pagination
					{...{ pagination }}
					onNavigate={pagination.setPage}
				/>
			</List>
		</div>
	);
};

TasksTable.locale = {
	nl: {
		headers: {
			status: "Status",
			task: "Taak",
			registrations: "Aanmeldingen",
			budget: "Budget",
			date: "Datum",
			actions: "Acties",
			type: "Type",
			"match-score": "Match Score",
			company: "Bedrijf",
		},
		head: {
			company: {
				title: "Opdrachten",
				subtitle:
					"Alle opdrachten die in jouw organisatie zijn geplaatst",
			},
		},
		empty: {
			extern: {
				student: {
					title: "Je hebt je nog niet aangemeld voor opdrachten",
					text: "Opdrachten zoeken",
				},
				company: {
					title: "Je hebt nog geen opdrachten",
					text: "Maak gemakkelijk een opdracht aan",
					button: "Opdracht plaatsen",
				},
			},
			intern: {
				student: {
					title: "Je hebt nog geen taken",
					text: "Maak een taak aan",
				},
				company: {
					title: "Je hebt nog geen taken",
					text: "Maak een taak aan",
				},
			},
		},
	},
	en: {
		headers: {
			status: "Status",
			task: "Task",
			registrations: "Registrations",
			budget: "Budget",
			date: "Date",
			actions: "Actions",
			type: "Type",
			"match-score": "Match Score",
			company: "Company",
		},
		head: {
			company: {
				title: "Tasks",
				subtitle: "All tasks that are made by your organization",
			},
		},
		empty: {
			extern: {
				student: {
					title: "You haven't signed up for any assignments yet",
					text: "Find assignments",
				},
				company: {
					title: "You haven't created any assignments yet",
					text: "Easily create an assignment",
					button: "Place Assignment",
				},
			},
			intern: {
				student: {
					title: "You don't have any tasks yet",
					text: "Create a task",
				},
				company: {
					title: "You don't have any tasks yet",
					text: "Create a task",
				},
			},
		},
	},
};

export default withTranslation(TasksTable);
