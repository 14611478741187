import { Route } from "react-router-dom";
import ExperienceFormModal from "src/components/resume/elements/experience-form/ExperienceFormModal";
import TaskModal from "src/components/tasks/TaskForm/modals/task-modal/TaskModal";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import OnboardingAddressModal from "src/views/authenticated/dashboard/company/onboarding/OnboardingAddressModal";
import OnboardingInformationModal from "src/views/authenticated/dashboard/company/onboarding/OnboardingInformationModal";
import DashboardConnectMandateView from "src/views/authenticated/dashboard/DashboardConnectMandateView";
import DashboardMandateView from "src/views/authenticated/dashboard/DashboardMandateView";
import DashboardChangeExperienceLinkModal from "src/views/authenticated/dashboard/resume/edit/DashboardChangeExperienceLinkModal";
import DashboardEditAvatarModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditAvatarModal";
import DashboardEditBasicModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditBasicModal";
import DashboardEditBioModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditBioModal";
import DashboardEditExperiencesModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditExperiencesModal";
import DashboardEditSocialMediaModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditSocialMediaModal";
import DashboardValidateSkillsModal from "src/views/authenticated/dashboard/resume/edit/skills/DashboardValidateSkillsModal";
import DashboardShareNetworkView from "src/views/authenticated/dashboard/student/DashboardShareNetworkView";
import DashboardView from "src/views/authenticated/DashboardView";
import ResumePublicView from "src/views/authenticated/profile/resume/public/ResumePublicView";
import TeamFormModal from "src/views/authenticated/profile/teams/TeamFormModal";
import InviteUserModal from "src/views/authenticated/profile/users/InviteUserModal";

const DashboardRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardView />
			</ProtectedRoute>
		}
		path="/dashboard"
		key="/dashboard"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardConnectMandateView />
			</ProtectedRoute>
		}
		path="/dashboard/connect/mandate"
		key="/dashboard/connect/mandate"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardMandateView />
			</ProtectedRoute>
		}
		path="/dashboard/mandate/:mandate"
		key="/dashboard/mandate/:mandate"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardShareNetworkView />
			</ProtectedRoute>
		}
		path="/dashboard/details/share-network"
		key="/dashboard/details/share-network"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<ResumePublicView />
			</ProtectedRoute>
		}
		path="/dashboard/resume/preview/:id"
		key="/dashboard/resume/preview/:id"
	/>,
];

export const DashboardModalRoutes = [
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditBasicModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/basic"
		key="/dashboard/resume/basic"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "fill",
				}}
			>
				<DashboardEditBioModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/edit/bio"
		key="/dashboard/resume/edit/bio"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "fill",
					// theme: "dark",
				}}
			>
				<DashboardValidateSkillsModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/edit/skills"
		key="/dashboard/resume/edit/skills"
	/>,

	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "fill",
				}}
			>
				<DashboardChangeExperienceLinkModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/edit/skills/experiences/:experienceType/:experienceId/change"
		key="/dashboard/resume/edit/skills/experiences/:experienceType/:experienceId/change"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "fill",
				}}
			>
				<DashboardEditSocialMediaModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/edit/social-media"
		key="/dashboard/resume/edit/social-media"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "fill",
				}}
			>
				<DashboardEditExperiencesModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/edit/experiences/:type"
		key="/dashboard/resume/edit/experiences/:type"
	/>,
	<Route
		path="/dashboard/resume/experiences/:type/:id/edit"
		key="/dashboard/resume/experiences/:type/:id/edit"
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<ExperienceFormModal />
			</ProtectedRoute>
		}
	/>,

	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditAvatarModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/avatar"
		key="/dashboard/resume/avatar"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<OnboardingInformationModal />
			</ProtectedRoute>
		}
		path="/dashboard/onboarding/information"
		key="/dashboard/onboarding/information"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<TaskModal defaultVisible backUrl="/dashboard" />
			</ProtectedRoute>
		}
		path="/dashboard/tasks/:id/show"
		key="/dashboard/tasks/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<OnboardingAddressModal />
			</ProtectedRoute>
		}
		path="/dashboard/onboarding/address"
		key="/dashboard/onboarding/address"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<TeamFormModal />
			</ProtectedRoute>
		}
		path="/dashboard/onboarding/teams"
		key="/dashboard/onboarding/teams"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<InviteUserModal />
			</ProtectedRoute>
		}
		path="/dashboard/invite/users"
		key="/dashboard/invite/users"
	/>,
];

export default DashboardRoutes;
