import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import SubscriptionPlans from "src/components/subscription/SubscriptionPlans";

function SignupCompanyView({ t }: Translation) {
	return (
		<AuthLayout variant="flat">
			<AuthLayoutContainer>
				<SubscriptionPlans
					getPlanCheckoutUrl={(plan, interval) =>
						`/signup/company/subscription/${plan.slug}?interval=${interval}`
					}
				/>
			</AuthLayoutContainer>
		</AuthLayout>
	);
}

SignupCompanyView.locale = {
	nl: {},
	en: {},
};
export default withTranslation(SignupCompanyView);
