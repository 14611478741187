import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import ResumeDetail from "src/components/resume/resume-detail/ResumeDetail";
import ResumeHeader from "src/components/resume/ResumeHeader";
import SkillsData from "src/components/skills-data/SkillsData";
import useResume from "src/hooks/api/services/resume/useResume";
import useSubscription from "src/hooks/selectors/useSubscription";
import { cn } from "src/lib/utils";

interface ResumeProps {
	id: string;
	exclude: string[];
	variant: "default" | "registration";
}

const Resume = ({ id, exclude, variant }: ResumeProps) => {
	const subscription = useSubscription();
	const { t } = useTranslation("resume", {
		keyPrefix: "resume",
	});
	const { resume, actions, status } = useResume({
		rememberKey: id,
	});
	const modules: string[] = resume?.resume_modules || [];
	const checkModule = (name: string) => {
		return modules.includes(name) && !exclude.includes(name);
	};

	useEffect(() => {
		actions.get(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!resume?.id && status !== "idle") {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<Loader />
			</div>
		);
	}

	if (!resume?.id) {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<strong className="text-background-foreground">
					{t("empty.title")}
				</strong>
			</div>
		);
	}

	if (variant === "registration") {
		return (
			<ResumeDetail {...{ resume }}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					{(checkModule("bio") || checkModule("video-pitch")) && (
						<div className={cn("md:col-span-2")}>
							<ResumeDetail.Bio />
						</div>
					)}

					{resume.skills && (
						<SkillsData
							className="flex flex-col flex-1 gap-8 md:col-span-2"
							data={resume.skills}
						>
							<div className="flex gap-4 justify-center items-center flex-1 border-y border-border">
								<SkillsData.AreaChart type="area">
									<SkillsData.AreaChartDisplay className="col-span-2 max-h-[400px] w-full max-w-[400px]" />
									<SkillsData.AreaChartLabels />
								</SkillsData.AreaChart>
							</div>
							<div className="flex flex-row gap-8">
								<div className="flex flex-col flex-1 gap-3">
									<div className="flex flex-col">
										<SkillsData.Title type="COMPETENCE" />
										<SkillsData.Description type="COMPETENCE" />
									</div>
									<SkillsData.Skills type="COMPETENCE" />
								</div>
								<div className="flex flex-col flex-1 gap-3">
									<div className="flex flex-col">
										<SkillsData.Title type="KNOWLEDGE" />
										<SkillsData.Description type="KNOWLEDGE" />
									</div>

									<SkillsData.Skills type="KNOWLEDGE" />
								</div>
							</div>
						</SkillsData>
					)}

					{checkModule("experiences.education") && (
						<ResumeDetail.Education variant="timeline" />
					)}
					{checkModule("experiences.job") && (
						<ResumeDetail.Work variant="timeline" />
					)}
					{checkModule("social-media") && subscription?.id && (
						<ResumeDetail.SocialMedia />
					)}
					{checkModule("reviews") && (
						<div className="md:col-span-2">
							<ResumeDetail.Reviews />
						</div>
					)}
					<div className="md:col-span-2">
						<ResumeDetail.Badges />
					</div>
				</div>
			</ResumeDetail>
		);
	}

	return (
		<ResumeDetail {...{ resume }}>
			<div className="flex flex-col gap-8">
				{!exclude?.includes("header") && <ResumeDetail.Head />}

				{(checkModule("bio") || checkModule("video-pitch")) && (
					<ResumeDetail.Bio />
				)}
				{resume.skills && (
					<SkillsData
						className="flex flex-col flex-1 gap-8 md:col-span-2"
						data={resume.skills}
					>
						<div className="flex gap-4 justify-center items-center flex-1 border-y border-border">
							<SkillsData.AreaChart type="area">
								<SkillsData.AreaChartDisplay className="col-span-2 max-h-[400px] w-full max-w-[400px]" />
								<SkillsData.AreaChartLabels />
							</SkillsData.AreaChart>
						</div>
						<div className="flex flex-row gap-8">
							<div className="flex flex-col flex-1 gap-3">
								<div className="flex flex-col">
									<SkillsData.Title type="COMPETENCE" />
									<SkillsData.Description type="COMPETENCE" />
								</div>
								<SkillsData.Skills type="COMPETENCE" />
							</div>
							<div className="flex flex-col flex-1 gap-3">
								<div className="flex flex-col">
									<SkillsData.Title type="KNOWLEDGE" />
									<SkillsData.Description type="KNOWLEDGE" />
								</div>

								<SkillsData.Skills type="KNOWLEDGE" />
							</div>
						</div>
					</SkillsData>
				)}
				{checkModule("experiences.education") && (
					<ResumeDetail.Education variant="timeline" />
				)}
				{checkModule("experiences.job") && (
					<ResumeDetail.Work variant="timeline" />
				)}
				{checkModule("social-media") && <ResumeDetail.SocialMedia />}
				{checkModule("reviews") && <ResumeDetail.Reviews />}
				<ResumeDetail.Badges />
			</div>
		</ResumeDetail>
	);
};

Resume.defaultProps = {
	exclude: [],
	variant: "default",
};

Resume.Header = ResumeHeader;

export default Resume;
