import flat from "flat";
import { ComponentType } from "react";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGlobal from "src/hooks/api/useGlobal";
import useLanguage from "src/hooks/api/useLanguage";

export interface Translation {
	t: (key: string, params?: any) => string;
}

function withTranslation<T>(Component: ComponentType<T>, _locale?: any) {
	return function Injected(props: Omit<T, "t">) {
		const { current } = useLanguage();
		const { tenant } = useTenant();
		const { global } = useGlobal();

		const t = (key: string, params?: any) => {
			const com: any = Component;
			let locale = _locale || com?.locale || {};
			if (!locale[current]) {
				return key;
			}
			locale = locale[current];
			const flatten: {
				[key: string]: string;
			} = flat(locale);

			if (params?.count !== undefined) {
				if (params?.count === 1) {
					if (flatten[`${key}_one`]) {
						key = `${key}_one`;
					}
				}

				if (params?.count > 1 || !params.count) {
					if (flatten[`${key}_other`]) {
						key = `${key}_other`;
					}
				}
			}

			if (!flatten[key]) {
				return key;
			}

			let value = flatten[key];

			if (params) {
				Object.keys(params).map((search) => {
					value = value.replaceAll(`{{${search}}}`, params[search]);
					return value;
				});
			}

			const flattenTenant = flat(tenant || {}) as any;
			if (flattenTenant) {
				Object.keys(flattenTenant).map((search) => {
					value = value.replaceAll(
						`{{tenant.${search}}}`,
						flattenTenant[search]
					);
					return value;
				});
			}
			const flattenGlobal = flat(global || {}) as any;
			if (flattenGlobal) {
				Object.keys(flattenGlobal).map((search) => {
					value = value.replaceAll(
						`{{global.${search}}}`,
						flattenGlobal[search]
					);
					return value;
				});
			}
			return value;
		};
		// Component.displayName = `withTranslation(${Component.displayName})`;

		return (
			<Component
				{...(Component?.defaultProps || {})}
				{...(props as T)}
				{...{ t }}
			/>
		);
	};
}

interface TProps {
	nl: any;
	en: any;
}

export const useT = (props: TProps) => {
	const { current } = useLanguage();
	const { tenant } = useTenant();
	const { global } = useGlobal();

	const t = (key: string, params?: any) => {
		let locale = props;
		if (!locale[current]) {
			return key;
		}
		locale = locale[current];
		const flatten: {
			[key: string]: string;
		} = flat(locale);

		if (params?.count !== undefined) {
			if (params?.count === 1) {
				if (flatten[`${key}_one`]) {
					key = `${key}_one`;
				}
			}

			if (params?.count > 1 || !params.count) {
				if (flatten[`${key}_other`]) {
					key = `${key}_other`;
				}
			}
		}

		if (!flatten[key]) {
			console.log(key);
			return key;
		}

		let value = flatten[key];

		if (params) {
			Object.keys(params).map((search) => {
				value = value.replaceAll(`{{${search}}}`, params[search]);
				return value;
			});
		}

		const flattenTenant = flat(tenant || {}) as any;
		if (flattenTenant) {
			Object.keys(flattenTenant).map((search) => {
				value = value.replaceAll(
					`{{tenant.${search}}}`,
					flattenTenant[search]
				);
				return value;
			});
		}
		const flattenGlobal = flat(global || {}) as any;
		if (flattenGlobal) {
			Object.keys(flattenGlobal).map((search) => {
				value = value.replaceAll(
					`{{global.${search}}}`,
					flattenGlobal[search]
				);
				return value;
			});
		}
		return value;
	};
	return t;
};

export default withTranslation;
