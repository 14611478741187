import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import History from "@tiptap/extension-history";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import Mention from "@tiptap/extension-mention";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { EditorContent, InputRule, useEditor } from "@tiptap/react";
import {
	ComponentProps,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import Header from "src/components/field/fields/Editor/Header";
import { ColorHighlighter } from "src/components/field/fields/Editor/plugins/ColorHighlighter";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";
// import { SmilieReplacer } from "src/components/field/fields/Editor/plugins/SmilieReplacer";
import UserSuggestion from "src/components/field/fields/Editor/plugins/UserSuggestion";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";
import { uploadFile } from "src/lib/uploadFile";

const ExtHorizontalRule = HorizontalRule.extend({
	addInputRules() {
		return [
			new InputRule({
				find: /^(?:---|—-|___\s|\*\*\*\s)$/,
				handler: ({ state, range, match }) => {
					const attributes = {};

					const { tr } = state;
					const start = range.from;
					let end = range.to;

					tr.insert(start - 1, this.type.create(attributes)).delete(
						tr.mapping.map(start),
						tr.mapping.map(end)
					);
				},
			}),
		];
	},
});

type EditorContextProps = {
	variant?: "bordered" | "floating";
	editor: any;
	onUploadImageClick: () => void;
	loadingImage: boolean;
	setLoadingImage: (value: boolean) => void;
};

export const EditorContext = createContext<EditorContextProps>(
	{} as EditorContextProps
);

export function useEditorProps(): any {
	const editor = useContext(EditorContext);

	return editor;
}

interface EditorProps extends Translation {
	wrapper?: WrapperProps;
	value: string;
	onChange: (value: string) => void;
	placeholder?: string;
	variant?: "bordered" | "floating";
	options?: {
		autoFocus?: boolean;
	};
	editorClassName?: string;
}

const Editor = ({
	t,
	wrapper,
	value: _value,
	onChange,
	placeholder: _placeholder,
	variant,
	options,
	editorClassName,
}: EditorProps) => {
	const auth = useAuth();
	const { users } = useUsers();
	const placeholder = _placeholder || t("placeholder");
	const imageInput = useRef<HTMLInputElement>(null);
	const parseHTML = (editor: any) => {
		let html = editor.getHTML();
		html = (html || "").replaceAll("<p></p>", "<br/>");
		return html;
	};
	const [value, setValue] = useState(
		(_value || "").replaceAll("<br/>", "<p></p>")
	);
	const [isFocused, setIsFocused] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);
	const editor = useEditor({
		extensions: [
			Underline,
			Document,
			BulletList,
			OrderedList,
			ListItem,
			Bold,
			History.configure({
				depth: 10,
			}),
			Heading.configure({
				levels: [2, 3],
			}),
			Paragraph,
			Text,
			Placeholder.configure({
				emptyNodeClass: "text-placeholder",
				placeholder: ({ node }) => {
					return placeholder;
				},
			}),
			Image.configure({}),
			ColorHighlighter,
			// SmilieReplacer,
			TaskList,
			TaskItem.configure({
				nested: true,
			}),
			Mention.configure({
				HTMLAttributes: {
					class: "mention",
				},
				renderLabel({ options, node }) {
					return `${options.suggestion.char}${node.attrs.label}`;
				},
				suggestion: UserSuggestion(
					users.filter((item) => item.id !== auth.id)
				),
			}),
			Link.configure({
				validate: (href) => /^https?:\/\//.test(href),
			}),
			ExtHorizontalRule.configure({
				HTMLAttributes: {
					class: "border-none flex w-full h-[1px] rounded-full my-4 bg-border",
				},
			}),
		],
		onUpdate: ({ editor }) => setValue(parseHTML(editor)),
		onBlur: () => value !== _value && onChange(value),
		content: value,
	});

	const onUploadImage = async (e: any) => {
		if (!e?.target?.files?.length) {
			setLoadingImage(false);
			return;
		}
		const fd = new FormData();
		fd.append("file", e.target.files[0]);

		const { data: media } = await uploadFile("/media", fd).then(
			({ data }) => data
		);
		if (media.url && editor) {
			editor.commands.setImage({
				src: media.url,
			});
		}
		setLoadingImage(false);
	};

	const onUploadImageClick = () => {
		if (!imageInput.current) return;
		setLoadingImage(true);
		imageInput.current.click();
	};

	useEffect(() => {
		if (options?.autoFocus) {
			editor?.commands.focus("end");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<EditorContext.Provider
			value={{
				variant,
				editor,
				onUploadImageClick,
				loadingImage,
				setLoadingImage,
			}}
		>
			<Wrapper {...wrapper}>
				<div
					className={cn(
						"relative rounded-md  min-h-[300px] flex flex-col flex-1",
						variant === "bordered" && "border border-border",
						editorClassName
					)}
				>
					<input
						className="hidden absolute"
						accept=".png, .jpg, .jpeg"
						ref={imageInput}
						type="file"
						name="file"
						value=""
						onChange={onUploadImage}
						tabIndex={-1}
						onBlur={() => setLoadingImage(false)}
					/>
					{editor && (
						<>
							{variant !== "floating" && (
								<Header
									className={
										variant === "bordered"
											? " border-y-0 border-b-[1px]"
											: ""
									}
								/>
							)}
							<Header variant="bubble" />
							<Header variant="floating" />
						</>
					)}
					<div
						className={cn(
							"relative cursor-text p-1 pt-4 flex flex-col flex-1 z-[88]",
							variant === "floating" && "p-1 pt-1",
							variant === "bordered" && "p-2"
						)}
						onClick={() => {
							if (!value) {
								editor?.commands.focus("end");
							} else {
								editor?.commands.focus();
							}
						}}
					>
						<EditorContent
							{...{ editor }}
							className={cn(
								"cursor-text tiptapp-editor-content flex-1",
								isFocused &&
									"tiptap-editor-content_hide-placeholder"
							)}
							placeholder={placeholder || t("placeholder")}
							style={{ minHeight: "300px" }}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
						/>
					</div>
				</div>
			</Wrapper>
		</EditorContext.Provider>
	);
};

interface EditorPreviewProps extends ComponentProps<"div"> {
	value: string;
}

export const EditorPreview = ({
	value,
	className,
	...rest
}: EditorPreviewProps) => {
	return (
		<div className={cn("tiptapp-editor-content ProseMirror", className)}>
			<div dangerouslySetInnerHTML={{ __html: value }}></div>
		</div>
	);
};

Editor.locale = {
	nl: {
		placeholder: "Begin met typen...",
	},
	en: {
		placeholder: "Start typing...",
	},
};

export default withTranslation(Editor);
