interface RegistrationSkillsMatchItemProps {
	title: string;
	description: string;
	score: number;
	children?: any;
}

const RegistrationSkillsMatchItem = ({
	title,
	description,
	score,
	children,
}: RegistrationSkillsMatchItemProps) => {
	return (
		<>
			<hr className="border-border" />

			<div className="flex flex-col p-4">
				<div className="flex gap-6 items-center">
					<div className="py-4 px-6 rounded-md w-32 text-center">
						<h2 className="text-primary">+{score}</h2>
					</div>
					<div className="flex flex-col flex-1">
						<p>{title}</p>
						<p className="opacity-80">{description}</p>
						<div className="mt-3 empty:hidden">{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegistrationSkillsMatchItem;
