import { useState } from "react";
import Button from "src/components/Button";
import Radio from "src/components/form/Radio";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import OnboardingLayout from "src/components/layout/onboarding-layout/OnboardingLayout";
import useSetType from "src/hooks/api/services/auth/useSetType";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cn } from "src/lib/utils";

type Type = "company" | "student" | "freelancer";

const SetupTypeView = ({ t }: Translation) => {
	const { tenant } = useTenant();
	const [active, setActive] = useState<Type>();
	const { status, actions } = useSetType();

	const onSubmit = async () => {
		if (!active) return;

		await actions.setType(active === "company" ? "company" : "student");
	};

	if (tenant?.slug === "odylyt") {
		return (
			<div className="dark flex flex-1 flex-col justify-center mx-auto gap-8 w-full max-w-[444px] px-3">
				<h1 className="">{t("title")}</h1>
				<div className="flex flex-col gap-4">
					<Button
						onClick={() => setActive("student")}
						type={active === "student" ? "primary" : "gray"}
					>
						{t("personally")}
					</Button>
					<Button
						onClick={() => setActive("company")}
						type={active === "company" ? "primary" : "gray"}
					>
						{t("team")}
					</Button>
				</div>
				<Button
					disabled={!active}
					loading={status !== "idle"}
					onClick={onSubmit}
					type="primary"
					className="w-full"
				>
					<span>{t("continue")}</span>
					<i className="fas fa-arrow-right ml-2"></i>
				</Button>
			</div>
		);
	}

	return (
		<OnboardingLayout>
			<OnboardingLayout.Header>
				<OnboardingLayout.Title>{t("title")}</OnboardingLayout.Title>
				<OnboardingLayout.Description>
					{t("description")}
				</OnboardingLayout.Description>
			</OnboardingLayout.Header>

			<OnboardingLayout.Container className="">
				<div className="flex flex-col gap-3">
					{["company", "student"].map((type: any) => (
						<div
							key={`onboarding-${type}`}
							className={cn(
								"flex bg-accent border border-border rounded-md p-3 gap-2 items-center hover:border-primary cursor-pointer",
								active === type && "border-primary"
							)}
							onClick={() => setActive(type)}
						>
							<Radio value={active === type} variant="light" />
							<div className="flex flex-col flex-1">
								<p className="text-[16px]">
									{t(`${type}.title`)}
								</p>
								<p className="opacity-70 text-[16px]">
									{t(`${type}.description`)}
								</p>
							</div>
						</div>
					))}
				</div>
			</OnboardingLayout.Container>
			<OnboardingLayout.Footer>
				<div />
				<Button disabled={!active} onClick={onSubmit}>
					{t("continue")}
				</Button>
			</OnboardingLayout.Footer>
		</OnboardingLayout>
	);
};

SetupTypeView.locale = {
	nl: {
		title: "Hoe wil je {{tenant.name}} gebruiken?",
		description:
			"Je kan via {{tenant.name}} opdrachten uitvoeren voor bedrijven om zo een centje bij te verdienen. Ook is het mogelijk om als opdrachtgever opdrachten te plaatsen.",
		continue: "Doorgaan",
		personally: "Persoonlijk",
		team: "Team",
		student: {
			title: "Opdrachtnemer",
			description: "Het reageren en uitvoeren van opdrachten",
		},
		company: {
			title: "Opdrachtgever",
			description: "Het plaatsen en matchen van opdrachten",
		},
	},
	en: {
		title: "How do you want to use {{tenant.name}}?",
		description:
			"Through {{tenant.name}}, you can complete tasks for companies to earn some extra money. You can also post tasks as a client.",
		continue: "Continue",
		personally: "Personally",
		team: "Team",
		student: {
			title: "Task Performer",
			description: "Responding to and completing tasks",
		},
		company: {
			title: "Client",
			description: "Posting and matching tasks",
		},
	},
};

export default withTranslation(SetupTypeView);
