import { AnimatePresence } from "framer-motion";
import React, { useRef } from "react";
import Button from "src/components/Button";
import ShadowBackground from "src/components/Modal/ShadowBackground";
import AlertPopup from "src/components/alerts/AlertPopup";
import useAlert from "src/hooks/api/services/useAlert";
import useUnreads from "src/hooks/api/useUnreads";
import useBlur from "src/hooks/useBlur";

const AlertButton = () => {
	const { unreads } = useUnreads();
	const { open, actions } = useAlert();
	const ref = useRef(null);
	useBlur(ref, actions.setOpen);
	return (
		<div {...{ ref }} className="relative">
			<Button
				indicator={unreads.alerts}
				onClick={() => actions.setOpen(!open)}
				type="transparent"
				icon="fas fa-bell"
				iconOnly
			/>
			<AnimatePresence>
				{open && (
					<>
						<ShadowBackground
							onClick={() => actions.setOpen(false)}
							theme={{
								opacity: 0.1,
							}}
						/>
						<AlertPopup />
					</>
				)}
			</AnimatePresence>
		</div>
	);
};

export default AlertButton;
