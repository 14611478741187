import { FormEvent, useCallback, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { uploadFile } from "src/lib/uploadFile";
import ChatFileDropzone from "src/components/chat/ChatFileDropzone";
import ChatFileItem from "src/components/chat/ChatFileItem";
import Skeleton from "src/components/Skeleton";
import { Trans, useTranslation } from "react-i18next";
import Link from "src/components/Link";

type CreateMessageBoxProps = {
	id: number | string;
	is_denied?: boolean;
	type: "offer" | "task";
};

const CreateMessageBox = ({ id, is_denied, type }: CreateMessageBoxProps) => {
	const { t } = useTranslation("chat", {
		keyPrefix: "create-message-box",
	});
	const [message, setMessage] = useState("");
	const { isPhone } = useWindowSize();
	const auth = useAuth();
	const [postMessage] = usePost(`/inbox/${id}?type=${type}`);
	const [loading, setLoading] = useState(false);
	const size = useWindowSize();
	const fileUpload = useRef() as any;
	const [files, setFiles] = useState([] as any[]);

	const resetState = () => {
		setMessage("");
		setFiles([]);
		setLoading(false);
	};

	const handleFocusInput = () => {
		const input = document.querySelector("#chat-input") as any;
		if (!input) return;
		input.focus();
	};

	const handleSubmit = useCallback(
		async (e?: FormEvent) => {
			if (e) {
				e.preventDefault();
			}

			if (loading) return;

			if (!message && files.length === 0) return;
			setLoading(true);
			try {
				let media = [] as any;
				for (let file of files) {
					let fd = new FormData();
					fd.append("file", file);
					media.push(
						await uploadFile("/media", fd).then(
							({ data }) => data.data.id
						)
					);
				}

				await postMessage({ content: message, media });
				resetState();
				handleFocusInput();
			} catch (error) {
				setLoading(false);
			}
		},
		[files, loading, message, postMessage]
	);

	useEventListener("keydown", (e) => {
		if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
			e.preventDefault();
			handleSubmit();
		}
	});

	if (is_denied) {
		return (
			<CreateMessageBoxCard>
				<p className={`mb-0 `}>
					{auth.type === "student"
						? t("denied-student")
						: t("denied-company")}
				</p>
			</CreateMessageBoxCard>
		);
	}

	if (auth.type === "company" && !auth.has_subscription) {
		return (
			<CreateMessageBoxCard>
				<div className="flex items-center">
					<p>
						<Trans {...{ t }} i18nKey="no-subscription">
							Om te kunnen chatten met de professionals heb je een
							actief abonnement nodig
							<Link
								to="/subscription"
								className="underline font-bold"
							>
								Klik hier
							</Link>
							om een abonnement te kiezen
						</Trans>
					</p>
				</div>
			</CreateMessageBoxCard>
		);
	}

	return (
		<>
			<CreateMessageBoxCard>
				{files.length > 0 ? (
					<div className="flex flex-wrap md:flex-row flex-col md:items-center gap-2 mb-2">
						{files.map((file, index) => (
							<ChatFileItem
								key={file.path}
								onDelete={() =>
									setFiles(
										files.filter((_, i) => i !== index)
									)
								}
								{...{ file }}
							/>
						))}
					</div>
				) : (
					false
				)}

				<ChatFileDropzone
					onSubmit={(file) => setFiles([...files, file])}
					ref={fileUpload}
				/>

				<form
					className="flex items-center gap-1 p-1"
					onSubmit={handleSubmit}
				>
					<Input
						inputId="chat-input"
						// type="transparent-dark"
						className="textarea-small flex-1 placeholder:text-placeholder"
						multiline={!isPhone && auth.type !== "student"}
						name="message"
						value={message}
						onChange={(_, value) => setMessage(value)}
						placeholder={t("placeholder")}
					/>

					<Button
						onClick={() => {
							fileUpload.current.toggle();
						}}
						disabled={loading}
						className="h-[42px] w-[42px]"
						type="white"
						iconOnly
					>
						<i className="far fa-paperclip"></i>
					</Button>
					{!isPhone && auth.type !== "student" && (
						<Button
							className="gtm-opdracht-chat--button-versturen flex justify-center items-center h-[42px] w-[42px] md:w-max md:h-[42px]"
							type="primary"
							{...{ loading }}
							disabled={loading}
							submit
							iconOnly={size.isPhone}
						>
							<span className="md:flex hidden">{t("cta")}</span>
							{(!loading || !size.isPhone) && (
								<i className="fas fa-paper-plane md:ml-2"></i>
							)}
						</Button>
					)}
				</form>
			</CreateMessageBoxCard>
		</>
	);
};

interface CreateMessageBoxCardProps {
	children?: any;
	className?: string;
}

const CreateMessageBoxCard = ({
	children,
	className,
}: CreateMessageBoxCardProps) => {
	return (
		<div
			className={` relative rounded-md border-t-1 border-border text-background-foreground ${className}`}
		>
			{children}
		</div>
	);
};

const Loading = () => (
	<CreateMessageBoxCard>
		<div className="flex flex-1 items-center gap-2">
			<div className="flex-1"></div>
			<Skeleton className="h-[50px] w-[50px]" />
			<Skeleton className="h-[50px] w-[50px] md:w-[170px]" />
		</div>
	</CreateMessageBoxCard>
);

CreateMessageBox.Loading = Loading;

export default CreateMessageBox;
