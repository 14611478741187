// import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import TagManager from "react-gtm-module";

const LINKEDIN_INSIGHT_ID = "3235921";
// const GOOGLE_TRACKING_ID = "UA-158401233-3";
const FACEBOOK_PIXEL_ID = "801533813749231";
const GOOGLE_ADS_ID = "AW-478314567";

function init() {
	// Enable debug mode on the local development environment
	const isDev =
		!process?.env?.NODE_ENV || process?.env?.NODE_ENV === "development";
	if (isDev) {
		console.log("RUNNING ANALYTICS IN DEV ENVIROMENT");
	}

	// ReactGA.initialize(GOOGLE_TRACKING_ID, { debug: isDev });
	ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, {
		debug: isDev,
		autoConfig: false,
	});
	LinkedInTag.init(LINKEDIN_INSIGHT_ID, "dc", false);
	TagManager.initialize({
		gtmId: "GTM-KBKBB5V",
	});

	const head = document.querySelector("head");

	let scriptSrc = document.createElement("script");
	scriptSrc.setAttribute("async", "true");
	scriptSrc.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADS_ID}`;

	let script = document.createElement("script");
	script.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GOOGLE_ADS_ID}');`;
	// let scriptLeadInfo = document.createElement("script");
	// scriptLeadInfo.innerHTML = `(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
	//     l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
	//     l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
	//     }(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-6094F939C710E"));`;

	if (head) {
		head.appendChild(scriptSrc);
		head.appendChild(script);
		// head.appendChild(scriptLeadInfo);
	}
}

function sendEvent(payload: any) {
	// ReactGA.event(payload);
}

function sendPageview(path: string) {
	// ReactGA.set({ page: path });
	// ReactGA.pageview(path);
	ReactPixel.pageView(); // For tracking page view
}

export default {
	init,
	sendEvent,
	sendPageview,
} as any;
