import moment from "moment";
import { useTranslation } from "react-i18next";
import Skeleton from "src/components/Skeleton";

interface TrackPaymentItemProps {
	created_at?: string;
	status: string;
	variant: "default" | "first" | "last";
}

const TrackPaymentItem = ({
	created_at,
	status,
	variant,
}: TrackPaymentItemProps) => {
	const { t } = useTranslation("payments");
	let icon = "fa-check";
	let activeColor = "primary";
	if (["chargeback", "cancelled", "refunded"].includes(status)) {
		icon = "fa-times";
		activeColor = "red";
	}

	return (
		<div className="flex gap-4 items-stretch flex-1 min-h-16">
			<div className="w-16 flex justify-center items-center flex-col">
				<div
					className={`flex flex-1 bg-${
						variant !== "first" ? "dark-300" : "transparent"
					} w-1`}
				></div>
				<div
					className={`w-8 h-8 rounded-full border-2 flex items-center justify-center text-white border-${activeColor} bg-${
						created_at ? activeColor : "gray"
					}`}
				>
					{created_at && (
						<i className={`fas text-[12px] ${icon}`}></i>
					)}
				</div>
				<div
					className={`flex flex-1 bg-${
						variant !== "last" ? "dark-300" : "transparent"
					} w-1`}
				></div>
			</div>
			<div
				className={`flex-1 gap-1 flex flex-col justify-center py-4 ${
					created_at ? "opacity-100" : "opacity-50"
				}`}
			>
				<p className="mb-0">{t(`status.${status}`)}</p>
				<p className="opacity-50 mb-0">
					{created_at
						? moment(created_at).fromNow()
						: t("status.pending")}
				</p>
			</div>
		</div>
	);
};

TrackPaymentItem.defaultProps = {
	variant: "default",
};

interface TrackPaymentItemLoadingProps {
	variant?: "default" | "first" | "last";
}

export const TrackPaymentItemLoading = ({
	variant,
}: TrackPaymentItemLoadingProps) => (
	<div className="flex gap-4 items-stretch flex-1 min-h-16">
		<div className="w-16 flex justify-center items-center flex-col">
			<Skeleton
				className={`flex flex-1 rounded-none bg-${
					variant !== "first" ? "dark-300" : "transparent"
				} w-1`}
			></Skeleton>
			<Skeleton
				className={`w-6 h-6 rounded-full border-2 flex items-center justify-center border-dark-300 bg-accent`}
			/>
			<Skeleton
				className={`flex flex-1 rounded-none bg-${
					variant !== "last" ? "dark-300" : "transparent"
				} w-1`}
			></Skeleton>
		</div>
		<div className="flex-1 flex flex-col justify-center">
			<Skeleton className="h-4 w-[40%] mb-2 bg-dark-300" />
			<Skeleton className="h-4 w-full bg-dark-300" />
		</div>
	</div>
);

export default TrackPaymentItem;
