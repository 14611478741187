import moment from "moment";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import Wrapper from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import PaymentRequestCustomForms from "src/components/payment-request/payment-request-form/PaymentRequestCustomForms";
import PaymentRequestCustomFormsDetails from "src/components/payment-request/payment-request-form/PaymentRequestCustomFormsDetails";
import PaymentRequestPriceField, {
	PaymentRequestPriceFieldTrigger,
} from "src/components/payment-request/payment-request-form/PaymentRequestPriceField";
import { PaymentRequestFormValue } from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useConnections from "src/hooks/api/services/users/useConnections";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import useHoursBetweenDates from "src/hooks/useHoursBetweenDates";
import { Result, useParseDatesByValue } from "src/hooks/useParseDatesState";
import useQueryString from "src/hooks/useQueryString";
import useWindowSize from "src/hooks/useWindowSize";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

const formatParseDate = (value: Result) => {
	let start: any = { date: undefined };
	let end: any = { date: undefined };

	if (value?.start) {
		start.date = moment(value.start.date()).format("YYYY-MM-DD HH:mm");
	}
	if (value?.end) {
		end.date = moment(value.end.date()).format("YYYY-MM-DD HH:mm");
	}

	return {
		date: moment(value.date()).format("YYYY-MM-DD"),
		start,
		end,
	} as any;
};

type Action = "delete";

interface PaymentRequestFullFormProps extends Translation {
	connectionId?: string;
	onSubmit: (values: PaymentRequestFormValue) => Promise<void>;
	defaultValue?: PaymentRequestFormValue;
	actions?: Action[];
}

const PaymentRequestFullForm = ({
	t,
	onSubmit,
	defaultValue,
	connectionId,
	className,
	actions = [],
	...rest
}: Omit<ComponentProps<"div">, "onSubmit" | "defaultValue"> &
	PaymentRequestFullFormProps) => {
	const [sendAlert] = useAlert();
	const qs = useQueryString();
	const auth = useAuth();
	const connectionKey =
		auth.type === "student" ? "company_id" : "receiver_id";
	const [isLoading, setIsLoading] = useState<"save" | "process">();
	const [value, setValue] = useState<PaymentRequestFormValue>(
		defaultValue || {
			description: qs?.description ? qs.description.toString() : "",
			type: qs.task_id ? "HOUR_RATE" : "FIXED_FEE",
			start_date: moment().format("YYYY-MM-DD HH:mm"),
			from_task_id: qs.task_id ? qs.task_id.toString() : undefined,
		}
	);
	const { isPhone } = useWindowSize();
	const fieldLayout = isPhone ? "horizontal-always" : "vertical";
	const { dates } = useParseDatesByValue(value?.description || "", {
		format: formatParseDate,
	});
	const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
	const hours = useHoursBetweenDates(value?.start_date, value?.end_date);
	const isFullyDisabled = [
		"APPROVED",
		"COMPLETED",
		"DENIED",
		"FINALIZING",
	].includes(value?.status || "");
	const isDisabled = useMemo(() => {
		if (isLoading) return true;
		if (value.type === "HOUR_RATE") {
			return !(
				value.start_date &&
				value.end_date &&
				value.description &&
				(value[connectionKey] || connectionId) &&
				hours &&
				hours >= 0
			);
		}
		return !(
			value.price &&
			value.start_date &&
			value.description &&
			(value[connectionKey] || connectionId)
		);
	}, [connectionId, connectionKey, hours, isLoading, value]);
	const companyId =
		auth.type === "student"
			? value[connectionKey] || connectionId
			: auth?.company?.hid;
	const { connections } = useConnections();
	const selectedConnection = connections.find(
		(connection) =>
			connection.id === value[connectionKey] ||
			connection.id === connectionId
	);

	const isOnlyFromTask =
		!!selectedConnection?.settings?.only_from_task &&
		auth.type === "student";
	const isDateDisabled = !!qs.task_id && isOnlyFromTask;

	useEffect(() => {
		if (dates?.length > 0) {
			const item: any = dates.find((item: any) => item.start && item.end);
			if (item) {
				setValue((prev) => ({
					...prev,
					start_date: item.start.date,
					end_date: item.end.date,
				}));
			}
		}
	}, [dates]);

	const handleTabChange = (tab: "FIXED_FEE" | "HOUR_RATE") => {
		if (isFullyDisabled) return;

		setValue((prev) => ({
			...prev,
			type: tab,
			...(tab === "FIXED_FEE"
				? { end_date: undefined, break: undefined }
				: { price: undefined }),
		}));
	};

	const handleTimeInput = (
		name: "start_date" | "end_date",
		timeValue: any
	) => {
		const time = moment(timeValue, "HH:mm");
		let val = value[name];
		if (val === "Invalid date") {
			val = value.start_date;
		}
		if (!val && value?.start_date) {
			val = value.start_date;
		}
		let date = moment(val);
		date.set({
			hour: time.get("hour"),
			minute: time.get("minute"),
			second: time.get("second"),
		});

		setValue((prev) => ({
			...prev,
			[name]: date.format("YYYY-MM-DD HH:mm"),
		}));
	};

	const handleSave = async (event: any) => {
		event.preventDefault();
		if (isDisabled) return;

		setIsLoading("save");
		await onSubmit({ ...value })
			.then(() => {
				setValue({});
			})
			.finally(() => {
				setIsLoading(undefined);
			});
	};

	const handleProcess = async (event: any) => {
		event.preventDefault();
		if (isDisabled) return;

		const execute = async () => {
			setIsLoading("process");
			await onSubmit({
				...value,
				status: auth.type === "student" ? "PENDING" : "APPROVED",
			})
				.then(() => {
					setValue({});
				})
				.finally(() => {
					setIsLoading(undefined);
				});
		};

		sendAlert({
			type: "confirm",
			title: t(`process.confirm.${auth.type}.title`),
			text: t(`process.confirm.${auth.type}.text`),
			onConfirm: () => {
				execute();
			},
		});
	};

	if (isOnlyFromTask && !qs.task_id) {
		return (
			<div
				className={cn("flex flex-col flex-1 gap-4", className)}
				{...rest}
			>
				<div className="flex flex-col gap-2">
					<strong>{t("only-from-task.title")}</strong>
					<p>{t("only-from-task.description")}</p>
				</div>
				<Field.Connection
					disabled={!!connectionId || isFullyDisabled}
					onChange={(value) =>
						setValue((prev) => ({
							...prev,
							[connectionKey]: value,
						}))
					}
					value={connectionId || value[connectionKey]}
					className="border-border md:min-w-0 md:w-auto w-full"
					wrapper={{
						className: "w-full md:w-max",
						layout: fieldLayout,
						label: t(
							`fields.${
								auth.type === "student" ? "company" : "student"
							}.label`
						),
					}}
				/>
			</div>
		);
	}

	return (
		<div className={cn("flex flex-col flex-1", className)} {...rest}>
			<div className="flex items-center justify-between mb-8 gap-6">
				<div className="flex">
					<Tabs.Container>
						<Tab
							onClick={() => handleTabChange("FIXED_FEE")}
							active={value.type === "FIXED_FEE"}
						>
							{t("tabs.fixed-fee")}
						</Tab>
						<Tab
							onClick={() => handleTabChange("HOUR_RATE")}
							active={value.type === "HOUR_RATE"}
						>
							{t("tabs.hours")}
						</Tab>
					</Tabs.Container>
				</div>
				<div className="flex items-center flex-wrap gap-3">
					{actions.includes("delete") && !isFullyDisabled && (
						<Button
							xsmall
							type="ghost"
							to={{
								modal: `/payment-requests/${value.id}/delete`,
							}}
						>
							{t("buttons.cancel")}
						</Button>
					)}
				</div>
			</div>
			<div className="flex flex-col gap-1 relative">
				{isFullyDisabled ? (
					<p className="bg-transparent p-1 flex-1">
						{value?.description}
					</p>
				) : (
					<Field.Input
						className="w-full max-w-full border-border"
						wrapper={{
							layout: "vertical",
							label: t(`fields.description.${auth.type}.label`),
							className: "w-full max-w-full",
						}}
						placeholder={t(
							`fields.description.${auth.type}.placeholder`
						)}
						value={value?.description}
						onChange={(description) =>
							setValue((prev) => ({
								...prev,
								description: description || "",
							}))
						}
						as="textarea"
						onFocus={() => setIsDescriptionFocused(true)}
						onBlur={() => setIsDescriptionFocused(false)}
					/>
				)}

				{value.type === "HOUR_RATE" && (
					<small
						className={cn(
							"opacity-0 absolute bottom-0 transition-all",
							isDescriptionFocused && "opacity-70"
						)}
					>
						{t("fields.description.disclaimer")}
					</small>
				)}
			</div>

			<div className="flex flex-col gap-4 pb-4">
				<div
					className={cn(
						"flex items-end justify-between gap-3 mt-4 w-full flex-col md:flex-row p-3 border border-border rounded-md"
					)}
				>
					{value.type === "HOUR_RATE" && (
						<div className="flex items-end flex-col md:flex-row gap-3 w-full">
							<Field.Date
								disabled={isFullyDisabled || isDateDisabled}
								value={value?.start_date}
								onChange={(_date) => {
									const date =
										moment(_date).format(
											"YYYY-MM-DD HH:mm"
										);
									setValue((prev) => ({
										...prev,
										start_date: date || undefined,
										end_date: date || undefined,
									}));
								}}
								className="border-border md:min-w-0 md:w-auto w-full"
								wrapper={{
									label: t("fields.date.label"),
									layout: fieldLayout,
									className: "w-full md:w-max",
								}}
							/>

							<Field.Input
								disabled={isFullyDisabled}
								type="time"
								value={
									value?.start_date
										? moment(value.start_date).format(
												"HH:mm"
										  )
										: ""
								}
								onChange={(value) =>
									handleTimeInput("start_date", value)
								}
								className="border-border md:min-w-0 md:w-auto w-full"
								wrapper={{
									label: t("fields.start.label"),
									layout: fieldLayout,
									className: "w-full md:w-max",
								}}
							/>

							<Field.Input
								disabled={isFullyDisabled}
								type="time"
								value={
									value?.end_date
										? moment(value.end_date).format("HH:mm")
										: ""
								}
								onChange={(value) =>
									handleTimeInput("end_date", value)
								}
								className="border-border md:min-w-0 md:w-auto w-full"
								wrapper={{
									label: t("fields.end.label"),
									layout: fieldLayout,
									className: "w-full md:w-max",
								}}
							/>
							<Field.Input
								disabled={isFullyDisabled}
								type="number"
								step={0.1}
								value={value?.break?.toString()}
								onChange={(value) =>
									setValue((prev) => ({
										...prev,
										break: value
											? Number(value)
											: undefined,
									}))
								}
								placeholder={t("fields.break.placeholder")}
								className="border-border md:min-w-0 md:w-auto w-full md:max-w-[72px]"
								wrapper={{
									className: "w-full md:w-max",
									layout: fieldLayout,
									label: t("fields.break.label"),
								}}
							/>
							{auth.type === "company" && (
								<Wrapper
									label={t("fields.price.label")}
									layout={fieldLayout}
									className="w-full md:w-max"
								>
									<PaymentRequestPriceField
										onChange={setValue}
										value={value}
										disabled={isFullyDisabled}
									>
										<PaymentRequestPriceFieldTrigger className="w-full md:w-[120px] text-left justify-between relative">
											<p
												className={cn(
													"line-clamp-1",
													!value?.price &&
														"text-placeholder"
												)}
											>
												{value?.price
													? formatPrice(value.price)
													: t(
															"fields.price.placeholder"
													  )}
											</p>
											<div
												className={cn(
													"absolute top-0 bottom-0 flex items-center right-2 pointer-events-none opacity-100 transition-all",
													value.price && "opacity-0"
												)}
											>
												<i className="fas fa-pencil text-sm text-border"></i>
											</div>
										</PaymentRequestPriceFieldTrigger>
									</PaymentRequestPriceField>
								</Wrapper>
							)}

							<Field.Connection
								disabled={!!connectionId || isFullyDisabled}
								onChange={(value) =>
									setValue((prev) => ({
										...prev,
										[connectionKey]: value,
									}))
								}
								value={connectionId || value[connectionKey]}
								className="border-border md:min-w-0 md:w-auto w-full max-w-[200px]"
								wrapper={{
									className: "w-full md:w-max",
									layout: fieldLayout,
									label: t(
										`fields.${
											auth.type === "student"
												? "company"
												: "student"
										}.label`
									),
								}}
							/>
						</div>
					)}

					{value.type === "FIXED_FEE" && (
						<div className="flex flex-col md:flex-row md:items-end gap-3 w-full">
							<Field.Date
								disabled={isFullyDisabled}
								value={value?.start_date}
								onChange={(date) => {
									setValue((prev) => ({
										...prev,
										start_date: date || undefined,
									}));
								}}
								className="border-border md:min-w-0 md:w-auto w-full"
								wrapper={{
									label: t("fields.date.label"),
									layout: fieldLayout,
									className: "w-full md:w-max",
								}}
							/>

							<Field.Input
								disabled={isFullyDisabled}
								type="number"
								step={0.01}
								value={value?.price?.toString()}
								onChange={(value) =>
									setValue((prev) => ({
										...prev,
										price: value
											? Number(value)
											: undefined,
									}))
								}
								placeholder={t("fields.price.placeholder")}
								className="border-border md:min-w-0 md:w-auto w-full"
								wrapper={{
									className: "w-full md:w-max",
									layout: fieldLayout,
									label: t("fields.price.label"),
								}}
							/>
							<Field.Connection
								disabled={!!connectionId || isFullyDisabled}
								onChange={(value) =>
									setValue((prev) => ({
										...prev,
										[connectionKey]: value,
									}))
								}
								value={connectionId || value[connectionKey]}
								className="border-border md:min-w-0 md:w-auto w-full"
								wrapper={{
									className: "w-full md:w-max",
									layout: fieldLayout,
									label: t(
										`fields.${
											auth.type === "student"
												? "company"
												: "student"
										}.label`
									),
								}}
							/>
						</div>
					)}
				</div>

				{value.start_date &&
					value.end_date &&
					hours !== null &&
					value.type === "HOUR_RATE" && (
						<div className="flex items-end flex-col md:flex-row gap-3 w-full opacity-70 mt-1.5">
							<small>
								{t("total-hours", {
									total: hours - (value.break || 0),
								})}
							</small>
						</div>
					)}
				{isFullyDisabled ? (
					<>
						{Object.keys(value.forms || {})?.length > 0 && (
							<PaymentRequestCustomFormsDetails
								request={value}
								{...{ companyId }}
							/>
						)}
					</>
				) : (
					<PaymentRequestCustomForms
						key={companyId}
						value={value?.forms || {}}
						onChange={(values) =>
							setValue((prev) => ({ ...prev, forms: values }))
						}
						variant="list"
						{...{ companyId }}
					/>
				)}
			</div>

			{auth.type === "student" && !isFullyDisabled && (
				<div className="flex flex-col-reverse md:flex-row md:items-center md:justify-end gap-3 mt-6 pt-2 pb-4 md:p-0 sticky bottom-0">
					<div className="absolute inset-0 bg-background opacity-90 pointer-events-none"></div>
					<Button
						loading={isLoading === "save"}
						disabled={isDisabled}
						type="transparent"
						name="save"
						onClick={handleSave}
					>
						{t(value.id ? "buttons.update" : "buttons.submit")}
					</Button>
					<Button
						loading={isLoading === "process"}
						disabled={isDisabled}
						onClick={handleProcess}
					>
						{t(
							value.id
								? "buttons.update-send"
								: "buttons.submit-send"
						)}
					</Button>
				</div>
			)}
			{auth.type === "company" && !isFullyDisabled && (
				<div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-3 mt-8 pt-2 md:mt-6 sticky bottom-0">
					<div className="absolute inset-0 bg-background opacity-90 pointer-events-none"></div>
					<div className="flex md:items-center gap-3">
						{value.id && (
							<Button
								type="transparent"
								to={{
									modal: `/payment-requests/deny?ids=${value.id}`,
								}}
								className="flex-1 md:flex-auto"
							>
								{t("buttons.deny")}
							</Button>
						)}
					</div>
					<div className="flex-col-reverse flex md:flex-row md:items-center gap-3">
						<Button
							loading={isLoading === "save"}
							disabled={isDisabled}
							type="transparent"
							className="flex-1 md:flex-auto"
							onClick={handleSave}
						>
							{t(value.id ? "buttons.update" : "buttons.submit")}
						</Button>
						<Button
							loading={isLoading === "process"}
							disabled={isDisabled || !value.price}
							className="flex-1 md:flex-auto"
							onClick={handleProcess}
						>
							{t(
								value.id
									? "buttons.update-accept"
									: "buttons.submit-accept"
							)}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

PaymentRequestFullForm.locale = {
	nl: {
		tabs: {
			hours: "Uren",
			"fixed-fee": "Vaste prijs",
		},
		"only-from-task": {
			title: "Alleen vanuit taken",
			description:
				"Voor deze opdrachtgever is het alleen mogelijk om een betaling vanuit een opdracht te maken",
		},
		or: "Of",
		fields: {
			description: {
				disclaimer:
					"Benoem de tijd en datum in de tekst, zo worden de velden hieronder automatisch gezet.",
				student: {
					label: "Werkzaamheden",
					placeholder: "Ik heb de volgende werkzaamheden gedaan...",
				},
				company: {
					label: "Werkzaamheden",
					placeholder: "De volgende werkzaamheden zijn uitgevoerd...",
				},
			},
			date: {
				label: "Datum",
			},
			price: {
				label: "Prijs",
				placeholder: "Prijs in euro",
			},
			start: {
				label: "Start",
			},
			end: {
				label: "Eind",
			},
			break: {
				label: "Pauze",
				placeholder: "In uren",
			},
			company: {
				label: "Opdrachtgever",
			},
			student: {
				label: "Opdrachtnemer",
			},
		},
		"total-hours": "Totaal {{total}} uur gewerkt",
		buttons: {
			submit: "Aanmaken",
			"submit-send": "Aanmaken & Versturen",
			"update-send": "Opslaan & Versturen",
			update: "Opslaan",
			cancel: "Annuleren",
			"update-accept": "Opslaan & Goedkeuren",
			"submit-accept": "Aanmaken & Goedkeuren",
			deny: "Afwijzen",
		},
		process: {
			confirm: {
				student: {
					title: "Weet je het zeker?",
					text: "Wanneer je de betaling verwerkt, wordt de betaling automatisch verstuurd naar de opdrachtgever. Wees er zeker van dat je dit wilt doen.",
				},
				company: {
					title: "Weet je het zeker?",
					text: "Het betaalverzoek wordt goedgekeurd en is direct klaar om een uitbetaling aan te maken. Wees er zeker van dat je dit wilt doen.",
				},
			},
		},
	},
	en: {
		tabs: {
			hours: "Hours",
			"fixed-fee": "Fixed Fee",
		},
		"only-from-task": {
			title: "Only from tasks",
			description: "Only possible to make a payment from a task",
		},
		or: "Or",
		fields: {
			description: {
				disclaimer:
					"Specify the time and date in the text, so the fields below will be automatically filled.",
				student: {
					label: "Tasks",
					placeholder: "I have performed the following tasks...",
				},
				company: {
					label: "Tasks",
					placeholder: "The following tasks have been executed...",
				},
			},
			date: {
				label: "Date",
			},
			price: {
				label: "Price",
				placeholder: "Price in euros",
			},
			start: {
				label: "Start",
			},
			end: {
				label: "End",
			},
			break: {
				label: "Break",
				placeholder: "In hours",
			},
			company: {
				label: "Client",
			},
			student: {
				label: "Contractor",
			},
		},
		"total-hours": "Worked a total of {{total}} hours",
		buttons: {
			submit: "Create",
			"submit-send": "Create & Send",
			"update-send": "Save & Send",
			update: "Save",
			cancel: "Cancel",
			"update-accept": "Save & Approve",
			"submit-accept": "Create & Approve",
			deny: "Reject",
		},
		process: {
			confirm: {
				student: {
					title: "Are you sure?",
					text: "When you process the payment, the payment will be automatically sent to the client. Make sure you want to do this.",
				},
				company: {
					title: "Are you sure?",
					text: "The payment request will be approved and will be ready to create a payout immediately. Make sure you want to do this.",
				},
			},
		},
	},
};

export default withTranslation(PaymentRequestFullForm);
