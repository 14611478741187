import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Loader from "src/components/Loader";
import Indicator from "src/components/layout/Indicator";
import useElementSize from "src/hooks/useElementSize";
import useVariant, { VariantTypes } from "src/hooks/useVariant";
import { lightenDarkenColor } from "src/lib/colors";
import { cn } from "src/lib/utils";
import "./button.scss";

type To = {
	modal: string;
	back?: {
		to: string;
	};
};

type ButtonTheme = VariantTypes | string;

export type ButtonProps = {
	// type: VariantTypes;
	type?: ButtonTheme;
	onClick?: (e?: any) => any;
	children?: any;
	loading?: boolean;
	name?: string;
	disabled?: boolean;
	to?: string | To;
	submit?: boolean;
	small?: boolean;
	xsmall?: boolean;
	icon?: string;
	href?: string;
	className?: string;
	noBlank?: boolean;
	style?: any;
	target?: string;
	help?: string;
	id?: string;
	iconOnly?: boolean;
	download?: boolean;
	indicator?: any;
	tabIndex?: string | number;
	gtm?: string;
	as?: "button" | "div";
};

const Button = ({
	type,
	onClick,
	children,
	loading = true,
	name,
	to,
	disabled,
	submit,
	small,
	xsmall,
	icon,
	href,
	className: _className,
	noBlank,
	style,
	target,
	help,
	id,
	iconOnly,
	download,
	indicator,
	tabIndex,
	gtm,
	as: _as,
}: ButtonProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const location = useLocation();
	const Wrapper = _as ? _as : "button";
	if (typeof to === "string" && to?.startsWith("http")) {
		href = to;
		to = undefined;
	}

	const isLoading = loading || isSubmitting;

	const variant = useVariant((type === "orange" ? "primary" : type) as any);
	const { ref, size } = useElementSize();

	const className = cn(
		"py-2 px-5 button rounded-md transition-all text-center justify-center flex items-center relative whitespace-nowrap font-medium select-none",
		!iconOnly && "[&>i:not(.icon-only)]:text-sm",
		type && variant,
		isLoading && "opacity-90 cursor-wait",
		disabled && "opacity-50 button_disabled cursor-not-allowed",
		xsmall && "rounded-md border px-3 py-1.5 text-[14px] gap-1.5 w-max",
		small && "py-2 px-4 text-[16px]",
		iconOnly && `p-2 w-[40px] h-[${size.width}px]`,
		gtm,
		_className
	);

	const renderChildren = () => {
		return (
			<>
				{isLoading ? <Loader.Overlay /> : <></>}

				{icon ? (
					<i
						className={cn("icon-only", icon)}
						style={{ pointerEvents: "none" }}
					></i>
				) : (
					children
				)}

				{indicator ? (
					<>
						{iconOnly ? (
							<Indicator className="absolute -top-2 -right-2">
								{indicator}
							</Indicator>
						) : (
							<Indicator className="absolute -top-[3px] -right-1">
								{indicator}
							</Indicator>
						)}
					</>
				) : (
					false
				)}
			</>
		);
	};

	if (to) {
		return (
			<>
				{help && (
					<ReactTooltip id={`tooltip-${id}`} effect="solid">
						{help}
					</ReactTooltip>
				)}
				<NavLink
					data-tip
					data-for={`tooltip-${id}`}
					to={typeof to === "string" ? to : to?.modal}
					state={
						typeof to !== "string" && to?.modal
							? {
									background:
										location?.state?.background || location,
									back: to?.back,
							  }
							: undefined
					}
					{...{ style, target, ref, className }}
					onClick={(e) => {
						if (disabled || isLoading) {
							e.preventDefault();
						}
					}}
					// className={classes.join(" ")}
				>
					{renderChildren()}
				</NavLink>
			</>
		);
	}

	if (href) {
		return (
			<>
				{help && (
					<ReactTooltip id={`tooltip-${id}`} effect="solid">
						{help}
					</ReactTooltip>
				)}
				<a
					data-tip
					data-for={`tooltip-${id}`}
					{...{ href, style, download, ref, className }}
					target={!noBlank ? "_blank" : undefined}
					rel="noopener noreferrer"
					// className={classes.join(" ")}
				>
					{renderChildren()}
				</a>
			</>
		);
	}

	return (
		<>
			{help && (
				<ReactTooltip id={`tooltip-${id}`} effect="solid">
					{help}
				</ReactTooltip>
			)}
			<Wrapper
				data-tip
				data-for={`tooltip-${id}`}
				type={submit ? "submit" : "button"}
				{...{ style, ref, className, name }}
				// className={classes.join(" ")}
				onClick={async (e) => {
					if (disabled) {
						e.preventDefault();
					}
					if (!disabled && onClick && !isLoading) {
						const result = onClick(e);
						if (result instanceof Promise) {
							try {
								setIsSubmitting(true);
								await result;
							} finally {
								setIsSubmitting(false);
							}
						}
					}
				}}
				tabIndex={tabIndex ? Number(tabIndex) : undefined}
			>
				{renderChildren()}
			</Wrapper>
		</>
	);
};

Button.defaultProps = {
	type: "dark",
	onClick: () => {},
	loading: false,
};

interface IconButtonProps {
	onClick?: () => void;
	variant: VariantTypes;
	icon: string;
	loading: boolean;
	customColor?: string;
}

const IconButton = ({
	onClick,
	variant,
	icon,
	loading,
	customColor,
}: IconButtonProps) => {
	const classes = ["text-[16px] w-8 h-8 rounded-md"];
	const variantClassName = useVariant(variant);
	const style = {} as any;

	if (!customColor) {
		classes.push(variantClassName);
	}

	if (customColor) {
		style.color = customColor;
		style.backgroundColor = lightenDarkenColor(customColor, 99);
	}

	return (
		<button className={classes.join(" ")} {...{ onClick, style }}>
			{loading ? (
				<span className="base-loader"></span>
			) : (
				<i className={icon}></i>
			)}
		</button>
	);
};

IconButton.defaultProps = {
	variant: "gray",
	loading: false,
};

Button.Icon = IconButton;

export default Button;
