import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useLogin from "src/hooks/api/services/auth/useLogin";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";
import { loginFacebook } from "src/lib/facebookInit";
import Button from "src/components/Button";
import { useState } from "react";
import { cn } from "src/lib/utils";

interface FacebookButtonProps extends Translation {
	label?: string;
	type: "signup" | "login";
	redirectUrl?: string;
	hideDisclaimer?: boolean;
	className?: string;
	params?: any;
}

const FacebookButton = ({
	t,
	label: _label,
	type,
	className,
	hideDisclaimer,
	params,
}: FacebookButtonProps) => {
	const { actions } = useLogin();
	const label = _label || t("signup");
	const [loading, setLoading] = useState(false);
	const [loginFacebookUser] = usePost("/auth/login/facebook");
	const classes = ["facebook-button"];
	const [sendAlert] = useAlert();

	if (loading) classes.push("facebook-button_loading");

	const handleSubmit = (user: any) => {
		let data = {
			email: user.email,
			facebook_id: user.id,
			access_token: user.accessToken,
			...(params || {}),
		};
		if (data.email && data.facebook_id) {
			loginFacebookUser(data)
				.then(async ({ data }: any) => {
					if (data.id) {
						await actions.authenticate(data);
						setLoading(false);
					}
				})
				.catch(() => {
					sendAlert({
						title: t("alert.login-failed"),
						text: "",
						type: "error",
					});
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	return (
		<div className={cn("flex flex-col gap-1", className)}>
			<Button
				type="facebook"
				gtm={`gtm-inloggen--cta-${type}-met-facebook`}
				onClick={() => {
					setLoading(true);
					loginFacebook().then(handleSubmit);
				}}
				{...{ loading }}
			>
				<i
					style={{ marginRight: 10 }}
					className="fab fa-facebook-square"
				/>
				{label}
			</Button>
			{!hideDisclaimer && <i className="italic">{t("disclaimer")}</i>}
		</div>
	);
};

FacebookButton.locale = {
	nl: {
		signup: "Aanmelden met Facebook",
		disclaimer: "Alleen voor opdracht uitvoerders",
		alert: {
			"login-failed": "Inloggen mislukt",
		},
	},
	en: {
		signup: "Sign up with Facebook",
		disclaimer: "Only for task completers",
		alert: {
			"login-failed": "Login failed",
		},
	},
};

export default withTranslation(FacebookButton);
