import { useEffect, useState } from "react";
import { AutoFillTypes } from "src/api/types";
import useGet from "src/hooks/rest/useGet";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

import { Command, CommandGroup, CommandItem } from "src/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";

interface AutoFillOptionsProps {
	onSelect: (str: string) => void;
	value: string;
	type?: AutoFillTypes;
	query?: string;
	children?: any;
}

const AutoFillOptions = ({
	value,
	onSelect,
	type,
	query: _query,
	children,
}: AutoFillOptionsProps) => {
	const [visible, setVisible] = useState(false);
	const [query, setQuery] = useState(_query || "");
	useUpdateEffectDebounce(value, 1000, (query) => {
		setQuery(query);
		if (!query) {
			setVisible(false);
		}
	});
	const [{ data }] = useGet(
		query
			? `/auto-fill?type=${type}&q=${query}${_query ? `&${_query}` : ""}`
			: undefined
	);

	useEffect(() => {
		if (data && data.length > 0) {
			let exist = data.find(
				(str: string) => str.toLowerCase() === query.toLowerCase()
			);
			setVisible(!exist);
		} else {
			setVisible(Boolean(data && data.length > 1 && query));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, query]);

	return (
		<Popover
			open={visible}
			// onOpenChange={(v) => {
			// 	if (!focused) {
			// 		setAutoFillVisible(v);
			// 	}
			// }}
		>
			<PopoverTrigger asChild>
				<div className="flex flex-col w-full">{children}</div>
			</PopoverTrigger>
			<PopoverContent align="start" className="w-full p-0 max-w-[220px]">
				<Command className="max-h-[300px] overflow-auto">
					<CommandGroup>
						{(data || []).map((item: string) => (
							<CommandItem
								key={`auto-fill-${item}`}
								value={item}
								onSelect={() => {
									onSelect(item);
									setVisible(false);
								}}
							>
								{item}
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default AutoFillOptions;
