import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import useGet from "src/hooks/rest/useGet";
import { formatPrice } from "src/lib/formatters";

interface PauseSubscriptionMiddlewareProps extends Translation {
	children?: any;
}

const PauseSubscriptionMiddleware = ({
	t,
	children,
}: PauseSubscriptionMiddlewareProps) => {
	const [{ data: payments }, loading] = useGet(
		"/auth/subscriptions/plans/pause/validate"
	);

	if (loading) {
		return (
			<div className="flex flex-col justify-center items-center flex-1">
				<Loader />
			</div>
		);
	}

	if (payments.length && !loading) {
		return (
			<Card>
				<div className="flex flex-col gap-4">
					<div className="flex flex-col">
						<strong>{t("title")}</strong>
						<p className="opacity-70">{t("description")}</p>
					</div>

					<ul>
						{payments.map((payment: any) => (
							<li
								key={`${payment.hid}-${payment.type}`}
								className="p-3 rounded-md border border-border flex flex-col md:flex-row md:justify-between md:items-center gap-3"
							>
								<div className="flex flex-col flex-1">
									<strong>{payment.task.title}</strong>
									<p className="opacity-70">
										{formatPrice(payment.price)}
										{/* {t("invoice-number", {
											invoice_number:
												payment.invoice_number,
										})} */}
									</p>
								</div>
								<Button
									xsmall
									to={`/tasks/${payment.task.hid}/show/${payment.registration.hid}`}
								>
									{t("actions.view")}
								</Button>
							</li>
						))}
					</ul>
				</div>
			</Card>
		);
	}

	return children;
};

PauseSubscriptionMiddleware.locale = {
	nl: {
		title: "Je hebt nog openstaande matches",
		description:
			"Voor je je abonnement kan pauzeren moet je alle opdrachten afronden.",
		"invoice-number": "Factuurnummer: {{invoice_number}}",
		actions: {
			view: "Bekijk opdracht",
		},
	},
	en: {
		title: "You have pending matches",
		description:
			"You can pause your subscription to finish all your tasks.",
		"invoice-number": "Invoice number: {{invoice_number}}",
		actions: {
			view: "View task",
		},
	},
};

export default withTranslation(PauseSubscriptionMiddleware);
