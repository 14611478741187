import React, { useMemo } from "react";
import Checkbox from "src/components/form/Checkbox";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Link from "src/components/Link";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface AgreeTermsCheckboxProps extends Translation {
	value: boolean;
	onChange: () => void;
	className?: string;
}

const AgreeTermsCheckbox = ({
	t,
	value,
	onChange,
	className,
}: AgreeTermsCheckboxProps) => {
	const { tenant } = useTenant();
	const urls = useMemo(() => {
		if (tenant.slug === "odylyt") {
			return {
				terms: `${tenant?.urls?.public}/terms-and-conditions`,
				privacy: `${tenant?.urls?.public}/privacy-policy`,
			};
		}
		return {
			terms: `${tenant?.urls?.public}/algemene-voorwaarden`,
			privacy: `${tenant?.urls?.public}/privacy-beleid`,
		};
	}, [tenant.slug, tenant?.urls?.public]);
	return (
		<div className={`flex gap-2 ${className}`}>
			<Checkbox {...{ value, onChange }} />
			<p className="flex-1">
				{t("first")}
				<Link className="text-primary underline" to={urls.terms}>
					{t("terms")}
				</Link>
				{t("and")}
				<Link className="text-primary underline" to={urls.privacy}>
					{t("privacy")}
				</Link>
			</p>
		</div>
	);
};

AgreeTermsCheckbox.locale = {
	nl: {
		first: "Ik ga akkoord met de ",
		terms: "Algemene voorwaarden",
		and: " en ",
		privacy: "Privacybeleid",
	},
	en: {
		first: "I agree to the website ",
		terms: "Terms & conditions",
		and: " and ",
		privacy: "Privacy policy",
	},
};

export default withTranslation(AgreeTermsCheckbox);
