import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ApiPaymentRequest } from "src/api/types";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestTableItem from "src/components/payment-request/visualizations/PaymentRequestTableItem";
import List from "src/components/ui/list";
import usePaymentRequests, {
	PaymentRequestFilter,
} from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useAuth from "src/hooks/selectors/useAuth";
import useNavigateModal from "src/hooks/useNavigateModal";

interface PaymentRequestWeekListProps extends Translation {
	children?: any;
	filter?: PaymentRequestFilter;
	createUrl: string;
}

const PaymentRequestWeekList = ({
	t,
	filter,
	createUrl,
}: PaymentRequestWeekListProps) => {
	const navigateModal = useNavigateModal();
	const auth = useAuth();
	const { requests, status, actions, pagination } = usePaymentRequests({
		stateId: "PAYMENT_REQUESTS_TABLE",
		filter,
	});

	const currentWeek = moment().isoWeek();
	const location = useLocation();
	const groupBy = (item: ApiPaymentRequest) => {
		const date = moment(item.start_date);
		const labelKey =
			date.isoWeek() === currentWeek
				? "group.current-week"
				: date.isoWeek() === currentWeek - 1
				? "group.previous-week"
				: "group.label";
		return t(labelKey, {
			week: date.isoWeek(),
			start: date.startOf("week").format("DD MMMM"),
			end: date.endOf("week").format("DD MMMM YYYY"),
		});
	};

	useEffect(() => {
		actions.list(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, filter]);

	return (
		<div className="flex flex-col flex-1 relative">
			{requests.length === 0 && status === "idle" && (
				<EmptyState.Overlay className="p-4">
					<EmptyState.Title>{t("empty.title")}</EmptyState.Title>
					<EmptyState.Description>
						{t("empty.description")}
					</EmptyState.Description>
					<EmptyState.Buttons>
						<Button
							type="dark"
							to={{
								modal: createUrl,
							}}
						>
							{t("buttons.create")}
						</Button>
					</EmptyState.Buttons>
				</EmptyState.Overlay>
			)}
			<List
				data={requests}
				{...{ status, groupBy }}
				showEmptyList
				keepListWhileLoading
				className="relative flex-1"
				columnCount={7}
			>
				{auth.type === "company" && (
					<List.Selected variant="floating-card">
						{(selected, clear) => (
							<>
								<small className="hidden md:block">
									{t("selected", {
										count: selected.length,
									})}
								</small>
								<Button
									onClick={() => {
										let params = new URLSearchParams();
										selected.forEach((item: any) => {
											params.append("ids", item);
										});
										clear();
										navigateModal(
											`/payment-requests/deny?${params}`
										);
									}}
									disabled={status !== "idle"}
									xsmall
								>
									<i className="far fa-times"></i>
									{t("buttons.deny")}
								</Button>
								<Button
									xsmall
									onClick={() =>
										actions
											.approve(selected)
											.then(async () => {
												await actions.list(filter);
												clear();
											})
									}
								>
									<i className="far fa-check"></i>
									{t("buttons.accept")}
								</Button>
							</>
						)}
					</List.Selected>
				)}

				<List.Items>
					{(row) => (
						<PaymentRequestTableItem
							key={`row-${row.id}`}
							request={row}
							onUpdate={(value) =>
								actions.update(
									{ id: row.id, ...value },
									{ updateList: true }
								)
							}
						/>
					)}
				</List.Items>
			</List>
			{pagination?.last_page > pagination?.page && (
				<div className="flex justify-center p-4">
					<Button
						small
						type="border"
						onClick={() => pagination.loadMore(pagination.page + 1)}
					>
						{t("buttons.load-more")}
					</Button>
				</div>
			)}
		</div>
	);
};

PaymentRequestWeekList.locale = {
	nl: {
		group: {
			label: `Week {{week}} - {{start}} / {{end}}`,
			"current-week": "Huidige week",
			"previous-week": "Vorige week",
		},
		selected: "{{count}} geselecteerd",
		headers: {
			status: "Status",
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			description: "Omschrijving",
			date: "Datum",
			price: "Waarde",
		},
		buttons: {
			edit: "Bewerk",
			delete: "Annuleren",
			create: "Betaalverzoek maken",
			accept: "Accepteren",
			deny: "Afwijzen",
			"load-more": "Meer laden",
		},
		empty: {
			title: "Geen verzoeken gevonden",
			description: "Er zijn geen verzoeken gevonden, maak er één aan.",
		},
	},
	en: {
		group: {
			label: `Week {{week}} - {{start}} / {{end}}`,
			"current-week": "Current week",
			"previous-week": "Previous week",
		},
		selected: "{{count}} selected",
		headers: {
			status: "Status",
			company: "Client",
			student: "Contractor",
			description: "Description",
			date: "Date",
			price: "Value",
		},
		buttons: {
			edit: "Edit",
			delete: "Cancel",
			create: "Create Payment Request",
			accept: "Approve",
			deny: "Reject",
			"load-more": "Load More",
		},
		empty: {
			title: "No requests found",
			description: "No requests found, create one.",
		},
	},
};

export default withTranslation(PaymentRequestWeekList);
