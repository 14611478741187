import { Navigate, useParams } from "react-router-dom";
import { ApiSubscriptionPlanInterval } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Link from "src/components/Link";
import SubscriptionCheckoutHead from "src/components/subscription/SubscriptionCheckoutHead";
import SubscriptionPlanSelected from "src/components/subscription/SubscriptionPlanSelected";
import usePlans from "src/hooks/api/usePlans";
import useQueryString from "src/hooks/useQueryString";

const SelectedSubscriptionView = ({ t }: Translation) => {
	const params = useParams();
	const qs = useQueryString();
	const interval = (
		qs.interval?.toString() || "monthly"
	).toUpperCase() as ApiSubscriptionPlanInterval;
	const { plans } = usePlans();
	const plan = plans.find(
		(item) => item.slug === params.slug && item.interval === interval
	);

	if (!plan) {
		return <Navigate to="/subscription" />;
	}

	return (
		<Layout hideNav>
			<div className="flex flex-col flex-1">
				<SubscriptionCheckoutHead
					title={t("head.title")}
					subtitle={t("head.subtitle")}
				/>
				{plan && (
					<SubscriptionPlanSelected
						{...{ plan, interval }}
						className="md:rounded-md max-w-md mx-auto w-full md:mt-8 mt-4"
						showIntervalSwitch={false}
						showAnimations={false}
					/>
				)}
				<div className="flex justify-center items-center mt-8">
					<Link className="opacity-50" to="/subscription">
						{t("back")}
					</Link>
				</div>
			</div>
		</Layout>
	);
};

SelectedSubscriptionView.locale = {
	nl: {
		back: "Terug naar Abonnementen",
		head: {
			title: "Abonnement kiezen",
			subtitle: "Kies het abonnement dat bij jouw organisatie past.",
		},
	},
	en: {
		back: "Back to subscriptions",
		head: {
			title: "Choose subscription",
			subtitle: "Choose the subscription that fits your organization.",
		},
	},
};

export default withTranslation(SelectedSubscriptionView);
