import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTask from "src/hooks/api/tasks/useTask";
import usePost from "src/hooks/rest/usePost";

interface TaskAddRegistrationModalProps extends Translation {}

const TaskAddRegistrationModal = ({ t }: TaskAddRegistrationModalProps) => {
	const params = useParams();
	const navigate = useNavigate();
	const { task } = useTask();
	const location = useLocation();
	const [values, setValues] = useState<any>({
		user_id: undefined,
	});
	const [addRegistration, loading] = usePost(
		`/client/tasks/${task.id}/registrations`
	);
	const [response, setResponse] = useState<null | {
		status: "success" | "error";
		count?: number;
	}>();
	const isDisabled = !values.user_id;

	const onSubmit = async () => {
		if (isDisabled) return;

		try {
			await addRegistration(values);
			navigate(`/tasks/${params.id}/${params.pane}`);
		} catch (error) {
			setResponse({
				status: "error",
			});
		}
	};

	return (
		<Modal
			title={t("title")}
			visible={location.pathname.includes("/add-registration")}
			onClose={() => {
				navigate(`/tasks/${params.id}/${params.pane}`);
			}}
			className=" "
		>
			<div className="flex flex-col gap-6">
				{response?.status === "error" && (
					<div className="p-4 bg-red-500 rounded-md text-red border border-red mb-4">
						{t("error")}
					</div>
				)}

				<Field.Connection
					wrapper={{
						label: t("user"),
						className: "w-full max-w-full",
					}}
					className="border-border w-full max-w-full"
					onChange={(user_id) => setValues({ ...values, user_id })}
					value={values.user_id}
				/>

				<div className="flex flex-col gap-6">
					<Button
						loading={loading}
						disabled={loading || isDisabled}
						onClick={onSubmit}
					>
						{t("buttons.add")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

TaskAddRegistrationModal.locale = {
	nl: {
		title: "Aanmelding toevoegen",
		user: "Opdrachtnemer",
		error: "Er is iets misgegaan, probeer het later opnieuw.",
		buttons: {
			add: "Toevoegen",
		},
	},
	en: {
		title: "Add registration",
		user: "Client",
		error: "Something went wrong, try again later.",
		buttons: {
			add: "Add",
		},
	},
};

export default withTranslation(TaskAddRegistrationModal);
