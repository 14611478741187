import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import NetworkView from "src/views/authenticated/network/NetworkView";
import NetworkCustomFormModal from "src/views/authenticated/network/forms/NetworkCustomFormModal";
import NetworkInvitesView from "src/views/authenticated/network/invites/NetworkInvitesView";
import NetworkInviteView from "src/views/authenticated/network/invites/invite/NetworkInviteView";

const NetworkRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<NetworkView />
			</ProtectedRoute>
		}
		path="/network"
		key="/network"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<NetworkInvitesView />
			</ProtectedRoute>
		}
		path="/network/invites"
		key="/network/invites"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<NetworkInviteView />
			</ProtectedRoute>
		}
		path="/network/invites/:id/invite"
		key="/network/invites/:id/invite"
	/>,
];

export const NetworkModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/network",
					expanded: false,
					size: "default",
				}}
			>
				<NetworkCustomFormModal />
			</ProtectedRoute>
		}
		path="/network/:connectionId/forms/:formId"
		key="/network/:connectionId/forms/:formId"
	/>,
];

export default NetworkRoutes;
