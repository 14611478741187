import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ApiIssuer, ApiPaymentMethods } from "src/api/types";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import IdealPaymentMethod from "src/components/checkout/IdealPaymentMethod";
import MandatePaymentMethod from "src/components/checkout/MandatePaymentMethod";
import Dropdown from "src/components/form/Dropdown";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useIssuer from "src/hooks/selectors/useIssuer";
import { BANK_ICONS } from "src/lib/constants";

interface MandateMethodsProps {
	loading?: ApiPaymentMethods;
	value?: {
		type: ApiPaymentMethods;
		issuer: string;
	};
	onClick: (type: ApiPaymentMethods, issuer?: string) => any;
	idealOnly?: boolean;
	variant?: "direct" | "button";
	layout?: "list" | "dropdown";
	title?: string;
	placeholder?: string;
}

const MandateMethods = ({
	onClick,
	loading,
	value,
	idealOnly,
	variant = "direct",
	layout = "list",
	title,
	placeholder,
}: MandateMethodsProps) => {
	const { t } = useTranslation("payments", {
		keyPrefix: "mandate-methods",
	});
	const [type, setType] = useState(value?.type || "");
	const [selectedBic, setSelectedBic] = useState<string | undefined>();
	const { mandate, loadingMandate, fetchIssuers } = useIssuer();
	const auth = useAuth();
	const { tenant } = useTenant();
	const selected = (mandate || []).find(
		(item: ApiIssuer) =>
			item.bic === selectedBic || item.bic === value?.issuer
	);

	useEffect(() => {
		if (!loadingMandate && (mandate?.length === 0 || !mandate)) {
			fetchIssuers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (layout === "dropdown") {
		return (
			<div className="flex flex-col gap-2">
				<Dropdown
					hasAvatar
					label={title || t("title")}
					placeholder={placeholder || t("placeholder")}
					value={value?.issuer}
					onChange={(value) => {
						if (variant === "direct") {
							onClick(
								"ideal",
								value ? value.toString() : undefined
							);
						}
						if (variant === "button") {
							setSelectedBic(
								value ? value.toString() : undefined
							);
						}
					}}
					options={(mandate || []).map((item: ApiIssuer) => ({
						id: item.bic,
						name: item.name,
						avatar: item.icon
							? BANK_ICONS[item.icon] || BANK_ICONS["unknown"]
							: undefined,
					}))}
					avatarClassName="w-12 h-8 rounded-md"
				/>

				<div className="flex flex-col mt-2 empty:hidden text-sm">
					{!selected && auth?.id && (
						<p>
							<Trans
								{...{ t }}
								i18nKey="ideal.method-not-existing"
							>
								Staat jouw gewenste betaalmethode er niet bij?
								<a
									className="underline"
									href={`mailto: support@${tenant?.urls?.domain}?subject=Bankkoppeling op maat: ${auth?.company?.name}&reply-to=${auth.email}`}
								>
									Klik dan hier
								</a>
								om een handmatige bankkoppeling aan te vragen.
							</Trans>
						</p>
					)}
					{selected?.is_custom && (
						<p>
							<Trans {...{ t }} i18nKey="ideal.method-custom">
								De koppeling met
								<strong>{{ name: selected.name }}</strong> is
								een handmatige koppeling. Je hoeft enkel je iban
								en tenaamstelling in te voeren, onze
								betalingsprovider regelt de rest.
							</Trans>
						</p>
					)}
				</div>

				{selected?.name && variant !== "direct" && (
					<div className="flex mt-4">
						<Button
							onClick={() => {
								onClick("ideal", selectedBic);
							}}
							loading={loading ? true : false}
						>
							{t("ideal.continue", {
								name: selected.name,
							})}
						</Button>
					</div>
				)}
			</div>
		);
	}

	return (
		<>
			<ul className="flex flex-col gap-2">
				<span className="text-[16px] opacity-70">
					{title || t("title")}
				</span>
				{!idealOnly && (
					<MandatePaymentMethod
						onClick={() => {
							setType(type !== "ideal" ? "ideal" : "");
						}}
						label={t("ideal.title")}
						icon="fab fa-ideal"
						loading={loading === "ideal"}
						open={type === "ideal"}
					/>
				)}

				{(type === "ideal" || idealOnly) && (
					<>
						{!idealOnly && (
							<p className="mt-2">{t("ideal.title")}:</p>
						)}
						{loadingMandate && (
							<div className="flex flex-col justify-center items-center p-8">
								<Loader />
							</div>
						)}
						{(mandate || []).map((item: ApiIssuer) => (
							<IdealPaymentMethod
								key={item.bic}
								variant="light"
								type="circle"
								active={
									value?.issuer === item.bic ||
									selectedBic === item.bic
								}
								onClick={() => {
									if (variant === "direct") {
										onClick("ideal", item.bic);
									}
									if (variant === "button") {
										setSelectedBic(item.bic);
									}
								}}
								{...item}
							/>
						))}

						<div className="flex flex-col mt-4">
							{!selected && auth?.id && (
								<p>
									<Trans
										{...{ t }}
										i18nKey="ideal.method-not-existing"
									>
										Staat jouw gewenste betaalmethode er
										niet bij?
										<a
											className="underline"
											href={`mailto: support@${tenant?.urls?.domain}?subject=Bankkoppeling op maat: ${auth?.company?.name}&reply-to=${auth.email}`}
										>
											Klik dan hier
										</a>
										om een handmatige bankkoppeling aan te
										vragen.
									</Trans>
								</p>
							)}
							{selected?.is_custom && (
								<p>
									<Trans
										{...{ t }}
										i18nKey="ideal.method-custom"
									>
										De koppeling met
										<strong>
											{{ name: selected.name }}
										</strong>{" "}
										is een handmatige koppeling. Je hoeft
										enkel je iban en tenaamstelling in te
										voeren, onze betalingsprovider regelt de
										rest.
									</Trans>
								</p>
							)}
						</div>

						{selected?.name && variant !== "direct" && (
							<div className="flex mt-4">
								<Button
									onClick={() => {
										onClick("ideal", selectedBic);
									}}
									loading={loading ? true : false}
								>
									{t("ideal.continue", {
										name: selected.name,
									})}
								</Button>
							</div>
						)}
					</>
				)}
			</ul>
		</>
	);
};

export default MandateMethods;
