import { motion } from "framer-motion";
import React, {
	ComponentProps,
	createContext,
	useContext,
	useState,
} from "react";
import { cn } from "src/lib/utils";

type AccordionContextType = {
	open: boolean;
	onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccordionContext = createContext<AccordionContextType>(
	{} as AccordionContextType
);

function useAccordion() {
	const props = useContext(AccordionContext);
	return props;
}

interface AccordionProps extends ComponentProps<"div"> {
	defaultOpen?: boolean;
}

const Accordion = ({ defaultOpen, className, ...rest }: AccordionProps) => {
	const [open, setOpen] = useState(defaultOpen || false);
	return (
		<AccordionContext.Provider
			value={{
				open,
				onOpenChange: setOpen,
			}}
		>
			<div
				{...rest}
				className={cn(
					"bg-card border border-border rounded-md overflow-hidden",
					className
				)}
			/>
		</AccordionContext.Provider>
	);
};

const Head = ({ children, className, ...rest }: ComponentProps<"div">) => {
	const { onOpenChange } = useAccordion();
	return (
		<div
			{...rest}
			className={cn(
				"px-4 py-3 cursor-pointer transition-all flex items-center justify-between",
				className
			)}
			onClick={() => onOpenChange((state) => !state)}
		>
			{children}
		</div>
	);
};

const Icon = ({ className, ...rest }: ComponentProps<"i">) => {
	const { open } = useAccordion();
	return (
		<motion.div animate={{ rotate: open ? 180 : 0 }}>
			<i
				{...rest}
				className={cn("far leading-full fa-angle-down", className)}
			></i>
		</motion.div>
	);
};

const Content = ({ className, ...rest }: ComponentProps<"div">) => {
	const { open } = useAccordion();
	return (
		<motion.div
			initial="closed"
			animate={open ? "open" : "closed"}
			variants={{
				closed: {
					height: 0,
					y: 32,
				},
				open: {
					height: "auto",
					y: 0,
				},
			}}
		>
			<div
				{...rest}
				className={cn("flex flex-col p-3 pt-0", className)}
			/>
		</motion.div>
	);
};

Accordion.Head = Head;
Accordion.Icon = Icon;
Accordion.Content = Content;

export default Accordion;
