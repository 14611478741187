import { useLocation } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Link from "src/components/Link";
import Skeleton from "src/components/Skeleton";
import useVariant, { VariantTypes } from "src/hooks/useVariant";
import { cn } from "src/lib/utils";

interface ListItemProps {
	children?: any;
	to?: string;
	className?: string;
	align: "start" | "center" | "end";
	bordered?: boolean;
	activeClassName?: string;
}

const ListItem = ({
	children,
	to,
	className,
	align,
	bordered,
	activeClassName,
}: ListItemProps) => {
	const location = useLocation();

	if (to) {
		const path = to?.split("#")[0];
		const isActive = location.pathname === path;
		return (
			<Link
				{...{ to }}
				className={cn(
					`flex items-${align} group gap-3 relative transition-all hover:opacity-90 p-1`,
					bordered &&
						"border border-b-accent border-l-transparent border-t-transparent border-r-transparent rounded-md p-1.5",
					isActive && "border-primary border rounded-md",
					className,
					isActive && activeClassName
				)}
			>
				{children}
			</Link>
		);
	}

	return (
		<div
			className={cn(
				`flex items-${align} group gap-3 relative transition-all`,
				bordered && "border-b border-accent pb-4",
				className
			)}
		>
			{children}
		</div>
	);
};

ListItem.defaultProps = {
	align: "center",
};

interface IconProps {
	name: string;
	variant: VariantTypes;
}

const Icon = ({ name, variant }: IconProps) => {
	const _variant = useVariant(variant);

	return (
		<div
			className={`w-12 h-12 rounded-md flex justify-center items-center ${_variant}`}
		>
			<i className={`${name}`}></i>
		</div>
	);
};

interface ContentProps {
	children?: any;
	className?: string;
}

const Content = ({ children, className }: ContentProps) => (
	<div className={cn("flex-1 flex flex-col", className)}>{children}</div>
);

// const ListItemAvatar = (props: AvatarProps) => {
// 	return <Avatar {...props} />;
// };

interface TitleProps {
	children?: any;
	className?: string;
	loading?: boolean;
}

const Title = ({ children, className, loading }: TitleProps) => {
	if (loading) {
		return <Skeleton className={`h-4 w-1/4 mb-1`}></Skeleton>;
	}
	return <p className={`text-sm ${className}`}>{children}</p>;
};

interface TextProps {
	children?: any;
	className?: string;
	variant: "paragraph" | "small";
	loading?: boolean;
}

const Text = ({
	children,
	className: _className,
	variant,
	loading,
}: TextProps) => {
	const className = cn("opacity-70", _className);
	if (loading) {
		return (
			<Skeleton
				className={`${
					!className?.includes("w-") && "w-2/4"
				} ${className} ${variant === "small" ? "h-3" : "h-4"}`}
			/>
		);
	}
	if (variant === "small") {
		return <small {...{ className }}>{children}</small>;
	}
	return <p {...{ className }}>{children}</p>;
};

Text.defaultProps = {
	variant: "paragraph",
};

ListItem.Icon = Icon;
ListItem.Title = Title;
ListItem.Text = Text;
ListItem.Avatar = Avatar;
ListItem.Content = Content;

export default ListItem;
