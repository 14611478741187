import { Navigate, useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";

const SignupCompanySubscriptionCheckoutThanksView = ({ t }: Translation) => {
	const params = useParams();

	if (!params.session_id) return <Navigate to="/signup/company" />;

	return (
		<AuthLayout variant="flat">
			<AuthLayoutContainer></AuthLayoutContainer>
		</AuthLayout>
	);
};

SignupCompanySubscriptionCheckoutThanksView.locale = {
	nl: {},
	en: {},
};

export default withTranslation(SignupCompanySubscriptionCheckoutThanksView);
