import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/Button";
import {
	Dialog,
	DialogContent,
	DialogOverlay,
	DialogPortal,
} from "src/components/ui/dialog";
import { cn } from "src/lib/utils";
import { clearAlert } from "src/redux/alert";

export type AlertProps = {
	type?: string;
	title: string;
	text?: any;
	stayOpen?: boolean;
	button?: string;
	onConfirm?: () => void;
};

const AlertContainer = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state: any) => state.alert);

	useEffect(() => {
		if (alert.title !== "" && !alert.stayOpen && alert.type !== "confirm") {
			setTimeout(() => {
				dispatch(clearAlert());
			}, 2000);
		}
	}, [alert, dispatch]);

	return (
		<Dialog
			open={!!alert.title}
			onOpenChange={() => dispatch(clearAlert())}
		>
			<DialogPortal>
				<DialogOverlay className="z-[999]" />
				<DialogContent variant="default" className="max-w-[460px]">
					<Alert {...{ ...alert }} />
				</DialogContent>
			</DialogPortal>
		</Dialog>
	);
};

const Alert = ({ title, type, text, button, onConfirm }: AlertProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const close = () => dispatch(clearAlert());

	return (
		<div
			className={cn(
				"flex flex-col gap-6 rounded-lg bg-background mx-2 md:mx-0 max-w-[460px]"
			)}
		>
			<div className="flex flex-col gap-1">
				<p className="font-bold">{title}</p>
				{text && <p className="opacity-70">{text}</p>}
			</div>
			{type === "confirm" ? (
				<div className="flex-col flex gap-3">
					<Button
						className="flex-1 md:flex-auto"
						onClick={() => {
							close();
							if (onConfirm) {
								onConfirm();
							}
						}}
					>
						{button || t("confirm", { ns: "buttons" })}
					</Button>
					<Button
						className="flex-1 md:flex-auto"
						type="ghost"
						onClick={close}
					>
						{t("cancel", { ns: "buttons" })}
					</Button>
				</div>
			) : (
				button && <Button onClick={close}>{button}</Button>
			)}
		</div>
	);
};

export default AlertContainer;
