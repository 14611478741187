import { useState } from "react";
import Button from "src/components/Button";
import Choose from "src/components/forms/auth/signup/Choose";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import Link from "src/components/Link";
import useBackUrl from "src/hooks/useBackUrl";

function SignupChooseView({ t }: Translation) {
	const backUrl = useBackUrl();
	const [type, setType] = useState<"professional" | "company">();

	return (
		<AuthLayout variant="flat">
			<AuthLayoutContainer className="items-center">
				<h1 className="max-w-md text-3xl text-center text-balance">
					{t("title")}
				</h1>
				<div className="flex flex-col gap-6 mt-16 max-w-[700px]">
					<Choose className="flex flex-col md:flex-row gap-6">
						<Choose.Item
							size="large"
							title={t("choose.professional.title")}
							icon="fas fa-user"
							onClick={() => setType("professional")}
							active={type === "professional"}
						/>
						<Choose.Item
							size="large"
							title={t("choose.company.title")}
							icon="fas fa-building"
							onClick={() => setType("company")}
							active={type === "company"}
						/>
					</Choose>
					<div className="flex flex-col gap-6 justify-center items-center mt-8">
						<Button
							disabled={!type}
							to={`/signup/${type}?${backUrl}`}
						>
							{t(type ? `choose.${type}.cta` : "choose.cta")}
						</Button>
						<p>
							<span>{t("login.description")}</span>
							<Link
								to={`/login?${backUrl}`}
								className="text-primary hover:underline"
							>
								{t("login.cta")}
							</Link>
						</p>
					</div>
				</div>
			</AuthLayoutContainer>
		</AuthLayout>
	);
}

SignupChooseView.locale = {
	nl: {
		title: "Meld je aan als opdrachtnemer of opdrachtgever",
		choose: {
			cta: "Account aanmaken",
			professional: {
				title: "Ik ben een opdrachtnemer die opdrachten wilt uitvoeren",
				cta: "Aanmelden als opdrachtnemer",
			},
			company: {
				title: "Ik ben een opdrachtgever die opdrachten wilt plaatsen",
				cta: "Aanmelden als opdrachtgever",
			},
		},
		login: {
			description: "Heb je al een account? ",
			cta: "Log in",
		},
	},
};
export default withTranslation(SignupChooseView);
