import axios, { AxiosInstance } from "axios";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useEffect } from "react";
import useAtomIds from "src/hooks/api/utils/useAtomIds";
import { createApiInstance } from "src/lib/api";
import {
	ApiActions,
	ApiFilter,
	ApiOptions,
	ApiState,
	ApiStatus,
	DownloadOptions,
	ListOptions,
	Pagination,
	QueryProps,
	UniqueKey,
	UpdateOptions,
} from "./useApi.types";

export const apiAtomFamily = atomFamily(
	(data: any) =>
		atom({
			...emptyApiState,
			...data,
			status: data?.defaultStatus || "idle",
		}),
	(a: any, b: any) => a.id === b.id
);

export const emptyApiState = {
	id: undefined,
	group: undefined,
	data: {},
	list: [],
	custom: {},
	status: "idle",
	filter: {},
	pagination: {
		page: 0,
		last_page: 0,
	},
};

function useApi(
	key: UniqueKey,
	options: ApiOptions
): {
	state: ApiState;
	api: AxiosInstance;
	actions: ApiActions;
	pagination: Pagination;
} {
	const atomIds = useAtomIds();

	const [state, setState] = useAtom<ApiState, any, any>(
		apiAtomFamily({
			id: key.id,
			group: key?.group,
			isAllowed: options?.isAllowed,
			defaultStatus: options?.defaultStatus,
		})
	);

	useEffect(() => {
		atomIds.add(key);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getQueryParams = (query?: QueryProps): any => {
		return {
			...(options?.with ? { with: options.with } : {}),
			...(options?.query || {}),
			...(state?.filter || {}),
			...(query || {}),
		};
	};

	const handleError = () => {
		setState((prev: any) => ({
			...(prev || {}),
			status: "idle",
		}));
	};

	const api = createApiInstance({
		baseURL: `${axios.defaults.baseURL}${options.baseUrl}`,
	});

	const setList = (data: any, listOptions: { appendResult?: boolean }) => {
		setState((prev: any) => {
			let list = data?.data || [];

			//Append the data
			if (listOptions?.appendResult) {
				const previousList = (prev?.list || []).map((item: any) => {
					const updated = list.find((i: any) => i?.id === item?.id);
					return updated ? updated : item;
				});

				list = [
					...previousList,
					...list.filter(
						(item: any) =>
							!previousList.find((i: any) => i.id === item.id)
					),
				];
			}
			return {
				...(prev || {}),
				status: "idle",
				list,
				filter: data?.params || prev?.filter,
				pagination: {
					page: data?.meta?.current_page,
					last_page: data?.meta?.last_page,
					total: data?.meta?.total,
				},
			};
		});
	};

	const setItem = (data: { data?: any }, options?: UpdateOptions) => {
		setState((prev: any) => {
			if (options?.updateList) {
				return {
					...(prev || {}),
					status: "idle",
					data: options?.disableUpdateItem
						? prev?.data
						: data?.data || {},
					list: prev.list.map((item: any) => {
						if (
							(data?.data?.id && item?.id === data?.data?.id) ||
							(data?.data?.hid && item.hid === data?.data?.hid)
						) {
							return data.data;
						}
						return item;
					}),
				};
			}
			return {
				...(prev || {}),
				status: "idle",
				data: options?.disableUpdateItem
					? prev?.data
					: data?.data || {},
			};
		});
	};

	//List a collection of resources
	const list = async (query?: QueryProps, listOptions?: ListOptions) => {
		if (state.status === "loading") {
			return state.list;
		}

		const params = {
			...(!listOptions?.disableMergeFilter
				? getQueryParams(query)
				: query),
		};

		try {
			if (!listOptions?.disableLoading) {
				setState((prev: any) => ({
					...(prev || {}),
					status: "loading",
					filter: params,
				}));
			}
			const { data } = await axios.get(`${options.baseUrl}`, {
				params,
			});
			data.params = params;

			if (data?.ghosts && data?.data) {
				data.data = [...data?.ghosts, ...data.data];
			}

			setList(data, {
				appendResult:
					listOptions?.appendResult === undefined
						? options?.appendListResult
						: listOptions.appendResult,
			});

			return data?.data || [];
		} catch (error) {
			handleError();
			return [];
		}
	};

	//List a single collection
	const get = async (id?: string | number, query?: QueryProps) => {
		try {
			setState((prev: any) => ({
				...(prev || {}),
				status: "loading",
			}));
			const { data } = await axios.get(
				id ? `${options.baseUrl}/${id}` : `${options.baseUrl}`,
				{
					params: getQueryParams(query),
				}
			);
			setItem(data);
			return data?.data || {};
		} catch (error) {
			handleError();
			throw error;
		}
	};

	//Update the selected resource
	const update = async (params: any, updateOptions?: UpdateOptions) => {
		const id = (params || {})?.id || state?.data?.id;
		if (!id) {
			return;
		}
		if (params?.id) {
			delete params.id;
		}
		setState((prev: any) => ({
			...(prev || {}),
			status: "updating",
		}));
		try {
			const { data } = await axios.patch(
				`${options.baseUrl}/${id}`,
				params,
				{
					params: getQueryParams(updateOptions?.query),
				}
			);
			setItem(data, {
				updateList: updateOptions?.updateList,
				disableUpdateItem: updateOptions?.disableUpdateItem,
			});
			return data?.data || {};
		} catch (error) {
			handleError();
			throw error;
		}
	};

	//Create and set the tenant
	const create = async (params: any) => {
		setState((prev: any) => ({
			...(prev || {}),
			status: "creating",
		}));
		try {
			const { data } = await axios.post(`${options.baseUrl}`, params, {
				params: getQueryParams(),
			});
			setState((prev: any) => ({
				...(prev || {}),
				status: "idle",
				// data: data?.data || {},
			}));
			return data?.data || {};
		} catch (error: any) {
			handleError();
			setState((prev: any) => ({
				...(prev || {}),
				status: "idle",
			}));
			throw error?.response?.data;
		}
	};

	const download = async (
		path: string,
		data: any,
		options?: DownloadOptions
	) => {
		setState((prev: any) => ({
			...(prev || {}),
			status: "loading",
		}));
		try {
			const result = await api.post(path.replace(/\/$/, ""), data, {
				responseType: "blob",
				headers: {
					responseType: "blob",
				},
			});
			const csvURL = window.URL.createObjectURL(result.data);
			const tempLink = document.createElement("a");
			tempLink.href = csvURL;
			tempLink.setAttribute(
				"download",
				`${options?.fileName || "export.xlsx"}`
			);
			tempLink.click();
			setState((prev: any) => ({
				...(prev || {}),
				status: "idle",
				// data: data?.data || {},
			}));
			return data?.data || {};
		} catch (error: any) {
			console.log(error);
			handleError();
			setState((prev: any) => ({
				...(prev || {}),
				status: "idle",
			}));
			throw error?.response?.data;
		}
	};

	//Create and set the tenant
	const upsert = async (params: any) => {
		setState((prev: any) => ({
			...(prev || {}),
			status: "creating",
		}));
		try {
			const { data } = await axios.post(`${options.baseUrl}`, params, {
				params: getQueryParams(),
			});
			setItem(data?.data);
			return data?.data || {};
		} catch (error) {
			handleError();
			return {};
		}
	};

	const remove = async (id: string | number) => {
		try {
			setState((prev: any) => ({
				...(prev || {}),
				status: "deleting",
			}));
			const { data } = await axios.delete(`${options.baseUrl}/${id}`, {
				params: getQueryParams(),
			});
			const hasMorePages =
				state.pagination.page !== state.pagination.last_page &&
				state.pagination.last_page !== 1;

			if (list.length && hasMorePages) {
				await list({
					...state.filter,
					page: state.pagination?.page || 1,
				});
			}

			setState((prev: any) => ({
				...(prev || {}),
				status: "idle",
				data: data?.data || {},
				list: !hasMorePages
					? (prev?.list || []).filter(
							(item: any) => item.id !== id && item.hid !== id
					  )
					: prev?.list || [],
			}));
		} catch (error) {
			handleError();
		}
	};

	const resetFilter = (data?: any) => {
		setState((prev: any) => ({
			...prev,
			filter: {
				...(data || {}),
			},
		}));
	};

	const resetState = (data?: any) => {
		setState({
			...emptyApiState,
			...(data || {}),
		});
	};

	return {
		state,
		api,
		pagination: {
			page: state?.pagination?.page,
			last_page: state?.pagination?.last_page,
			total: state?.pagination?.total,
			setPage: (page) => list({ page }),
			loadMore: (page) =>
				list(
					{ page },
					{
						appendResult: true,
					}
				),
		},
		actions: {
			set: setState,
			get,
			list,
			update,
			create,
			upsert,
			delete: remove,
			resetFilter,
			resetState,
			setList,
			setItem,
			download,
			setFilter: (filter) =>
				setState((prev: any) => ({ ...prev, filter })),
		},
	};
}

export type {
	ApiActions,
	ApiFilter,
	ApiOptions,
	ApiState,
	ApiStatus,
	DownloadOptions,
	ListOptions,
	Pagination,
	QueryProps,
	UniqueKey,
	UpdateOptions,
};

export default useApi;
