import { useSelector } from "react-redux";
import {
	ApiOfferRegistration,
	ApiRegistration,
	ApiTask,
	ApiOffer,
} from "src/api/types";
import Card from "src/components/Card";
import ChatHead from "src/components/chat/ChatHead";
import CreateMessageBox from "src/components/chat/CreateMessageBox";
import MessagesBox from "src/components/chat/MessagesBox";

interface ChatProps {
	options: {
		type: "task" | "offer";
		room: string;
		backUrl?: string;
	};
	data: {
		task?: ApiTask;
		registration?: ApiRegistration | ApiOfferRegistration;
		offer?: ApiOffer;
	};
	loading?: boolean;
	className?: string;
	headActions?: any;
}

const Chat = ({ options, data, className, headActions }: ChatProps) => {
	const type = useSelector((state: any) => state.auth.type);
	const { task, offer } = data;
	const { registration }: any = data;
	const is_denied =
		registration?.is_denied || registration?.status === "DENIED";

	const getHeadProps = () => {
		if (options.type === "task") {
			return {
				avatar:
					type === "company"
						? registration?.user?.avatar
						: task?.company?.avatar,
				name:
					type === "company"
						? registration?.user?.full_name
						: task?.creator?.first_name,
				subtitle: type === "student" ? task?.company?.name : "",
				profileId:
					type === "company" ? registration?.user?.hid : undefined,
				backUrl: options.backUrl,
				taskUrl: `/tasks/${task?.hid}/show/${registration?.hid}`,
			};
		}
		if (options.type === "offer") {
			return {
				avatar:
					type === "company"
						? offer?.user?.avatar
						: registration?.company?.avatar,
				name:
					type === "company"
						? offer?.user?.full_name
						: registration.company.name,
				subtitle: type === "student" ? registration.user.full_name : "",
				backUrl: options.backUrl,
			};
		}
		return {
			avatar: undefined,
			name: "",
			profileId: undefined,
			backUrl: options.backUrl,
		};
	};

	return (
		<Card
			className={`${className} flex flex-col max-w-full flex-1`}
			contentClassName="p-0 flex flex-col flex-1"
		>
			<ChatHead {...{ headActions }} {...getHeadProps()} />
			<MessagesBox.Wrapper>
				{registration?.id && (
					<MessagesBox
						id={registration?.id}
						type={options.type}
						data={{ registration, task }}
					/>
				)}
				<CreateMessageBox
					{...{ is_denied }}
					id={registration?.id as any}
					type={options.type}
				/>
			</MessagesBox.Wrapper>
		</Card>
	);
};

const Loading = ({ className }: { className?: string }) => {
	return (
		<Card
			className={`${className} flex flex-col max-w-full bg-background flex-1`}
			contentClassName="p-0 flex flex-col flex-1"
		>
			<ChatHead.Loading />
			<MessagesBox.Wrapper>
				<MessagesBox.Loading />
			</MessagesBox.Wrapper>
			<CreateMessageBox.Loading />
		</Card>
	);
};

Chat.Loading = Loading;

export default Chat;
