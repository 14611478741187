import { Link } from "react-router-dom";
import Logo from "src/components/Logo";
import NavbarToggle from "src/components/layout/NavbarToggle";
import useLayout from "src/hooks/useLayout";
import { cn } from "src/lib/utils";

const SidebarHead = () => {
	const { layout } = useLayout();

	return (
		<div
			className={cn(
				"flex justify-center lg:justify-between items-center p-4 relative z-[94]",
				layout.navbar.variant !== "default" &&
					"p-4 justify-center items-center"
			)}
		>
			<Link className="flex dark:hidden" to="/dashboard">
				<Logo
					className={cn(
						"block h-8",
						layout.navbar.variant !== "default" && "w-8 h-8"
					)}
					color="dark"
					variant="small"
				/>
			</Link>
			<Link className="hidden dark:flex" to="/dashboard">
				<Logo
					className={cn(
						"block h-8",
						layout.navbar.variant !== "default" && "w-8 h-8"
					)}
					color="primary"
					variant="small"
				/>
			</Link>

			<NavbarToggle
				className={cn(
					layout.navbar.variant === "default"
						? "relative"
						: "absolute z-[9949] -right-3"
				)}
			/>
		</div>
	);
};

export default SidebarHead;
