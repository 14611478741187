import moment from "moment";
import { useTranslation } from "react-i18next";
import ApiCall from "src/components/ApiCall";
import Layout from "src/components/layout/Layout";
import Tabs from "src/components/layout/Tabs";
import PaymentList from "src/components/lists/PaymentList";
import IconTotalCard from "src/components/statistics/IconTotalCard";
import useAuth from "src/hooks/selectors/useAuth";
import { formatPrice } from "src/lib/formatters";
import DashboardCompanyPayments from "src/views/authenticated/payments/company/CompanyPaymentsView";

export default function PaymentsView() {
	const { t } = useTranslation("payment-view", { keyPrefix: "index-view" });
	const auth = useAuth();

	if (auth.type === "company") {
		return <DashboardCompanyPayments />;
	}

	return (
		<Layout title={t("title")} container="xxl">
			<div className="flex flex-col gap-2">
				<strong>{t("title-refresh", { year: moment().year() })}</strong>
				<ApiCall query={`/student/payments/statistics`}>
					{(data, loading) => {
						if (loading) {
							return (
								<div className="flex flex-wrap items-center gap-4">
									<IconTotalCard.Loading />
									<IconTotalCard.Loading />
									<IconTotalCard.Loading />
								</div>
							);
						}
						return (
							<div className="flex flex-wrap items-center gap-4">
								<IconTotalCard
									label={t("status.reserved")}
									icon="far fa-lock"
								>
									{formatPrice(data?.reserved)}
								</IconTotalCard>
								{/* <IconTotalCard
									label={t("status.coming")}
									icon="far fa-paper-plane"
								>
									{formatPrice(data?.coming)}
								</IconTotalCard> */}
								<IconTotalCard
									label={t("status.paid")}
									icon="far fa-check"
								>
									{formatPrice(data?.paid)}
								</IconTotalCard>
							</div>
						);
					}}
				</ApiCall>
			</div>
			<Tabs tabs={[t("tabs.all"), t("tabs.reserved")]}>
				{(tab) => {
					if (tab === t("tabs.reserved")) {
						return (
							<PaymentList
								query={`response_type=list&status=reserved&with[]=company`}
							/>
						);
					}

					return (
						<PaymentList
							query={`response_type=list&with[]=company`}
						/>
					);
				}}
			</Tabs>
		</Layout>
	);
}
