import { ComponentProps } from "react";
import Card from "src/components/Card";
import Link from "src/components/Link";
import Logo from "src/components/Logo";
import LanguageSelector from "src/components/language/LanguageSelector";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

interface AuthLayoutProps {
	children?: any;
	head?: {
		title?: string;
		description?: string;
		back?: {
			to?:
				| string
				| {
						modal?: string;
				  };
			label?: string;
		};
	};
	variant?: "card" | "flat";
	loading?: boolean;
}

const AuthLayout = ({ children, head, variant = "card" }: AuthLayoutProps) => {
	const { theme } = useTheme();
	const { tenant } = useTenant();
	return (
		<div className="flex flex-col flex-1 bg-gray-background dark:bg-background">
			<div className="flex items-center justify-center h-16 py-4 ">
				{tenant.slug === "getcrewdone" ? (
					<Logo
						variant="default"
						color={theme === "dark" ? "dark" : "primary"}
						className="h-full"
					/>
				) : (
					<Logo
						variant="default"
						color={theme === "dark" ? "white" : "dark"}
						className="h-full"
					/>
				)}
			</div>
			<div className="flex flex-col flex-1 justify-center items-center px-3 md:px-0">
				{variant === "card" ? (
					<Card className="w-full max-w-[520px]">
						{head && (
							<div className="flex flex-col gap-3 mb-4">
								<div className="flex items-center justify-between mb2">
									<h2>{head.title}</h2>
									<LanguageSelector theme={"transparent"} />
								</div>
								{head?.description && <p>{head.description}</p>}
								{head.back?.to && (
									<Link to={head.back.to}>
										<i className="fal fa-arrow-left"></i>
										{head.back?.label && (
											<span className="ml-2">
												{head.back.label}
											</span>
										)}
									</Link>
								)}
							</div>
						)}

						{children}
					</Card>
				) : (
					children
				)}
			</div>
			<div className="h-16 flex"></div>
		</div>
	);
};

export const AuthLayoutContainer = ({
	children,
	className,
	...rest
}: ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn(
				"md:px-4 md:pt-12 w-full flex flex-col flex-1 max-w-[1200px]",
				className
			)}
		>
			{children}
		</div>
	);
};

export default AuthLayout;
