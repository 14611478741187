import { NavLink, useLocation } from "react-router-dom";
import { cn } from "src/lib/utils";

const variants = {
	default: "px-3 py-1.5",
	small: "px-3 py-1.5 text-sm",
	square: "p-2",
};

interface TabProps {
	variant: keyof typeof variants;
	children?: any;
	color: any;
	className?: string;
	onClick?: () => void;
	active?: boolean;
	to?: string;
	ref?: any;
	small?: boolean;
	padding?: string;
	element?: "div" | "button";
}

const Tab = ({
	variant,
	children,
	onClick,
	color,
	className,
	active,
	to,
	ref,
	element,
}: TabProps) => {
	const location = useLocation();
	const _active = to ? location.pathname === to : false;
	const As = element || "div";

	const renderTab = () => (
		<As
			{...{ onClick }}
			className={cn(
				"cursor-pointer rounded-md flex justify-center items-center gap-2 whitespace-nowrap transition-all text-[16px] font-regular dark:hover:bg-border",
				variants[variant],
				active || _active
					? `shadow opacity-100 bg-card text-card-foreground dark:bg-border dark:text-border-foreground`
					: "",
				className
			)}
			{...{ ref }}
		>
			{children}
		</As>
	);

	if (to) {
		return <NavLink {...{ to, ref }}>{renderTab()}</NavLink>;
	}

	return renderTab();
};

Tab.defaultProps = {
	color: "dark",
	variant: "default",
};

export default Tab;
