import { ComponentProps, createContext, useContext } from "react";
import { ApiLinkedSkill, ApiSkillData } from "src/api/types";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import {
	Area,
	AreaDisplay,
	AreaLabels,
} from "src/components/skills-data/charts";
import { Description, Title } from "src/components/skills-data/text";

interface SkillsDataContextProps {
	data: ApiSkillData;
}

const SkillsDataContext = createContext<SkillsDataContextProps>(
	{} as SkillsDataContextProps
);

export const useSkillsData = () => useContext(SkillsDataContext);

interface SkillsDataProps extends ComponentProps<"div"> {
	data: SkillsDataContextProps["data"];
}

const SkillsData = ({ data, ...rest }: SkillsDataProps) => {
	return (
		<SkillsDataContext.Provider value={{ data }}>
			<div {...rest} />
		</SkillsDataContext.Provider>
	);
};

interface SkillsProps {
	type: ApiLinkedSkill["type"] | "ALL";
}

const Skills = ({
	type,
	className,
	...rest
}: SkillsProps & ComponentProps<"div">) => {
	const { data } = useSkillsData();
	const skills = (data?.skills || []).filter((skill) =>
		type === "ALL" ? true : skill.type === type
	);
	return (
		<SkillSelector defaultSkills={skills}>
			<SkillSelector.Results
				variant="display"
				itemClassName="bg-primary-300 text-primary border-transparent"
			/>
		</SkillSelector>
	);
};

interface CheckEmptySkillsProps {
	type: ApiLinkedSkill["type"] | "ALL";
	children: JSX.Element;
}

const CheckEmptySkills = ({ type, children }: CheckEmptySkillsProps) => {
	const { data } = useSkillsData();
	const skills = (data?.skills || []).filter((skill) =>
		type === "ALL" ? true : skill.type === type
	);

	if (skills.length === 0) return null;

	return children;
};

SkillsData.Skills = Skills;
SkillsData.Title = Title;
SkillsData.Description = Description;
SkillsData.AreaChart = Area;
SkillsData.AreaChartDisplay = AreaDisplay;
SkillsData.AreaChartLabels = AreaLabels;
SkillsData.CheckEmptySkills = CheckEmptySkills;

export default SkillsData;
