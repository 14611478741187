import axios from "axios";
import { atom, useAtom } from "jotai";
import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";
import { ApiTenant } from "src/api/types";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useCategories from "src/hooks/api/services/categories/useCategories";
import useProjectOptions from "src/hooks/api/services/projects/useProjectOptions";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useConnections from "src/hooks/api/services/users/useConnections";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";
import useTaskStatuses from "src/hooks/api/tasks/useTaskStatuses";
import useGlobal from "src/hooks/api/useGlobal";
import useLanguage from "src/hooks/api/useLanguage";
import usePlans from "src/hooks/api/usePlans";
import useStudentPool from "src/hooks/api/useStudentpool";
import useIssuer from "src/hooks/selectors/useIssuer";
import useModules from "src/hooks/selectors/useModules";
import useSignature from "src/hooks/selectors/useSignature";
import { getCookie } from "src/lib/api";
import getTokenName from "src/lib/getTokenName";
import { updateProfile } from "src/redux/auth";
import { updateModules } from "src/redux/modules";
import { updateSupport } from "src/redux/support";

const prefetchAtom = atom({
	profile: true,
	member: true,
});

function usePrefetch() {
	const posthog = usePostHog();
	const dispatch = useDispatch();
	const [loading, setLoading] = useAtom(prefetchAtom);
	const { actions: languageActions } = useLanguage();
	const { fetchModules } = useModules();
	const { fetchSelectedSignature } = useSignature();
	const { fetchIssuers } = useIssuer();
	const { fetchGlobal } = useGlobal();
	const { fetchStudentPool } = useStudentPool();
	const { actions: taskStatusesActions } = useTaskStatuses();
	const { actions: usersActions } = useUsers();
	const { actions: categoriesActions } = useCategories();
	const { actions: connectionsActions } = useConnections();
	const { actions: limitsActions } = useLimits();
	const { actions: teamsActions } = useTeams();
	const { actions: boardsActions } = useBoards();
	const { actions: projectOptionsActions } = useProjectOptions();
	const { actions: timeTrackingActions } = useTimeTracking();
	const plans = usePlans();

	const run = async (tenant: ApiTenant) => {
		fetchGlobal();
		languageActions.get();

		let user = null;

		const token = getCookie(getTokenName()) || null;

		plans.actions.list({
			...(tenant?.slug === "getjobsdone" && {
				group_by: "interval_price",
			}),
		});

		//Check if the token is stored, if not skip the requests
		if (!token) {
			setLoading((l) => ({
				...l,
				profile: false,
			}));
			return;
		}

		//Fetch the user
		try {
			const result = await axios.get("/auth/profile");
			user = result?.data?.data;
		} catch (error) {
			//Handle error
			setLoading((l) => ({
				...l,
				profile: false,
			}));
			return;
		}

		if (user) {
			dispatch(updateProfile(user));
			if (user?.id) {
				posthog?.identify(user?.id.toString(), {
					email: user?.email,
				});
				// mixpanel.identify(user.id);
			}
			if (user?.language) {
				languageActions.changeLanguage(user?.language?.code);
			}

			if (user.type === "company") {
				taskStatusesActions.get();
				axios
					.get("/client/support/member")
					.then(({ data }) => {
						const member = data?.data;
						dispatch(updateSupport({ member, loading: false }));
					})
					.catch(() => {
						dispatch(updateSupport({ loading: false }));
					});
			}

			if (user.type === "company") {
				fetchModules();
				if (tenant.providers.payments === "OPP") {
					fetchIssuers();
				}

				if (
					tenant.modules.includes("extern-task") &&
					user.links?.client
				) {
					fetchStudentPool();
					connectionsActions.list({ with: ["form", "values"] });
				}

				if (user?.links?.client) {
					usersActions.list();
					teamsActions.list();
				}
				categoriesActions.list();

				if (tenant?.modules?.includes("intern-task")) {
					timeTrackingActions.getCurrent();
				}
			}

			if (tenant?.modules?.includes("intern-task")) {
				await boardsActions.list();
				await projectOptionsActions.list();
			}

			limitsActions.get("");

			if (user.type === "student") {
				dispatch(
					updateModules({
						loading: false,
					})
				);
				dispatch(updateSupport({ loading: false }));
			}
			if (tenant.modules.includes("extern-task")) {
				fetchSelectedSignature();
			}
		}
		setLoading((l) => ({
			...l,
			profile: false,
		}));
	};

	return {
		loading: loading.profile,
		run,
	};
}

export default usePrefetch;
