import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Crumb } from "src/components/Breadcrumb";
import Container, { ContainerVariant } from "src/components/layout/Container";
import LayoutHead from "src/components/layout/layout-default/LayoutHead";
import LayoutSidebar from "src/components/layout/layout-default/sidebar/LayoutSidebar";
import LayoutFooter from "src/components/layout/LayoutFooter";
import Loader from "src/components/Loader";
import DemoAccountBanner from "src/components/promotion/banners/DemoAccountBanner";
import NoMandateBanner from "src/components/promotion/banners/NoMandateBanner";
import PausedBanner from "src/components/promotion/banners/PausedBanner";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import { ParsedBackUrl } from "src/hooks/useBackUrl";
import useLayout from "src/hooks/useLayout";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface LayoutProps {
	children?: any;
	className?: string;
	actions?: any;
	title?: string;
	subtitle?: string;
	backUrl?: ParsedBackUrl;
	variant?: "default" | "small" | "no-container" | "medium";
	breadcrumb?: JSX.Element | Crumb[];
	hideNav?: boolean;
	loading?: boolean;
	container?: ContainerVariant;
	head?: {
		children?: any;
		className?: string;
		animation?: any;
	};
}

const Layout = ({
	children,
	title,
	subtitle,
	actions,
	backUrl,
	variant = "default",
	breadcrumb,
	hideNav,
	loading,
	container,
	head,
}: LayoutProps) => {
	const location = useLocation();
	const { isPhone, isTablet } = useWindowSize();
	const { layout } = useLayout();
	const auth = useAuth();
	const subscription = useSubscription();
	const hasNoMandate = !auth.has_mandate && !hideNav && auth.has_subscription;

	const hasPausedSubscription =
		auth.type === "company" &&
		subscription?.paused_at &&
		auth.has_subscription &&
		!hideNav;

	useEffect(() => {
		if (!location?.hash) {
			// window.scrollTo(0, 0);
		} else {
			const element = document.getElementById(
				location.hash.replace("#", "")
			);
			if (element) {
				element.scrollIntoView({
					behavior: "smooth",
				});
			}
		}
	}, [location]);

	return (
		<div className="flex flex-1">
			{auth.type === "company" && auth.mode === "demo" && (
				<DemoAccountBanner />
			)}
			{!isPhone && !isTablet && !hideNav && <LayoutSidebar />}
			<div
				style={{
					width:
						window.innerWidth - (layout?.navbar?.size?.width || 0),
				}}
				className="flex flex-1 bg-gray-background dark:bg-background flex-col"
			>
				{!hideNav && (
					<LayoutHead
						{...{
							title,
							subtitle,
							actions,
							backUrl,
							breadcrumb,
							children: head?.children,
							className: head?.className,
							animation: head?.animation,
							container: container || variant,
						}}
					/>
				)}
				<Container
					variant={container || variant}
					className={cn(
						"flex-1 flex flex-col overflow-auto pt-4",
						hideNav && "mt-8"
					)}
				>
					{!loading ? (
						<div className="flex flex-1 relative flex-col gap-4 pb-4">
							{hasNoMandate && <NoMandateBanner />}
							{hasPausedSubscription && <PausedBanner />}
							{children}
						</div>
					) : (
						<div className="flex flex-1 justify-center items-center relative flex-col gap-4 pb-4">
							<Loader />
						</div>
					)}
				</Container>
				<LayoutFooter />
			</div>
		</div>
	);
};

export default Layout;
