import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import useSubscription from "src/hooks/selectors/useSubscription";

const PausedBanner = () => {
	const { t } = useTranslation("promotion", {
		keyPrefix: "paused-banner",
	});
	const subscription = useSubscription();
	return (
		<div className="top-0 left-0 right-0 p-4 bg-red z-20">
			<div className="container">
				<NavLink
					to="/profile/billing/change/resume"
					className="text-white mx-auto block text-center"
				>
					<Trans {...{ t }} i18nKey="description">
						Je account is gepauzeerd, jouw account wordt op
						<strong>
							{{
								date: moment(subscription?.ends_at).format(
									"DD-MM-YYYY"
								),
							}}
						</strong>
						volledig gesloten. Tot die tijd kan je nog wel
						aanmeldingen bekijken en opdrachten afronden. Klik hier
						om je abonnement te hervatten.
					</Trans>
				</NavLink>
			</div>
		</div>
	);
};

export default PausedBanner;
