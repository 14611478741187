import { AnimatePresence, motion } from "framer-motion";
import { useContext, useMemo, useState } from "react";
import Loader from "src/components/Loader";
import { ModalProps } from "src/components/Modal";
import { ModalViewContext } from "src/components/layout/ModalView";
import useLayout from "src/hooks/useLayout";

const FULLSCREEN_SPACING = 64;

const SideModal = ({
	onClose,
	visible,
	children,
	className,
	title,
	loading,
	expanded,
}: ModalProps) => {
	const modal = useContext(ModalViewContext);
	const { layout } = useLayout();
	const modalWidth = window.innerWidth - layout.navbar.size.width || 600;
	const [isFullScreen, setIsFullScreen] = useState(expanded || false);
	const handleClose = () => {
		setIsFullScreen(false);
		onClose();
	};
	// useKeyPress(["Escape"], handleClose);
	const animationState = visible
		? isFullScreen
			? "fullscreen"
			: "open"
		: "closed";

	const wrapperAnimations = useMemo(() => {
		return {
			closed: {
				x: 1000,
				maxWidth: 600,
			},
			open: { x: 0, maxWidth: (modalWidth / 3) * 2 },
			fullscreen: {
				x: 0,
				maxWidth: modalWidth - FULLSCREEN_SPACING,
			},
		};
	}, [modalWidth]);

	return (
		<AnimatePresence>
			{visible && (
				<>
					<motion.div
						onClick={handleClose}
						className={`bg-dark opacity-20 fixed top-0 bottom-0 right-0 left-0 z-[88]`}
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.4 }}
						exit={{ opacity: 0 }}
					/>
					<motion.div
						transition={{
							ease: "linear",
						}}
						style={{
							top: layout.head.size?.height || 0,
						}}
						animate={animationState}
						variants={wrapperAnimations}
						initial="closed"
						exit="closed"
						className={`z-[90] fixed w-full flex flex-col flex-1 right-0 bottom-0 bg-background ${
							className || ""
						}`}
					>
						<div className="flex gap-6 border-b-[2px] border-t-[2px] border-border-background px-6 py-4">
							<div className="flex items-center gap-4 flex-1">
								<div
									onClick={() =>
										setIsFullScreen(!isFullScreen)
									}
									className="w-8 h-8 rounded-sm cursor-pointer hover:bg-dark-200 bg-accent-background transition-all hidden md:flex items-center justify-center"
								>
									<i
										className={`far fa-${
											isFullScreen ? "compress" : "expand"
										}`}
									></i>
								</div>
								{(modal?.title || title) && (
									<strong>{title || modal?.title}</strong>
								)}
							</div>
							<i
								onClick={handleClose}
								className="fal fa-times text-xl cursor-pointer"
							></i>
						</div>

						<div className="md:text-left text-center overflow-y-scroll flex flex-col flex-1">
							{loading ? (
								<div className="flex flex-1 flex-col justify-center items-center">
									<Loader />
								</div>
							) : (
								children
							)}
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default SideModal;
