import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import Card from "src/components/Card";
import Link from "src/components/Link";
import NoTasksCard from "src/components/dashboard/NoTasksCard";
import CompleteAccountCard from "src/components/dashboard/company/elements/CompleteAccountCard";
import CreateTaskCard from "src/components/dashboard/company/elements/CreateTaskCard";
import DashboardHeader from "src/components/dashboard/company/elements/DashboardHeader";
import MyOverdueTasksCard from "src/components/dashboard/company/elements/MyOverdueTasksCard";
import MyTaskInboxCheckList from "src/components/dashboard/company/elements/MyTaskInboxCheckList";
import MyTasksDayCheckCard from "src/components/dashboard/company/elements/MyTasksDayCheckCard";
import MyTasksTodayCard from "src/components/dashboard/company/elements/MyTasksTodayCard";
import MyWeekTasksCard from "src/components/dashboard/company/elements/MyWeekTasksCard";
import ProjectsButtons from "src/components/dashboard/company/elements/ProjectsButtons";
import ToggleCompletedIcon from "src/components/dashboard/company/elements/ToggleCompletedIcon";
import Draggable, { DragChangeEvent } from "src/components/draggable";
import { useFocusMode } from "src/components/forms/FocusModeSwitch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import Layout from "src/components/layout/Layout";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import MyInboxList from "src/components/lists/MyInboxList";
import RegistrationList from "src/components/lists/RegistrationList";
import PaymentListener from "src/components/payment/PaymentListener";
import CreateTaskChat from "src/components/tasks/create-task-chat/CreateTaskChat";
import Text from "src/components/ui/text";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import { useMyTasksByDayCompletedToggle } from "src/hooks/api/services/tasks/useMyTasksByDay";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskFormAutoSave from "src/hooks/api/tasks/useTaskFormAutoSave";
import { useTasksUpdater } from "src/hooks/api/tasks/useTasksUpdater";
import useAuth from "src/hooks/selectors/useAuth";
import useRememberState from "src/hooks/useRememberState";
import { cn } from "src/lib/utils";
import DashboardStudentView from "src/views/authenticated/dashboard/student/DashboardStudentView";
// type VariantLayout = "flat" | "wrapped";

const DashboardView = ({ t }: Translation) => {
	const VARIANT = "flat" as any;
	useTaskFormAutoSave();
	const taskForm = useTaskForm();
	const { isFocusMode } = useFocusMode();
	const [view, setView] = useRememberState("dashboard-view", "list");
	const { actions: sessionActions } = useAuthSession();
	// const [isWeekOpen, setIsWeekOpen] = useRememberState("week-open", "false");
	const auth = useAuth();
	const statuses = auth?.company?.statuses;
	const { tenant, taskType: type } = useTenant();
	const { handleEvent } = useTasksUpdater();
	const { showCompletedTasks, setShowCompletedTasks } =
		useMyTasksByDayCompletedToggle();

	useEffect(() => {
		const sessionId = sessionActions.getCookie();
		if (sessionId) {
			sessionActions.find(sessionId).then((res) => {
				if (res.status === "COMPLETED") {
					sessionActions.deleteCookie();
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (event: DragChangeEvent) => {
		let data: any = {};
		if (event.to === "DATE:TODAY") {
			data.end_date = moment().format("YYYY-MM-DD");
		} else if (event.to === "DATE:OVERDUE") {
			data.end_date = null;
		} else {
			const value = event?.to.toString().split(":");
			if (value.length === 2) {
				let key = value[0];
				if (key === "DATE") {
					key = "end_date";
				}
				data[key.toLowerCase()] = value[1];
			}
		}

		if (Object.keys(data).length) {
			if (event.data) {
				handleEvent({
					type: "UPDATE",
					id: Number(event.id),
					data: {
						...event.data,
						...data,
					},
				});
			}
			axios.patch(`/client/tasks/${event.id}`, data);
		}
	};

	if (auth.type === "student" && type === "extern") {
		return <DashboardStudentView />;
	}

	return (
		<Layout
			breadcrumb={[
				{
					label: t("title"),
					to: "/dashboard",
					icon: Icon.getFontAwesome("fa-home"),
				},
			]}
		>
			<PaymentListener />

			{type === "intern" && (
				<Draggable {...{ onChange }}>
					<div
						// layout
						className={cn(
							"flex flex-col lg:flex-row gap-4 lg:gap-6 xl:gap-12 w-full max-w-[1560px] mx-auto",
							VARIANT === "wrapped" &&
								"max-w-full mx-0 gap-4 lg:gap-4 xl:gap-4 justify-center p-4",
							!(view === "calendar" && isFocusMode) && "flex-1"
						)}
					>
						<div
							className={cn(
								"flex flex-col w-full flex-1 gap-8 rounded-md max-w-xl md:min-w-md -order-2 lg:order-none mx-auto relative z-[2]",
								VARIANT === "wrapped" &&
									"bg-accent p-12 rounded-xl mx-0 max-w-full",
								isFocusMode && "max-w-xl"
							)}
						>
							<CompleteAccountCard />
							<DashboardHeader />
							<div className="flex flex-col flex-1">
								<div className="flex items-center justify-between gap-4 flex-wrap">
									<Tabs.Container
										className={cn(
											"w-max mb-8 md:mb-4",
											view === "calendar" &&
												isFocusMode &&
												"mb-0 md:mb-0"
										)}
									>
										<Tab
											onClick={() => setView("list")}
											active={view === "list"}
										>
											{t("tabs.list")}
										</Tab>
										<Tab
											onClick={() => setView("calendar")}
											active={view === "calendar"}
										>
											{t("tabs.calendar")}
										</Tab>
									</Tabs.Container>
									{view === "list" && (
										<ToggleCompletedIcon
											name="completed-day"
											onChange={() =>
												setShowCompletedTasks(
													!showCompletedTasks
												)
											}
											value={showCompletedTasks}
										/>
									)}
								</div>
								{view === "list" && (
									<div className="flex flex-col">
										{new Array(7)
											.fill("")
											.map((v, index) => (
												<MyTasksDayCheckCard
													key={`tasks-day-check-${index}`}
													defaultOpen={index === 0}
													date={moment().add(
														index,
														"day"
													)}
												/>
											))}
									</div>
								)}
								{view === "calendar" && !isFocusMode && (
									<MyWeekTasksCard className="flex flex-col flex-1" />
								)}
							</div>
						</div>
						<div
							className={cn(
								"flex flex-col md:max-w-[420px] xl:max-w-[520px] flex-1 relative z-[1] gap-6",
								VARIANT === "wrapped" &&
									"bg-accent p-12 rounded-xl md:max-w-[560px]",
								isFocusMode && "hidden"
							)}
						>
							<div className="flex flex-col gap-4">
								<Text.Eyebrow>
									{t("sections.projects.title")}
								</Text.Eyebrow>
								<ProjectsButtons />
								<Link
									to="/projects"
									className="cursor-pointer text-border font-regular"
								>
									{t("sections.projects.all")}
								</Link>
							</div>

							<div className="flex flex-col gap-4">
								<Text.Eyebrow>
									{t("sections.inbox.title")}
								</Text.Eyebrow>
								<MyTaskInboxCheckList />
							</div>
						</div>
					</div>
					{view === "calendar" && isFocusMode && (
						<MyWeekTasksCard className="flex flex-col flex-1" />
					)}
				</Draggable>
			)}

			{type === "extern" && (
				<div
					className={cn(
						"xl:grid flex flex-col flex-1 gap-4 grid-cols-1 xl:grid-cols-3",
						!statuses?.has_tasks &&
							"md:py-12 xl:grid-cols-1 grid-cols-1"
					)}
				>
					<div className="flex flex-1 xl:col-span-3 flex-col gap-4">
						{statuses?.has_tasks ? (
							<div
								className={cn(
									"flex-1 grid gap-4 grid-cols-1",
									tenant.modules.includes(
										"ai-description-creation"
									)
										? "xl:grid-cols-3"
										: "xl:grid-cols-2"
								)}
							>
								{tenant.modules.includes(
									"ai-description-creation"
								) && (
									<div className="flex flex-1 xl:col-span-2 row-span-2">
										<CreateTaskChat
											noMaxWidth
											isLoading={
												taskForm.status !== "idle"
											}
											onSubmit={(formatted) => {
												taskForm.actions.createByTitle(
													formatted.title,
													{
														answers:
															formatted?.answers,
													}
												);
											}}
										/>
									</div>
								)}
								<Card title={t("cards.inbox")}>
									<MyInboxList limit={3} />
								</Card>
								<Card title={t("cards.registrations")}>
									<RegistrationList
										query={`state=available`}
										limit={3}
									/>
								</Card>
							</div>
						) : tenant.modules.includes(
								"ai-description-creation"
						  ) ? (
							<div className="flex flex-col flex-1 gap-12 max-w-lg mx-auto w-full ">
								<h1>{t("create-task-title")}</h1>
								<CreateTaskChat
									isLoading={taskForm.status !== "idle"}
									onSubmit={(formatted) => {
										taskForm.actions.createByTitle(
											formatted.title,
											{
												answers: formatted?.answers,
											}
										);
									}}
								/>
							</div>
						) : (
							<div className="flex flex-col flex-1">
								<NoTasksCard />
							</div>
						)}
					</div>
				</div>
			)}
			{type === "both" && (
				<div className="flex flex-col flex-1 gap-6">
					<Draggable {...{ onChange }}>
						{/* {auth.type === "student" && <ComplianceSection />} */}
						<div className="grid gap-3 flex-1 grid-cols-2 lg:grid-cols-[minmax(auto,_540px)_1fr_1fr]">
							<div className="flex flex-col md:grid lg:flex grid-cols-2 lg:flex-col gap-3 col-span-2 lg:col-span-1">
								<div className="flex flex-col gap-3">
									<CompleteAccountCard />
									<CreateTaskCard className="col-span-2" />
									<MyTasksTodayCard className="flex-1" />
									<MyOverdueTasksCard className="flex-1" />
								</div>
							</div>
							<div className="flex flex-col flex-1 col-span-2">
								<MyWeekTasksCard className="flex-1 flex flex-col" />
							</div>
						</div>
					</Draggable>
				</div>
			)}
		</Layout>
	);
};

DashboardView.locale = {
	nl: {
		title: "Mijn Dashboard",
		greeting: "Hallo {{name}}",
		"date-format": "dddd, DD MMMM",
		search: "Zoek taken...",
		"create-task-title": "Maak je eerste opdracht",
		week: {
			title: "Taken van deze week",
		},
		tabs: {
			list: "Lijst",
			calendar: "Kalender",
		},
		sections: {
			report: {
				cta: "Bekijk voortgang",
			},
			"my-skills": {
				title: "Mijn Skills",
			},
			"recent-skills": {
				title: "Recent behaalde skills",
			},
			inbox: {
				title: "Inbox",
			},
			"quick-task": {
				title: "Leeg je hoofd",
			},
			projects: {
				title: "Projecten",
				all: "Alle projecten",
			},
		},
		cards: {
			registrations: "Nieuwe aanmeldingen",
			inbox: "Mijn berichten",
			"example-tasks": "Voorbeeldopdrachten",
		},
	},
	en: {
		title: "My Dashboard",
		greeting: "Hello {{name}}",
		"date-format": "dddd, MMMM DD",
		search: "Search tasks...",
		"create-task-title": "Create your first task",
		week: {
			title: "Tasks for this week",
		},
		tabs: {
			list: "List",
			calendar: "Calender",
		},
		sections: {
			report: {
				cta: "View report",
			},
			"my-skills": {
				title: "My Skills",
			},
			"recent-skills": {
				title: "Recent accomplished Skills",
			},
			inbox: {
				title: "Inbox",
			},
			"quick-task": {
				title: "Clear your head",
			},
			projects: {
				title: "Projects",
				all: "All projects",
			},
		},
		cards: {
			registrations: "New Registrations",
			inbox: "My Messages",
			"example-tasks": "Example Tasks",
		},
	},
};

export default withTranslation(DashboardView);
