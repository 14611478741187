const payment = {
	"detail-view": {
		title: "Betaling {{invoice_number}}",
		information: "Informatie",
		invoice: "Factuur",
		students: "Professionals",
		"payment-failed": {
			title: "Betaling mislukt",
			description:
				"Helaas is de betaling mislukt. Je kan alsnog de betaling compleet maken via de onderstaande knop.",
			button: "Betaling opnieuw proberen",
		},
	},
	"index-view": {
		title: "Mijn betalingen",
		"title-refresh": "Verdiensten van {{year}}",
		status: {
			reserved: "Gereserveerd",
			coming: "Onderweg",
			paid: "Uitbetaald",
		},
		cards: {
			open: "Openstaande betalingen",
			"open-worth": "Openstaande waarde",
			"open-invoices": "Open facturen",
		},
		tabs: {
			all: "Alles",
			open: "Open betalingen",
			credit: "Credit",
			reserved: "Gereserveerd",
		},
	},
	"refresh-view": {
		title: "",
		error: {
			title: "Er is iets misgegaan",
			description:
				"Blijft dit probleem voorkomen, neem dan contact op met support@{{tenant.slug}}.nl",
		},
		"invoice-created": {
			title: "De factuur is aangemaakt",
			"iframe-title": "Factuur",
		},
		failed: {
			title: "Helaas",
			"description-pending": "Deze betaling wordt momenteel verwerkt",
			"description-disabled":
				"Voor deze betaling kan geen nieuwe betaallink aangemaakt worden. De betaling is al voltooid.",
			button: "Terug naar betalingen",
		},
		"account-disabled":
			"Je kan je account pas weer gebruiken nadat het abonnement is betaald",
	},
	"billing-upcoming-view": {
		title: "Mijn abonnement",
		back: "Terug naar abonnementen",

		"invoice-current": {
			title: "Factuur deze maand",
		},
	},
	"billing-view": {
		title: "Mijn abonnement",
		"payment-history": {
			title: "Betalingen",
		},
	},
	"change-mandate-view": {
		title: "Bankkoppeling kiezen",
		back: "Terug naar aanpassen",
		header: {
			title: "Bankkoppeling",
			description:
				"Via deze koppeling worden de abonnementsgelden geincasseerd, eventueel kan je hier ook de professional mee uitbetalen.",
		},
		form: {
			title: "Bankkoppeling:",
		},
		error: {
			title: "Er is iets misgegaan",
			description:
				"Blijft dit probleem voorkomen, neem dan contact op met support@{{tenant.slug}}.nl",
		},
	},
	"change-plan-view": {
		title: "Abonnement aanpassen",
		back: "Terug naar abonnement",
		pause: "Abonnement pauzeren",
		"subscription-head": {
			title: "Abonnement",
			subtitle: "Kies een abonnement dat bij jouw organisatie past.",
		},
		"mandate-head": {
			title: "Betaalmethode",
			subtitle:
				"Deze wordt gebruikt voor het betalen van het abonnement maar ook de betaling van de professional.",
		},
	},

	"resume-plan-view": {
		title: "Abonnement hervatten",
		back: "Terug naar abonnement aanpassen",
		error: {
			title: "Er is iets misgegaan",
			description: "Probeer het later opnieuw",
		},
		card: {
			title: "Abonnement hervatten",
			description:
				"Wanneer je op de knop hervatten klikt wordt het abonnement weer hervat. De eerst volgende afschrijving is dan op {{date}}",
			cancel: "Annuleren",
			resume: "Hervatten",
		},
	},
};

export default payment;
