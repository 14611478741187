import { useCallback, useEffect } from "react";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import { listen } from "src/lib/socket";

type InboxVariant = "tasks" | "offers";
type InboxOptions = {
	limit?: number;
};
function useInbox(variant: InboxVariant = "tasks", options?: InboxOptions) {
	const auth = useAuth();
	let params = {
		variant,
		order: "newest",
		"exclude[0]": "payment",
		"exclude[1]": "signer",
		"only-messages": 1,
		response_type: "inbox",
	} as any;
	if (options?.limit) {
		params.limit = options.limit;
	}
	const query = new URLSearchParams(params);
	const [
		{ data, meta, hasMore },
		loading,
		{ fetchNext, fetchGet, handleSearch, fetching },
	] = useGet(
		`/client/inbox?${query}`,
		{
			data: [],
			meta: {},
		},
		true
	);
	const { fetchProfile } = useFetchProfile();

	const updateInboxOnSocket = useCallback(() => {
		listen(`messages-changed.${auth.hid}`, () => {
			fetchGet(false);
			fetchProfile();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateInboxOnSocket();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data,
		meta,
		loading,
		fetchNext,
		fetchGet,
		hasMore,
		handleSearch,
		searching: fetching,
	};
}

export default useInbox;
