import { motion } from "framer-motion";
import { ComponentProps, useEffect, useState } from "react";
import Confetti from "react-dom-confetti";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import { cn } from "src/lib/utils";

const Completed = ({
	t,
	children,
	className,
	...rest
}: Translation & ComponentProps<"div">) => {
	const { fetchProfile } = useFetchProfile();
	const { taskType } = useTenant();
	const users = useUsers();
	const boards = useBoards();
	const teams = useTeams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [showConfetti, setShowConfetti] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowConfetti(true);
		}, 500);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const handleSubmit = async () => {
		setIsLoading(true);
		await Promise.all([
			users.actions.list(),
			boards.actions.list(),
			teams.actions.list(),
			fetchProfile(),
		]);
		navigate("/dashboard");
	};

	return (
		<div
			{...rest}
			className={cn("flex flex-col flex-1 gap-4 w-full", className)}
		>
			<div className="absolute left-0 right-0 flex justify-between">
				<Confetti
					config={{
						colors: [
							"#1aaa9d",
							"#ffffff",
							"#4BB543",
							"#f88c5e",
							"#f9b739",
						],
					}}
					active={showConfetti}
				/>
				<Confetti
					config={{
						colors: [
							"#1aaa9d",
							"#ffffff",
							"#4BB543",
							"#f88c5e",
							"#f9b739",
						],
					}}
					active={showConfetti}
				/>
			</div>

			<div className="w-full flex flex-col gap-4 flex-1 rounded-md mt-8">
				<div className="flex flex-col">
					<motion.div
						initial={{ scale: 0.6, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{
							delay: 0.7,
						}}
					>
						<div className="flex flex-col mb-6">
							<p className="font-bold text-xl mb-2">
								{t(`${taskType}.title`)}
							</p>
							<p>{t(`${taskType}.description`)}</p>
						</div>
						<Button
							onClick={handleSubmit}
							loading={isLoading}
							className="w-full"
							type="primary"
						>
							{t("continue")}
						</Button>
					</motion.div>
				</div>
			</div>
		</div>
	);
};

Completed.locale = {
	nl: {
		continue: "Let's go!",
		extern: {
			title: "Je hebt nu toegang tot {{global.students}} (Young) Professionals",
			description:
				"Van maandelijkse marketing opdrachten tot het eenmalig opschonen van een leadlijst, onze (Young) professionals staan te trappelen om voor je te beginnen.",
		},
		intern: {
			title: "Hoe nu verder?",
			description:
				"Je bent nu klaar om je taken bij te gaan houden en de progressie in je skills te observeren. Je hoeft straks alleen nog even context te geven aan je borden.",
		},
	},
	en: {
		continue: "Let's go!",
		intern: {
			title: "How further?",
			description:
				"You are now ready to start tracking your tasks and observing the progression in your skills. Later, you just need to provide context to your boards.",
		},
	},
};

export default withTranslation(Completed);
