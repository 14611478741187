import TimelineDot from "src/components/Timeline/TimelineDot";
import useWindowSize from "src/hooks/useWindowSize";

interface TimelineItemProps {
	date?: {
		start: number;
		end?: number;
	};
	title?: string;
	subtitle?: string;
	color?: string;
	type: {
		first?: boolean;
		last?: boolean;
	};
	actions?: any;
	children?: any;
	className?: string;
}

const TimelineItem = ({
	date,
	title,
	subtitle,
	type,
	color,
	actions,
	children,
	className,
}: TimelineItemProps) => {
	const { isPhone } = useWindowSize();
	return (
		<div className={`flex gap-4 ${className}`}>
			{!isPhone && date && (
				<div className="flex items-center min-w-[110px] justify-end">
					<small>
						{date.start} - {date?.end || "Heden"}
					</small>
				</div>
			)}
			<TimelineDot {...{ type, color }} />
			<div className="flex-1 flex flex-col gap-1 py-2">
				{title && (
					<strong style={{ lineHeight: "20px" }}>{title}</strong>
				)}
				{subtitle && (
					<span style={{ lineHeight: "20px" }}>{subtitle}</span>
				)}
				{isPhone && date && (
					<small className="font-bold opacity-50">
						{date.start} - {date?.end || "Heden"}
					</small>
				)}
				{children}
			</div>
			{actions}
		</div>
	);
};

TimelineItem.defaultProps = {
	type: {
		start: true,
		end: true,
	},
};

export default TimelineItem;
