import { ComponentProps } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import useResumeDetailContext, {
	ResumeTabName,
} from "src/components/resume/resume-detail/ResumeDetailContext";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import { cn } from "src/lib/utils";

interface ResumeTabsProps extends ComponentProps<"div"> {
	editable?: boolean;
}

export const ResumeTabs = withTranslation(
	({ t, className, editable, ...rest }: ResumeTabsProps & Translation) => {
		const {
			resume,
			tabs: { all, active, setActive },
		} = useResumeDetailContext();
		const isMissingExperiences =
			!resume.resume_modules.includes("experiences.job") ||
			!resume.resume_modules.includes("experiences.education");
		return (
			<Tabs.Container {...rest} className={cn("", className)}>
				{all.map((tab) => {
					if (
						isMissingExperiences &&
						tab === "experiences" &&
						editable
					) {
						return (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<Tab
											key={tab}
											active={tab === active}
											onClick={() => setActive(tab)}
											className="flex gap-2"
										>
											<i className="fas fa-exclamation text-red"></i>
											{t(tab)}
										</Tab>
									</TooltipTrigger>
									<TooltipContent>
										{t("missing-experiences")}
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						);
					}

					return (
						<Tab
							key={tab}
							active={tab === active}
							onClick={() => setActive(tab)}
						>
							{t(tab)}
						</Tab>
					);
				})}
			</Tabs.Container>
		);
	},
	{
		nl: {
			profile: "Profiel",
			experiences: "Ervaringen",
			"missing-experiences":
				"Voeg je werk en / of opleiding ervaring toe",
		},
		en: {
			profile: "Profile",
			experiences: "Experiences",
			"missing-experiences":
				"Add your work and / or education experiences",
		},
	}
);

interface ResumeTabProps {
	name: ResumeTabName;
	children?: any;
}

export const ResumeTab = ({
	children,
	className,
	name,
	...rest
}: ResumeTabProps & Omit<ComponentProps<"div">, "children">) => {
	const { tabs } = useResumeDetailContext();

	if (tabs.active !== name) {
		return null;
	}

	return (
		<div {...rest} className={cn("flex flex-col", className)}>
			{children}
		</div>
	);
};
