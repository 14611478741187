import LoaderComp from "src/components/Loader";
import Skeleton from "src/components/Skeleton";
import Icon from "src/components/icon/Icon";
import { cn } from "src/lib/utils";

interface ChooseProps {
	children?: any;
	className?: string;
}

const Choose = ({ children, className }: ChooseProps) => {
	return (
		<div className={cn("flex flex-col gap-2", className)}>{children}</div>
	);
};

interface ChooseItemProps {
	icon?: string;
	title: string;
	description?: string;
	onClick?: () => void;
	active?: boolean;
	loading?: boolean;
	size?: "small" | "large";
}

const ChooseItem = ({
	icon,
	title,
	description,
	onClick,
	active,
	loading,
	size = "small",
}: ChooseItemProps) => {
	return (
		<div
			{...{ onClick }}
			className={cn(
				"flex flex-col gap-3 border-2 rounded-lg border-border cursor-pointer group relative transition-all hover:border-primary",
				active && "border-primary",
				size === "small" && "p-3",
				size === "large" && "p-6"
			)}
		>
			{size === "large" && (
				<div className="flex justify-between items-center gap-4">
					{icon ? (
						<Icon
							iconFontSize={16}
							className="bg-primary-200 text-primary w-11 h-11 hover:bg-primary-200 group-hover:bg-primary-200 transition-all"
							icon={{
								font_awesome: {
									name: icon,
								},
							}}
						/>
					) : (
						<div />
					)}

					<div
						className={cn(
							"w-6 h-6 rounded-full border-2 border-border transition-all text-primary-foreground flex justify-center items-center",
							active && "border-primary bg-primary"
						)}
					>
						{loading ? (
							<LoaderComp />
						) : (
							<i
								className={cn(
									"fas fa-check text-[9px] transition-all",
									!active && "opacity-0"
								)}
							></i>
						)}
					</div>
				</div>
			)}
			<div className={cn("flex flex-col gap-2")}>
				<div className="flex items-center justify-between gap-3">
					<strong className="text-balance">{title}</strong>
					{size === "small" && (
						<div
							className={cn(
								"w-4 h-4 rounded-full border-2 border-border transition-all text-primary-foreground flex justify-center items-center",
								active && "border-primary bg-primary"
							)}
						>
							{loading ? (
								<LoaderComp />
							) : (
								<i
									className={cn(
										"fas fa-check text-[9px] transition-all",
										!active && "opacity-0"
									)}
								></i>
							)}
						</div>
					)}
				</div>

				{description && <p>{description}</p>}
			</div>
		</div>
	);
};

Choose.Item = ChooseItem;

const Loader = () => (
	<div
		className={cn(
			"flex gap-2 p-3 border rounded-md border-border cursor-pointer group relative transition-all flex-col"
		)}
	>
		<Skeleton className="h-4 w-1/4 bg-accent" />
		<Skeleton className="h-4 w-full bg-accent" />
	</div>
);

Choose.Skeleton = Loader;

export default Choose;
