import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FlatList from "src/components/FlatList";
import Indicator from "src/components/layout/Indicator";
import Tab from "src/components/layout/Tab";
import InboxListItem from "src/components/lists/list-items/InboxListItem";
import useInbox from "src/hooks/api/useInbox";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useElementSize from "src/hooks/useElementSize";
import useRememberState from "src/hooks/useRememberState";
import Tabs from "src/components/layout/Tabs";
import Input from "src/components/form/Input";
import Loader from "src/components/Loader";

interface InboxListProps {
	variant: "dark" | "light";
	theme: "default" | "dashboard";
	limit?: number;
}

const InboxList = ({ variant, limit, theme }: InboxListProps) => {
	const { t } = useTranslation("lists", {
		keyPrefix: "inbox-list",
	});
	const auth = useAuth();
	const [activeTab, setActiveTab] = useRememberState("inbox", "tasks");
	const inboxVariant = activeTab === "tasks" ? "tasks" : "offers";
	const [isFetchingNext, setIsFetchingNext] = useState(false);
	const { data, loading, fetchNext, hasMore, handleSearch, searching } =
		useInbox(inboxVariant, {
			limit,
		});
	const container = useElementSize();
	const { unreads } = useUnreads();
	const location = useLocation();
	const [query, setQuery] = useState<string>("");
	useUpdateEffectDebounce(query, 1000, () => {
		handleSearch().then();
	});

	useEffect(() => {
		if (!location.hash) {
			const parts = location.pathname.split("-");
			const isOffer = parts.includes("offer");
			const isTask = parts.includes("task");
			if (isOffer) {
				setActiveTab("offers");
			}
			if (isTask) {
				setActiveTab("tasks");
			}
			if (!isTask && !isOffer) {
				setActiveTab("tasks");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		container.getSize(container.ref);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	if (theme === "dashboard") {
		return (
			<div className="flex flex-1 flex-col gap-4">
				<div className="flex">
					<Tabs.Container className="flex-1">
						<Tab
							onClick={() => setActiveTab("tasks")}
							active={activeTab === "tasks"}
							className="flex gap-2 items-center"
							small
						>
							{unreads?.tasks > 0 && (
								<Indicator>{unreads.tasks}</Indicator>
							)}
							{t("tabs.tasks")}
						</Tab>
						<Tab
							onClick={() => setActiveTab("offers")}
							active={activeTab === "offers"}
							className="flex gap-2 items-center"
							small
						>
							{unreads?.offers > 0 && (
								<Indicator>{unreads.offers}</Indicator>
							)}
							{t(`tabs.offers-${auth.type}`)}
						</Tab>
					</Tabs.Container>
				</div>
				{loading ? (
					<div className="flex flex-col gap-[12px]">
						{new Array((limit || 6) - 1).fill("").map((_, key) => (
							<InboxListItem.Skeleton
								{...{ variant }}
								key={`list-item-${key}`}
							/>
						))}
					</div>
				) : (
					<div ref={container.ref} className="flex flex-1 flex-col">
						<div
							style={{ maxHeight: container.size.height }}
							className={`flex flex-col flex-1 hide-default-scrollbar overflow-y-auto pb-16 gap-3 relative`}
						>
							<FlatList
								empty={{
									title: t("empty.title"),
								}}
								{...{ hasMore, data, loading }}
								noInfiniteScroll
								loadingNext={isFetchingNext}
								onNext={async () => {
									setIsFetchingNext(true);
									await fetchNext();
									setIsFetchingNext(false);
								}}
								gap={12}
								renderItem={(item) => {
									const user = {
										name: item?.member?.first_name,
										company_name:
											item?.member?.company?.name,
										avatar: item?.member?.avatar,
									};
									let subject = item?.subject;
									let url = "/inbox";

									if (item?.type === "task") {
										subject =
											subject || t("item.deleted.task");
										url = `/inbox/${item?.links?.task_id}-${item?.links.registration_id}-task`;
									}

									if (item?.type === "offer") {
										subject =
											subject || t("item.deleted.offer");
										url = `/inbox/${item?.links?.offer_id}-${item?.links.registration_id}-offer`;
									}

									return (
										<InboxListItem
											theme={"list-item"}
											key={`list-item-${item.id}`}
											unread_count={item.unread_messages}
											message={item.last_message}
											{...{
												user,
												variant,
												url,
												subject,
											}}
										/>
									);
								}}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}

	return (
		<div
			className={`flex flex-1 flex-col rounded-md relative`}
			ref={container.ref}
		>
			<div
				style={{ maxHeight: container.size.height }}
				className={`flex flex-col flex-1 hide-default-scrollbar overflow-y-auto pb-16 relative`}
			>
				<div className="flex flex-col gap-2">
					{auth.type === "company" && (
						<Input
							name="search"
							onChange={(key, value) => setQuery(value)}
							placeholder={t("search.placeholder")}
							type="text"
							noMarginBottom
							icon={
								<>
									{searching ? (
										<Loader />
									) : (
										<i className="far fa-search" />
									)}
								</>
							}
							value={query}
						/>
					)}
					{/* <small className="">
						{t("total", { count: meta?.total || 0 })}
					</small> */}
				</div>
				<div className="flex">
					<Tabs.Container className="mt-4 mb-8 w-full flex-1">
						<Tab
							onClick={() => setActiveTab("tasks")}
							active={activeTab === "tasks"}
							className="flex gap-2 items-center"
							small
						>
							{unreads?.tasks > 0 && (
								<Indicator>{unreads.tasks}</Indicator>
							)}
							{t("tabs.tasks")}
						</Tab>
						<Tab
							onClick={() => setActiveTab("offers")}
							active={activeTab === "offers"}
							className="flex gap-2 items-center"
							small
						>
							{unreads?.offers > 0 && (
								<Indicator>{unreads.offers}</Indicator>
							)}
							{t(`tabs.offers-${auth.type}`)}
						</Tab>
					</Tabs.Container>
				</div>
				{loading ? (
					<div className="flex flex-col gap-[12px]">
						{new Array(6).fill("").map((_, key) => (
							<InboxListItem.Skeleton
								{...{ variant }}
								key={`list-item-${key}`}
							/>
						))}
					</div>
				) : (
					<FlatList
						empty={{
							title: t("empty.title"),
						}}
						{...{ hasMore, data, loading }}
						loadingNext={isFetchingNext}
						noInfiniteScroll
						onNext={async () => {
							setIsFetchingNext(true);
							await fetchNext();
							setIsFetchingNext(false);
						}}
						gap={12}
						renderItem={(item) => {
							const user = {
								name: item?.member?.first_name,
								company_name: item?.member?.company?.name,
								avatar: item?.member?.avatar,
							};
							let subject = item?.subject;
							let url = "/inbox";

							if (item?.type === "task") {
								subject = subject || t("item.deleted.task");
								url = `/inbox/${item?.links?.task_id}-${item?.links.registration_id}-task`;
							}

							if (item?.type === "offer") {
								subject = subject || t("item.deleted.offer");
								url = `/inbox/${item?.links?.offer_id}-${item?.links.registration_id}-offer`;
							}

							return (
								<InboxListItem
									theme="list-item"
									key={`list-item-${item.id}`}
									unread_count={item.unread_messages}
									message={item.last_message}
									{...{
										user,
										variant,
										url,
										subject,
									}}
								/>
							);
						}}
					/>
				)}
			</div>
		</div>
	);
};

InboxList.defaultProps = {
	theme: "default",
};

export default InboxList;
