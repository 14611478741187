import { ApiPayment } from "src/api/types";
import TrackPaymentItem, {
	TrackPaymentItemLoading,
} from "src/components/TrackPayment/TrackPaymentItem";
import useGet from "src/hooks/rest/useGet";

interface TrackPaymentProps {
	payment: ApiPayment;
}

const TrackPayment = ({ payment }: TrackPaymentProps) => {
	const [{ data }, loading] = useGet(`/payments/${payment.hid}/track`);
	const isCancelled = data?.cancelled || data?.chargeback || data?.refunded;

	if (loading) {
		return (
			<div>
				<TrackPaymentItemLoading variant="first" />
				<TrackPaymentItemLoading />
				<TrackPaymentItemLoading />
				<TrackPaymentItemLoading variant="last" />
			</div>
		);
	}

	return (
		<div>
			<TrackPaymentItem
				status="created"
				created_at={data?.created?.created_at}
				variant="first"
			/>

			<TrackPaymentItem
				status="reserved"
				created_at={data?.reserved?.created_at}
			/>

			{isCancelled ? (
				<TrackPaymentItem
					status="cancelled"
					created_at={
						data?.cancelled?.created_at ||
						data?.chargeback?.created_at ||
						data?.refunded?.created_at
					}
					variant="last"
				/>
			) : (
				<TrackPaymentItem
					status="completed"
					created_at={data?.completed?.created_at}
					variant="last"
				/>
			)}
		</div>
	);
};

export default TrackPayment;
