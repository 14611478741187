import { ComponentProps, useState } from "react";
import AgreeTermsCheckbox from "src/components/form/AgreeTermsCheckbox";
import FormGroup from "src/components/form/FormGroup";
import Input from "src/components/form/Input";
import AddressForm from "src/components/forms/AddressForm/v2/AddressForm";
import Choose from "src/components/forms/auth/signup/Choose";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useCompanyInfoSearch from "src/hooks/api/services/auth/useCompanyInfo";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import { cn } from "src/lib/utils";

export type BillingFormValues = {
	agreed: boolean;
	company: {
		name: string;
		kvk?: string;
		kvk_id?: string;
		bio?: string;
		street: string;
		house_number: string;
		zipcode: string;
		city: string;
	};
	user: {
		first_name: string;
		last_name: string;
		email: string;
	};
};

interface SubscriptionBillingFormProps extends Translation {
	values: BillingFormValues;
	onChange: (values: BillingFormValues) => void;
}

const SubscriptionBillingForm = ({
	t,
	className,
	values,
	onChange,
	...rest
}: SubscriptionBillingFormProps & Omit<ComponentProps<"div">, "onChange">) => {
	const companyInfo = useCompanyInfoSearch();
	const [showForm, setShowForm] = useState(false);
	const [fetchingCompanyInfo, setFetchingCompanyInfo] = useState<string>();
	const [error] = useState<string>();
	const [addressMode, setAddressMode] = useState<any>("search");

	const getFormField = (type: keyof BillingFormValues, name: string) => {
		const value = ((values || {})[type] || {}) as any;
		return {
			name,
			label: t(`fields.${name}.label`),
			placeholder: t(`fields.${name}.placeholder`),
			value: value[name] || "",
			handleChange: (event: any) => {
				onChange({
					...values,
					[type]: {
						...value,
						[name]: event?.target?.value,
					},
				});
			},
		};
	};

	const searches =
		(companyInfo?.searches || []).length > 0 ? companyInfo?.searches : [];
	useUpdateEffectDebounce(values?.company?.name, 1000, (q) => {
		if (companyInfo.status === "idle") {
			setShowForm(false);
			companyInfo.actions.search(q).then((list) => {
				if (list.length === 0) {
					setShowForm(true);
				}
			});
		}
	});

	const handlePickCompanyInfo = async (item: {
		id: string;
		dossiernummer: string;
		ids: string[];
	}) => {
		setFetchingCompanyInfo(item.id);
		const data = await companyInfo.actions.getDetails(item.id, {
			ids: item?.ids || null,
		});
		setFetchingCompanyInfo(undefined);
		delete data.results;
		onChange({
			...values,
			company: {
				...values?.company,
				...data,
			},
		});
		console.log(data);
		// formActions.setForm(data);
		if (data?.street) {
			setAddressMode("manual");
		}
		setShowForm(true);
	};

	return (
		<div {...rest} className={cn("flex flex-col flex-1", className)}>
			<div className="flex flex-col flex-1 gap-4">
				<div className="flex flex-col">
					<Input {...getFormField("company", "name")} />

					{(!!searches.length || companyInfo.status === "loading") &&
						!values?.company?.kvk &&
						!showForm && (
							<div className="flex flex-col max-h-full overflow-auto hide-default-scrollbar mt-2">
								<Choose>
									{companyInfo.searches
										.filter((item, index) => index < 6)
										.map((item) => (
											<Choose.Item
												loading={
													item.id ===
													fetchingCompanyInfo
												}
												key={item.id}
												active={
													item.id ===
													values?.company?.kvk_id
												}
												title={
													item.handelsnaam ||
													item?.text
												}
												description={item?.id}
												onClick={() =>
													handlePickCompanyInfo(item)
												}
											/>
										))}
									{searches.length === 0 &&
										companyInfo.status === "loading" &&
										new Array(4)
											.fill("")
											.map((item, index) => (
												<Choose.Skeleton
													key={`company-item-${index}-loading`}
												/>
											))}
									<p
										className="w-max opacity-70 hover:opacity-100 transition-all cursor-pointer select-none text-sm"
										onClick={() => setShowForm(true)}
									>
										{t("manual")}
									</p>
								</Choose>
							</div>
						)}

					{!searches.length &&
						!showForm &&
						companyInfo.status === "idle" && (
							<p
								className="w-max opacity-70 hover:opacity-100 transition-all cursor-pointer select-none text-sm mt-2 flex gap-1 items-center"
								onClick={() => setShowForm(true)}
							>
								<small>
									<i className="far fa-pencil"></i>
								</small>
								{t("manual")}
							</p>
						)}
				</div>
				{(showForm || values?.company?.kvk) && (
					<>
						<Input optional {...getFormField("company", "kvk")} />
						<FormGroup
							label={t("fields.address.label")}
							className="flex flex-col w-full"
						>
							<AddressForm
								className="w-full"
								defaultMode={addressMode}
								onChange={(address) =>
									onChange({
										...values,
										company: {
											...values?.company,
											...address,
										},
									})
								}
								address={{
									street: values?.company?.street,
									house_number: values?.company?.house_number,
									zipcode: values?.company?.zipcode,
									city: values?.company?.city,
								}}
							/>
						</FormGroup>
					</>
				)}
				<div className="flex gap-2">
					<Input {...getFormField("user", "first_name")} />
					<Input {...getFormField("user", "last_name")} />
				</div>
				<Input {...getFormField("user", "email")} type="email" />
				{/* <Input {...getFormField("name", "telephone")} type="telephone" /> */}
				{/* <Input {...getFormField("user", "password")} type="password" /> */}
				<AgreeTermsCheckbox
					className="my-2"
					value={values.agreed}
					onChange={() => {
						onChange({
							...values,
							agreed: !values.agreed,
						});
					}}
				/>
			</div>
			{error && (
				<div className="flex flex-col p-4 rounded-md bg-red-200 border border-red text-red">
					{error}
				</div>
			)}
		</div>
	);
};

SubscriptionBillingForm.locale = {
	nl: {
		continue: "Opslaan",
		manual: "Handmatig gegevens invoeren",
		fields: {
			name: {
				label: "Bedrijfsnaam",
				placeholder: "Bedrijfsnaam",
			},
			kvk: {
				label: "KVK",
				placeholder: "KVK",
			},
			address: {
				label: "Adres",
			},
			source: {
				label: "Hoe heb je van ons gehoord",
				placeholder: "Via welk medium...",
			},
			first_name: {
				label: "Voornaam",
				placeholder: "Voornaam",
			},
			last_name: {
				label: "Achternaam",
				placeholder: "Achternaam",
			},
			email: {
				label: "Email",
				placeholder: "Email",
			},
		},
	},
	en: {
		continue: "Save",
		manual: "Enter manual",
		fields: {
			name: {
				label: "Company name",
				placeholder: "Company name",
			},
			kvk: {
				label: "KVK",
				placeholder: "KVK",
			},
			address: {
				label: "Address",
			},
			source: {
				label: "How did you hear about us",
				placeholder: "Via which medium...",
			},
		},
	},
};

export default withTranslation(SubscriptionBillingForm);
