import { useTranslation } from "react-i18next";
import ShareButton from "src/components/promotion/share/ShareButton";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import useTenant from "src/hooks/api/services/tenants/useTenant";

type ShareTaskType = {
	description: string;
	title: string;
	budget: any;
	task_id: any;
	slug?: string;
	url?: string;
	variant?: "buttons" | "buttons-stacked";
	className?: string;
};

const ShareTask = ({
	task_id,
	title,
	budget,
	slug,
	url: _url,
	variant,
	className,
}: ShareTaskType) => {
	const { tenant } = useTenant();
	const { t } = useTranslation("tasks", {
		keyPrefix: "share-task",
	});
	let url = tenant?.urls?.public + `/opdracht/${slug || task_id}`;

	if (tenant?.slug === "getcrewdone") {
		url = tenant?.urls?.public + "/evenementen";
	}

	if (_url) {
		url = _url;
	}

	const share = {
		title: t("social.title", { title }),
		description: t("social.text", { budget, url }),
		url,
	};

	if (variant === "buttons-stacked") {
		return (
			<div className={`${className || ""}`}>
				<div className="flex flex-col gap-2">
					<ShareButton type={"facebook"} {...{ share }}>
						{t("labels.facebook")}
					</ShareButton>
					<ShareButton type={"linkedin"} {...{ share }}>
						{t("labels.linkedin")}
					</ShareButton>
					<ShareButton type={"twitter"} {...{ share }}>
						{t("labels.twitter")}
					</ShareButton>
					<ShareClipboard url={share.url} />
				</div>
			</div>
		);
	}

	if (variant === "buttons") {
		return (
			<div className={`${className || ""}`}>
				<div className="flex flex-wrap items-center gap-2">
					<ShareButton type={"facebook"} {...{ share }}>
						{t("labels.facebook")}
					</ShareButton>
					<ShareButton type={"linkedin"} {...{ share }}>
						{t("labels.linkedin")}
					</ShareButton>
					<ShareButton type={"twitter"} {...{ share }}>
						{t("labels.twitter")}
					</ShareButton>
				</div>
				<div className="mt-2">
					<ShareClipboard url={share.url} />
				</div>
			</div>
		);
	}

	return (
		<div
			className={`share-task flex items-center justify-center flex-col ${
				className || ""
			}`}
		>
			<strong className="text-center">{t("title")}</strong>
			<div className="mt-4 flex flex-wrap justify-center items-center gap-2">
				<ShareButton type={"facebook"} {...{ share }}>
					{t("labels.facebook")}
				</ShareButton>
				<ShareButton type={"linkedin"} {...{ share }}>
					{t("labels.linkedin")}
				</ShareButton>
				<ShareButton type={"twitter"} {...{ share }}>
					{t("labels.twitter")}
				</ShareButton>
			</div>
			<div className="mt-4">
				<a
					className="underline"
					target="_blank"
					rel="noopener noreferrer"
					href={url}
				>
					{url}
				</a>
			</div>
		</div>
	);
};

export default ShareTask;
