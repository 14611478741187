import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Container from "src/components/layout/Container";
import NoSubscriptionMiddleware from "src/components/middlewares/NoSubscriptionMiddleware";
import ResumeDetail from "src/components/resume/resume-detail/ResumeDetail";
import useResume from "src/hooks/api/services/resume/useResume";
import useAuth from "src/hooks/selectors/useAuth";
import { useParseBackUrl } from "src/hooks/useBackUrl";
import { Loading } from "../ResumeView";

function ResumePublicView({ t }: Translation) {
	const { id } = useParams();
	const auth = useAuth();
	const { resume, actions, status } = useResume(
		id
			? {
					rememberKey: id?.toString(),
			  }
			: undefined
	);
	const backgroundColor = resume.primary_color;
	const navigate = useNavigate();
	const backUrl = useParseBackUrl();

	useEffect(() => {
		actions.get(id);
		return () => {
			actions.setItem(
				{
					data: {},
				},
				{
					updateList: false,
				}
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (auth.type === "company" && !auth.has_subscription) {
		return <NoSubscriptionMiddleware />;
	}

	return (
		<div className="flex flex-1 flex-col">
			<div
				className="flex flex-col h-[33vh]"
				style={{ backgroundColor }}
			></div>
			<div className="flex flex-col flex-1 -mt-[10vh]">
				{resume.id && (
					<div
						onClick={() =>
							backUrl && typeof backUrl === "string"
								? navigate(backUrl)
								: navigate("/dashboard")
						}
						className="fixed top-5 left-5 flex-row cursor-pointer"
					>
						<i className="far fa-arrow-left mr-2"></i>
						{t("back")}
					</div>
				)}
				<Container className="gap-4" variant="medium">
					{status === "loading" && !resume.id ? (
						<div className="flex justify-center">
							<Loading type={false} />
						</div>
					) : status === "idle" && !resume.id ? (
						<section className="flex py-32 flex-1 items-center">
							<div className="container container-sm">
								<div className="flex flex-col mb-8 gap-2">
									<h1>{t("title")}</h1>
									<p className="flex mt-4">
										{t("description")}
									</p>
								</div>
								<div className="flex items-center gap-4">
									<Button
										onClick={() =>
											backUrl &&
											typeof backUrl === "string" &&
											navigate(backUrl)
										}
									>
										<i className="fas fa-arrow-left mr-4"></i>
										{t("buttons.back")}
									</Button>
								</div>
							</div>
						</section>
					) : (
						<Card>
							<ResumeDetail resume={resume}>
								<div className="flex flex-col gap-10">
									<ResumeDetail.PublicHead />
									<ResumeDetail.Bio />
									<ResumeDetail.Skills />
									<ResumeDetail.Education />
									<ResumeDetail.Work />
									<ResumeDetail.Reviews />
									<ResumeDetail.Badges />
									<ResumeDetail.SocialMedia />
								</div>
							</ResumeDetail>
						</Card>
					)}
				</Container>
			</div>
		</div>
	);
}

ResumePublicView.locale = {
	nl: {
		title: "Oeps! We zijn verdwaald",
		description: "Het lijkt er op dat dit CV niet meer bestaat.",
		back: "Terug",
		buttons: {
			back: "Terug naar de website",
		},
	},
	en: {
		title: "Oops! We are lost",
		description: "It seems that this CV no longer exists.",
		back: "Back",
		buttons: {
			back: "Back to the website",
		},
	},
};

export default withTranslation(ResumePublicView);
