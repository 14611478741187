import moment from "moment";
import { useMemo, useState } from "react";
import { ApiRegistration } from "src/api/types";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import PlatformFeedbackModal from "src/components/feedback/PlatformFeedbackModal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import SkillsData from "src/components/skills-data/SkillsData";
import RegistrationButtons from "src/components/tasks/panes/registration/RegistrationButtons";
import RegistrationDetails from "src/components/tasks/panes/registration/RegistrationDetails";
import RegistrationReviews from "src/components/tasks/panes/registration/RegistrationReviews";
import RegistrationSkillsMatch from "src/components/tasks/panes/registration/RegistrationSkillsMatch";
import RegistrationTaskDetails from "src/components/tasks/panes/registration/RegistrationTaskDetails";
import RegistrationContactDetails from "src/components/tasks/panes/registration/student/RegistrationContactDetails";
import ChatButton from "src/components/tasks/TaskForm/elements/ChatButton";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useBackUrl from "src/hooks/useBackUrl";
// import useTask from "src/hooks/api/tasks/useTask";

interface RegistrationMatchProps extends Translation {
	registration: ApiRegistration;
}

const RegistrationMatch = ({ t, registration }: RegistrationMatchProps) => {
	const backUrl = useBackUrl();
	const isConnected = registration?.is_connected;
	const { tenant } = useTenant();
	const { task } = useTask({
		id: registration?.task?.hid,
	});
	const tabs = useMemo(() => {
		const tabs = [t("tabs.information"), t("tabs.registration")];
		if (task?.contact) {
			tabs.push(t("tabs.contact"));
		}
		if (registration?.is_accepted) {
			tabs.push(t("tabs.reviews"));
		}

		if (tenant.modules.includes("skills-profile")) {
			if (registration?.score) {
				//Add the tab skills match at index 1
				tabs.splice(1, 0, t("tabs.skills-match"));
			}
			if (task.skills) {
				tabs.splice(2, 0, t("tabs.task-skills"));
			}
		}

		return tabs;
	}, [
		registration?.is_accepted,
		registration?.score,
		t,
		task?.contact,
		task.skills,
		tenant.modules,
	]);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	const paymentRequestUrl = useMemo(() => {
		if (isConnected) {
			const q = new URLSearchParams();
			q.append("connection_id", task.company?.id);
			q.append("task_id", task.hid);
			q.append(
				"description",
				t("payment-request.description", {
					task: task.parent
						? `${task.parent.title}: ${task.title}`
						: task.title,
				})
			);
			return `/payment-requests/create?${backUrl}&${q.toString()}`;
		}
	}, [
		backUrl,
		isConnected,
		t,
		task.company?.id,
		task.hid,
		task.parent,
		task.title,
	]);

	const renderTab = () => {
		if (activeTab === t("tabs.registration")) {
			return <RegistrationDetails {...{ registration }} />;
		}

		if (activeTab === t("tabs.reviews")) {
			return <RegistrationReviews {...{ registration }} />;
		}

		if (activeTab === t("tabs.contact")) {
			return (
				<RegistrationContactDetails
					contact={task.contact}
					subject={t("contact.subject", {
						title: task.title,
					})}
				/>
			);
		}

		if (activeTab === t("tabs.skills-match")) {
			return <RegistrationSkillsMatch {...{ registration }} />;
		}

		if (activeTab === t("tabs.task-skills")) {
			return (
				<SkillsData
					className="flex flex-col flex-1 gap-12"
					data={task.skills}
				>
					<div className="flex justify-between items-end">
						<h2>{t("skills.title")}</h2>
						<PlatformFeedbackModal
							type="TASK_SKILLS"
							data={{
								url: window.location.href,
							}}
							trigger={<PlatformFeedbackModal.Trigger />}
						>
							<PlatformFeedbackModal.Title />
							<PlatformFeedbackModal.Description>
								{t("skills-feedback.description")}
							</PlatformFeedbackModal.Description>
							<PlatformFeedbackModal.Form className="mt-4" />
						</PlatformFeedbackModal>
					</div>

					<div className="flex justify-center">
						<div className="flex gap-12">
							<div className="flex gap-4 justify-center items-center flex-1">
								<SkillsData.AreaChart type="area">
									<SkillsData.AreaChartDisplay className="col-span-2 max-h-[400px] w-full max-w-[400px]" />
									<SkillsData.AreaChartLabels className="max-w-[360px]" />
								</SkillsData.AreaChart>
							</div>
						</div>
					</div>

					<div className="flex gap-12">
						<SkillsData.CheckEmptySkills type="COMPETENCE">
							<div className="flex flex-col flex-1 gap-3">
								<div className="flex flex-col">
									<SkillsData.Title type="COMPETENCE" />
									<SkillsData.Description type="COMPETENCE" />
								</div>
								<SkillsData.Skills type="COMPETENCE" />
							</div>
						</SkillsData.CheckEmptySkills>
						<SkillsData.CheckEmptySkills type="KNOWLEDGE">
							<div className="flex flex-col flex-1 gap-3">
								<div className="flex flex-col">
									<SkillsData.Title type="KNOWLEDGE" />
									<SkillsData.Description type="KNOWLEDGE" />
								</div>

								<SkillsData.Skills type="KNOWLEDGE" />
							</div>
						</SkillsData.CheckEmptySkills>
					</div>
				</SkillsData>
			);
		}

		return <RegistrationTaskDetails {...{ registration }} />;
	};

	return (
		<div className="flex flex-col gap-6">
			{registration?.is_denied && (
				<Alert type="danger">{t("denied")}</Alert>
			)}

			<div className="flex flex-col md:flex-row gap-4 justify-between">
				<Tabs.Container>
					{tabs.map((tab) => (
						<Tab
							key={tab}
							active={tab === activeTab}
							onClick={() => setActiveTab(tab)}
						>
							{tab}
						</Tab>
					))}
				</Tabs.Container>
				<div className="flex items-center gap-3">
					{task.variant === "HOUR_RATE" &&
						registration.status === "matched" && (
							<Button
								to={`/hours/${task.hid}/show`}
								xsmall
								disabled={
									!moment(task.end_date).isBefore(moment())
								}
							>
								<i className="fas mr-2 fa-alarm-clock"></i>
								{t("tabs.hours")}
							</Button>
						)}
					{isConnected && paymentRequestUrl && (
						<Button
							xsmall
							to={{
								modal: paymentRequestUrl,
							}}
						>
							{t("tabs.payment-request")}
						</Button>
					)}
					<ChatButton
						xsmall
						to={`/tasks/${task.hid}/show/${registration?.hid}/chat`}
						unreadCount={registration?.unread_messages}
					/>
				</div>
			</div>

			{renderTab()}
			<RegistrationButtons {...{ registration }} />
		</div>
	);
};

RegistrationMatch.locale = {
	nl: {
		tabs: {
			information: "Informatie",
			registration: "Aanmelding",
			"skills-match": "Skills Match",
			contact: "Contactgegevens",
			reviews: "Reviews",
			hours: "Uren opgeven",
			"payment-request": "Betaalverzoek",
			"task-skills": "Opdracht Skills",
		},
		denied: "Helaas ben je niet uitgekozen voor de job",
		contact: {
			subject: "Vraag over {{title}}",
		},
		"payment-request": {
			description: "Opdracht: {{task}}",
		},
		skills: {
			title: "Deze skills zijn nodig voor de opdracht",
		},
		"skills-feedback": {
			description:
				"Vertel ons wat je van de skills voor deze opdracht vind, zo kunnen wij de AI in de toekomst verbeteren",
		},
	},
	en: {
		tabs: {
			information: "Information",
			registration: "Registration",
			"skills-match": "Skills Match",
			contact: "Contact information",
			reviews: "Reviews",
			hours: "Submit hours",
			"payment-request": "Payment request",
			"task-skills": "Task Skills",
		},
		denied: "Unfortunately, you were not chosen for the job",
		contact: {
			subject: "Question about {{title}}",
		},
		"payment-request": {
			description: "Task: {{task}}",
		},
		skills: {
			title: "These skills are required for the task",
		},
		"skills-feedback": {
			description:
				"Tell us what you think about the skills for this task, so we can improve the AI in the future",
		},
	},
};

export default withTranslation(RegistrationMatch);
