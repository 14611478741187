import { Route } from "react-router-dom";
import ReferralJoinView from "src/views/auth/ReferralJoinView";
import SignupChooseView from "src/views/auth/signup/SignupChooseView";
import SignupStudentView from "src/views/auth/signup/SignupStudentView";
import SignupView from "src/views/auth/signup/SignupView";
import SignupCompanyInformationView from "src/views/auth/signup/company/SignupCompanyInformationView";
import SignupCompanySearchView from "src/views/auth/signup/company/SignupCompanySearchView";
import SignupCompanySubscriptionCheckoutThanksView from "src/views/auth/signup/company/SignupCompanySubscriptionCheckoutThanksView";
import SignupCompanySubscriptionCheckoutView from "src/views/auth/signup/company/SignupCompanySubscriptionCheckoutView";
import SignupCompanyUserView from "src/views/auth/signup/company/SignupCompanyUserView";
import SignupCompanyView from "src/views/auth/signup/company/SignupCompanyView";
import SignupCompanyAIView from "src/views/auth/signup/company/experiments/ai/SignupCompanyAIView";
import SingupCompanyInviteView from "src/views/auth/signup/company/invite/SingupCompanyInviteView";

const SignupRoutes = [
	<Route element={<SignupView />} path="/signup/:session?" key="/signup" />,
	<Route
		element={<SignupCompanyAIView />}
		path="/signup/ai"
		key="/signup/ai"
	/>,
	<Route element={<SignupChooseView />} path="/choose" key="/choose" />,
	<Route
		element={<SignupStudentView />}
		path="/signup/professional"
		key="/signup/professional"
	/>,
	<Route
		element={<SignupCompanyView />}
		path="/signup/company"
		key="/signup/company"
	/>,
	<Route
		element={<SignupCompanySubscriptionCheckoutView />}
		path="/signup/company/subscription/:slug"
		key="/signup/company/subscription/:slug"
	/>,

	<Route
		element={<SignupCompanySubscriptionCheckoutThanksView />}
		path="/signup/company/subscription/:slug/:session_id/thanks"
		key="/signup/company/subscription/:slug/:session_id/thanks"
	/>,
	<Route
		element={<SignupCompanySearchView />}
		path="/signup/company/:slug"
		key="/signup/company/:slug"
	/>,

	<Route
		element={<SignupCompanyInformationView />}
		path="/signup/company/:slug/information"
		key="/signup/company/:slug/information"
	/>,
	<Route
		element={<SignupCompanyUserView />}
		path="/signup/company/:slug/information/contact"
		key="/signup/company/:slug/information/contact"
	/>,
	<Route
		element={<SingupCompanyInviteView />}
		path="/signup/company/:hid/invite"
		key="/signup/company/:hid/invite"
	/>,
	<Route
		element={<ReferralJoinView />}
		path="/join/:code"
		key="/join/:code"
	/>,
];

export default SignupRoutes;
