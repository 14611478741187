import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ApiCouponCode,
	ApiPaymentMethods,
	ApiSubscriptionPlan,
} from "src/api/types";
import Button from "src/components/Button";
import CouponCodeChecker from "src/components/checkout/CouponCodeChecker";
import MandateMethods from "src/components/checkout/MandateMethods";
import ManualCheckoutSummary, {
	Product,
} from "src/components/checkout/ManualCheckoutSummary";
import { PlanDisclaimer } from "src/components/subscription/plan/Plan";
import SubscriptionBillingForm, {
	BillingFormValues,
} from "src/components/subscription/SubscriptionBillingForm";
import { Switch } from "src/components/ui/switch";
import useSignupTracking from "src/hooks/api/services/auth/useSignupTracking";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useUsers from "src/hooks/api/services/users/useUsers";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import useNavigateModal from "src/hooks/useNavigateModal";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface SubscriptionPlanSelectedProps {
	plan: ApiSubscriptionPlan;
	showIntervalSwitch?: boolean;
	showCouponCodeChecker?: boolean;
	showAnimations?: boolean;
	className?: string;
	interval: ApiSubscriptionPlan["interval"];
	layout?: "stacked" | "horizontal";
	header?: any;
	sessionId?: string;
}

const SubscriptionPlanSelected = ({
	plan,
	showCouponCodeChecker = true,
	showAnimations = true,
	className,
	interval,
	layout = "stacked",
	header,
	sessionId,
}: SubscriptionPlanSelectedProps) => {
	const trackingData = useSignupTracking();
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-plan-selected",
	});
	const [couponCode, setCouponCode] = useState<ApiCouponCode | null>(null);
	const { tenant } = useTenant();
	const navigateModal = useNavigateModal();
	const [subscribeToPlan] = usePost(
		`/auth/subscriptions/plans/${plan.id}/subscribe`
	);
	const [billing, setBilling] = useState<BillingFormValues>({
		agreed: false,
		company: {},
		user: {},
	} as BillingFormValues);
	const [posting, setPosting] = useState(false);
	const [method, setMethod] = useState({
		type: "ideal",
		issuer: "",
	}) as any;
	const [sendAlert] = useAlert();
	const auth = useAuth();
	const { users, actions: usersActions } = useUsers();
	const activeUsers =
		tenant.providers.payments === "STRIPE"
			? users.filter((user) => user.status === "ACTIVE")?.length
			: null;

	const partnerFee = tenant.providers.payments === "OPP" ? 1 : 0;
	const vat = tenant.providers.payments === "STRIPE" ? 0 : 0.21;
	const showMandateMethods =
		(!auth?.id || !auth?.company?.statuses.has_active_mandate) &&
		tenant.providers.payments === "OPP";
	const hasBilling =
		auth?.id ||
		(billing.agreed &&
			billing.company?.name &&
			billing.company?.city &&
			billing.company?.street &&
			billing.company?.house_number &&
			billing.company?.zipcode &&
			billing.user?.first_name &&
			billing.user?.last_name &&
			billing.user?.email) ||
		!!sessionId;
	const isDisabled = !(hasBilling && method.type && method.issuer);
	const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
	const addons = useMemo(() => {
		const items = plan.addons.map((addon) => {
			const active = addon.prices.find(
				(item) => item.interval === interval
			);
			return {
				name: addon.name,
				slug: addon.slug,
				price: active?.price,
			};
		});

		if (auth.type === "student") {
			return items.filter((item) => item.slug !== "analytics");
		}
		return items;
	}, [plan, interval, auth]);
	const [activeAddons, setActiveAddons] = useState<string[]>([]);
	const products = useMemo(() => {
		const products: Product[] = [];

		products.push({
			description: `${tenant.name}, ${plan.name}`,
			price: plan?.discount?.original_price || plan.price,
			amount: activeUsers,
			vat,
		});

		if (activeAddons.length > 0) {
			products.push(
				...activeAddons.map((slug) => {
					const item = addons.find((item) => item.slug === slug);
					return {
						description: `Module: ${item?.name || ""}`,
						price: item?.price || 0,
						amount: activeUsers,
						vat,
					};
				})
			);
		}

		if (partnerFee > 0) {
			products.push({
				description: "PARTNER_FEE",
				price: partnerFee,
				vat,
			});
		}

		if (plan.discount) {
			products.push({
				description: t("discount"),
				price: plan.price - plan.discount.original_price,
				amount: activeUsers,
				vat,
			});
		}

		if (couponCode?.id && couponCode.interval === interval) {
			const currentPrice = plan.price;
			if (couponCode.price !== null) {
				products.push({
					description: couponCode.name,
					price: couponCode.price - currentPrice,
					vat,
				});
			}

			if (couponCode.percent) {
				products.push({
					description: couponCode.name,
					price: -(currentPrice * (couponCode.percent / 100)),
					vat,
				});
			}
		}

		return products;
	}, [
		activeAddons,
		activeUsers,
		addons,
		couponCode,
		interval,
		partnerFee,
		plan,
		t,
		tenant.name,
		vat,
	]);

	const checkIfEmailIsAvailable = async (email: string) => {
		const { data } = await axios.get(
			`/helpers/check-email-available?email=${email}`
		);
		return data?.data || false;
	};

	useEffect(() => {
		setCouponCode(null);
	}, [interval]);

	useEffect(() => {
		if (auth?.id) {
			usersActions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleError = () => {
		sendAlert({
			type: "error",
			title: t("error.title"),
			text: t("error.text"),
		});
		setPosting(false);
	};

	const handleSubmit = async (method: ApiPaymentMethods, issuer?: string) => {
		const onboarding = auth?.company?.onboarding?.missing || [];
		setEmailAlreadyExists(false);
		if (
			onboarding.includes("address") &&
			tenant.providers.payments !== "STRIPE"
		) {
			navigateModal("/subscription/address");
			return;
		}

		setPosting(true);

		if (!auth?.id && billing.user.email) {
			const available = await checkIfEmailIsAvailable(billing.user.email);
			if (!available) {
				setPosting(false);
				setEmailAlreadyExists(true);
				return;
			}
		}

		const addons = plan.addons
			.filter((item) => activeAddons.includes(item.slug))
			.map((item) => {
				return {
					price_id: item.prices.find(
						(price) => price.interval === interval
					)?.id,
				};
			});

		setPosting(true);
		subscribeToPlan({
			payment_method: method,
			redirect_url: `/subscription/${plan.slug}/thanks`,
			issuer,
			interval,
			addons,
			session_id: sessionId,
			coupon_code_id:
				couponCode?.id && couponCode.interval === interval
					? couponCode?.id
					: null,
			...(!auth?.id && billing),
			...(!auth?.id && trackingData),
		})
			.then(({ data }) => {
				const url = data?.redirect_url;
				if (url) {
					window.location = url;
				} else {
					handleError();
				}
			})
			.catch(handleError);
	};

	return (
		<motion.div
			{...(showAnimations && {
				animate: {
					opacity: 1,
					x: 0,
					transition: {
						delay: 0.2,
						type: "timing",
					},
				},
				initial: {
					opacity: 0,
					x: -200,
				},
			})}
			className={cn(
				"rounded-md flex flex-col gap-8 bg-card text-card-foreground border border-border relative p-6",
				layout === "horizontal" && "md:flex-row md:gap-8 p-8",
				className
			)}
		>
			<div className="flex-1 flex flex-col gap-6">
				{addons?.length > 0 && (
					<div className="flex flex-col gap-2">
						<strong>{t("addons.title")}</strong>
						<div className="flex flex-col">
							{addons.map((addon: any) => (
								<div
									key={`${interval}-${addon.slug}`}
									className="flex items-center gap-2"
								>
									<div className="flex-1">{addon.name}</div>
									<div className="flex gap-2 items-center">
										<Switch
											checked={activeAddons.includes(
												addon.slug
											)}
											onClick={() => {
												if (
													activeAddons.includes(
														addon.slug
													)
												) {
													setActiveAddons(
														activeAddons.filter(
															(item) =>
																item !==
																addon.slug
														)
													);
												} else {
													setActiveAddons([
														...activeAddons,
														addon.slug,
													]);
												}
											}}
										/>
										<span>{formatPrice(addon.price)}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				)}

				<div className="flex flex-col gap-2">
					<strong>{t("summary.title")}</strong>
					<ManualCheckoutSummary
						{...{ products }}
						summary={{
							vat_mode:
								tenant.providers.payments === "STRIPE"
									? "checkout"
									: undefined,
						}}
					/>
				</div>
				{tenant.providers.payments === "OPP" &&
					showCouponCodeChecker && (
						<CouponCodeChecker
							{...{ couponCode }}
							onChange={setCouponCode}
							plan={{
								id: plan.id,
								interval: interval,
							}}
						/>
					)}
				<PlanDisclaimer className="opacity-80 text-sm" />
			</div>
			<div className="w-[2px] bg-accent"></div>
			<div className="flex flex-col flex-1 gap-6">
				{header}

				{showMandateMethods && (
					<MandateMethods
						layout="dropdown"
						idealOnly
						value={method}
						onClick={(type, issuer) => setMethod({ type, issuer })}
					/>
				)}

				{!sessionId && (
					<>
						{!auth?.id && (
							<SubscriptionBillingForm
								values={billing}
								onChange={(billing: BillingFormValues) =>
									setBilling(billing)
								}
							/>
						)}

						{emailAlreadyExists && (
							<div className="flex flex-col text-red">
								{t("email-already-exists")}
							</div>
						)}
					</>
				)}

				<div className="flex flex-col">
					{auth?.company?.statuses.has_active_mandate ||
					tenant.providers.payments === "STRIPE" ? (
						<Button
							className="w-full flex-1"
							type="dark"
							loading={posting}
							onClick={() => handleSubmit("ideal")}
						>
							<i className="fas fa-check mr-4"></i>
							{!auth ? t("button.checkout") : t("button.confirm")}
						</Button>
					) : (
						<Button
							className="w-full flex-1"
							type="dark"
							disabled={isDisabled}
							loading={posting}
							// disabled={!method.type || !method.issuer}
							onClick={() =>
								handleSubmit(method.type, method.issuer)
							}
						>
							{!auth?.id
								? t("button.checkout")
								: t("button.confirm")}
						</Button>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default SubscriptionPlanSelected;
