import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";

let isProduction = process.env.NODE_ENV === "production";
// const banner = "/assets/images/basic-page-header.jpg";

if (window.location.origin.includes("demo.getjobsdone")) {
	isProduction = false;
}

// if (isProduction) {
// 	Sentry.init({
// 		dsn: "https://b76cae79175049aaba2d99964775adad@o456499.ingest.sentry.io/5449634",
// 		integrations: [new Integrations.BrowserTracing()],
// 		tracesSampleRate: 1.0,
// 	});
// }

const Head = () => {
	const { tenant } = useTenant();
	const { t } = useTranslation(["layout"]);
	if (!isProduction) {
		return (
			<Helmet>
				{/* <meta name="image" content={banner} />
				<meta name="og:image" content={banner} /> */}

				<meta property="og:title" content={tenant.name} />

				<meta name="description" content={t("seo.description")} />
				<meta name="og:description" content={t("seo.description")} />
				<meta property="og:url" content={window.location.origin} />

				<script>{`window.DataLayer = []`}</script>
				<style>
					{`
                        iframe{
                            display: none;
                        }
                    `}
				</style>
				<title>DEV:{tenant?.name || ""}</title>
			</Helmet>
		);
	}

	return (
		<Helmet>
			{/* <meta name="image" content={banner} />
			<meta name="og:image" content={banner} /> */}
			<meta name="google" content="notranslate" />
			<meta property="og:title" content={tenant.name} />

			<meta name="description" content={t("seo.description")} />
			<meta name="og:description" content={t("seo.description")} />
			<meta property="og:url" content={window.location.origin} />
			<meta
				name="facebook-domain-verification"
				content="5kte1hl8igobmkklokh5wrge1twsq8"
			/>
			<title>{tenant?.name || ""}</title>
		</Helmet>
	);
};

export default Head;
