import { useParams } from "react-router-dom";
import { ApiTask } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TabNav from "src/components/layout/tab-nav/TabNav";
import TaskDropDownButton from "src/components/tasks/TaskDropDownButton";
import TaskEditableTitle from "src/components/tasks/TaskEditableTitle";
import SaveTaskButton from "src/components/tasks/TaskForm/elements/SaveTaskButton";
import TaskModalInfo from "src/components/tasks/TaskForm/modals/task-modal/TaskModalInfo";
import TemplateTaskBanner from "src/components/tasks/TemplateTaskBanner";
import TaskDetailBreadcrumbs from "src/components/tasks/detail/TaskDetailBreadcrumbs";
import TaskDetailFields from "src/components/tasks/detail/TaskDetailFields";
import TaskQuickActions from "src/components/tasks/detail/TaskQuickActions";
import TimeTrackerCard from "src/components/time-tracker/TimeTrackerCard";
import Sidebar from "src/components/ui/sidebar";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useTaskTabs from "src/hooks/api/tasks/useTaskTabs";
import useModules from "src/hooks/selectors/useModules";
import useBackUrl, { useParseBackUrl } from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";
import NotFoundView from "src/views/auth/NotFoundView";

interface TaskDetailWrapperProps extends Translation {
	children?: any;
	task: ApiTask;
	loading: boolean;
	backUrl?: string;
	className?: string;
	sidebar?: {
		open: boolean;
		onOpenChange: (value: boolean) => void;
	};
	options?: {
		hideTabs?: boolean;
		hideFields?: boolean;
		hideSidebar?: boolean;
		childrenOffset?: boolean;
	};
	container?: any;
}

const TaskDetailWrapper = ({
	t,
	task,
	sidebar,
	loading,
	children,
	backUrl: _backUrl,
	options,
	className,
	container,
}: TaskDetailWrapperProps) => {
	const { modules } = useModules();
	const { tabs } = useTaskTabs(task);
	const back = useParseBackUrl();
	const backUrl = useBackUrl();
	const { id, pane } = useParams();
	const { status } = useTask({
		id,
	});

	const hideSidebar =
		!modules.includes("intern-task") || options?.hideSidebar;

	if (status === "error") {
		return <NotFoundView />;
	}

	return (
		<Layout
			{...{ className, container }}
			backUrl={_backUrl || back || "/tasks"}
			breadcrumb={<TaskDetailBreadcrumbs {...{ task }} />}
		>
			{!loading && (
				<>
					{task.type === "TEMPLATE" && (
						<TemplateTaskBanner className="rounded-md" />
					)}

					<div className="flex flex-1 md:gap-6">
						<div
							className={cn(
								"flex flex-col gap-4 flex-1 sm:pt-0",
								pane === "editor" &&
									"max-w-[1080px] w-full mx-auto"
							)}
						>
							{task?.id && (
								<>
									{modules.includes("intern-task") ? (
										<TaskEditableTitle
											className={cn(
												task.type !== "PUBLISHED" &&
													"mt-16"
											)}
											variant="large"
											disabled={
												!task.can.includes("edit")
											}
										/>
									) : (
										<div className="flex justify-between items-end">
											<h1>{task.title}</h1>
											<div className="flex gap-4 items-center">
												{task.has_subtasks && (
													<Button
														type="transparent"
														to={`/tasks/${task.hid}/subtasks/edit?${backUrl}`}
													>
														{t("edit-subtasks")}
													</Button>
												)}
												{task.type === "PUBLISHED" &&
													task.can.includes(
														"edit"
													) && (
														<div className="flex items-center justify-between">
															<Button
																to={{
																	modal: `/tasks/${id}/${pane}/share`,
																}}
																small
																type="ghost"
															>
																<i className="far fa-share-alt mr-2"></i>
																{t("share")}
															</Button>
														</div>
													)}
												<SaveTaskButton />
											</div>
										</div>
									)}
									{tabs.length > 1 && !options?.hideTabs && (
										<div className="flex items-end gap-4">
											<TabNav {...{ tabs }} />
											{hideSidebar && (
												<TaskDropDownButton
													{...{ task }}
												/>
											)}
										</div>
									)}
									<div className="flex flex-col flex-1 md:mt-2">
										{children}
									</div>
								</>
							)}
						</div>
						{!hideSidebar && (
							<Sidebar
								open={
									sidebar?.open !== undefined
										? sidebar?.open
										: true
								}
								onOpenChange={sidebar?.onOpenChange}
							>
								<div className="flex flex-col gap-6 md:min-w-[300px]">
									{task?.type === "INTERNAL" && task?.id && (
										<>
											<SaveTaskButton />
											<Card className="shadow-none dark:shadow">
												<TaskDetailFields />
												{modules.includes(
													"extern-task"
												) && (
													<Button
														type="ghost"
														to={`/tasks/${task.hid}/publish?${backUrl}`}
													>
														{t("publish")}
													</Button>
												)}
											</Card>
											<TimeTrackerCard
												className="shadow-none dark:shadow"
												data={{
													task_id: task.id,
												}}
											/>
										</>
									)}
									{task?.id && task.can.includes("edit") && (
										<>
											<hr className="border-border" />
											<TaskQuickActions className="px-3" />
										</>
									)}

									<TaskModalInfo />
								</div>
							</Sidebar>
						)}
					</div>
				</>
			)}
		</Layout>
	);
};

TaskDetailWrapper.locale = {
	nl: {
		title: "Taak",
		share: "Delen",
		publish: "Uitbesteden",
		"edit-subtasks": "Shifts bewerken",
	},
	en: {
		title: "Task",
		share: "Share",
		publish: "Publish",
		"edit-subtasks": "Edit shifts",
	},
};

export default withTranslation(TaskDetailWrapper);
