import { atom, useAtom } from "jotai";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { User } from "src/api/types";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePrefetch from "src/hooks/api/usePrefetch";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";
import { createCookie } from "src/hooks/useTokenListener";
import getTokenName from "src/lib/getTokenName";
import { updateProfile } from "src/redux/auth";

type ApiAuth = User & {
	token?: string;
};

const loadingAtom = atom(false);

type LoginProps = {
	email: string;
	password: string;
};

type Options = {
	redirectUrl?: string;
};

export default function useLogin(options?: Options) {
	const [loading, setLoading] = useAtom(loadingAtom);
	const [, setCookie] = useCookies();
	const posthog = usePostHog();
	const auth = useAuth();
	const dispatch = useDispatch();
	const { actions: tenantActions } = useTenant();
	const prefetch = usePrefetch();
	const navigate = useNavigate();
	const { api, state } = useApi(
		{ id: "AUTH" },
		{
			baseUrl: "/auth",
		}
	);

	const login = async (values: LoginProps) => {
		try {
			setLoading(true);
			const user = await api
				.post("/login", values)
				.then(({ data }) => data.data);
			if (user?.id) {
				await authenticate(user);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			throw error;
		}
	};

	const authenticate = async (user: ApiAuth) => {
		//Set the cookie for authentication
		setCookie(getTokenName(), user.token, createCookie());
		console.log(user);
		//Get the current tenant state
		const tenant = await tenantActions.current();
		prefetch.run(tenant);

		//Set the auth state
		dispatch(updateProfile(user));

		if (user?.id) {
			posthog?.identify(user?.id.toString(), {
				email: user?.email,
			});
			// mixpanel.identify(user.id);
		}

		if (window.self !== window.top) {
			const path = !tenant.has_access
				? "/enable-access"
				: options?.redirectUrl || "/dashboard";
			window.parent.location.href =
				tenant.urls.account + path + "?token=" + user.token;
		} else {
			//Redirect to the url
			if (!tenant.has_access) {
				navigate("/enable-access");
			} else {
				if (
					options?.redirectUrl === "/onboarding" &&
					user?.onboarding?.is_completed
				) {
					navigate("/dashboard");
					return;
				}

				navigate(options?.redirectUrl || "/dashboard");
			}
		}
	};

	return {
		auth,
		state: loading ? "loading" : "idle",
		status: state?.status,
		actions: {
			login,
			authenticate,
		},
	};
}
